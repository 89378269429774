import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button, Radio } from '@mui/material';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { userType } from 'app/helpers/userType';
import { getPhases } from 'app/redux/actions/Phase';
import { addReport, getReportCategory, uploadFileS3, uploadFileS3signedUrl } from 'app/redux/actions/Report';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getSelectProjects } from 'app/redux/actions/Project';
import envData from 'env_config';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 100 characters!')
    .max(100, 'Name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  category: yup.string('Enter your category').required('The Category field is required'),
  description: yup
    .string('Enter your description')
    .min(4, 'Description can set of between 4 and 255 characters!')
    .max(255, 'Description can set of between 4 and 255 characters!')
    .required('The Description field is required'),
});

const AddEditReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const phases = useSelector((state) => state?.phase);
  const reportCategorys = useSelector((state) => state?.report);
  const projects = useSelector((state) => state?.project);
  const [phase, setPhase] = useState('');
  const [type, setType] = useState('general');
  const [projectIdASE, setProjectIdASE] = useState('');
  const [reportCategory, setReportCategory] = useState('');
  const [file, setfile] = useState();
  const [extension, setExtension] = useState();
  const { isPA, isASE } = userType();
  const { projectId } = useParams();
  const mutation = { isError: false };
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      phase: '',
      category: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      if (isASE && !projectId && projectIdASE === '') {
        enqueueSnackbar('The ProjectId field is required', { variant: 'error' });
        return;
      }
      if (!type) {
        enqueueSnackbar('Please select radio button', { variant: 'error' });
        return;
      }
      if (!file) {
        enqueueSnackbar('The Attachment field is required', { variant: 'error' });
        return;
      }
      if (type === 'phase' && phase === '') {
        setFieldError('phase', 'The Phase field is required');
        return;
      }

      setSubmitting(true);
      const newReportData = {
        name: values.name.trim(),
        attachment: file,
        attachmentExt: extension,
        description: values.description.trim(),
        phaseId: type === 'general' ? null : phase,
        categoryId: reportCategory,
        isGeneral: type === 'general',
        projectId: isPA ? localStorage.getItem('projectId') : projectId ? projectId : projectIdASE,
      };
      const response = await dispatch(addReport(newReportData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/reports');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  const fileUpload = async (val) => {
    const allowFile = ['doc', 'docx', 'pdf', 'xls', 'xlsx', 'csv'];
    if (allowFile.includes(val.name.split('.').pop())) {
      if (val && val.size <= 2097152) {
        const response = await dispatch(
          uploadFileS3signedUrl({
            key: `${Date.now()}-${val.name.replace(' ', '')}`,
            ContentType: val.type,
            folderName: envData.FOLDER_NAME_REPORT,
          }),
        );
        if (response.status === 200) {
          setfile(response?.data?.data?.url);
          setExtension(val.name.split('.').pop());
          await dispatch(uploadFileS3({ url: response.data.data.signedUrl, data: val }));
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('The document may not be greater than 2 MB', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Please enter only pdf, excel and docx file', {
        variant: 'error',
      });
    }
  };
  const projectSelect = async (value) => {
    setProjectIdASE(value);
    await dispatch(getPhases({ projectId: value }));
  };
  useEffect(async () => {
    await dispatch(getPhases({ projectId: isPA ? localStorage.getItem('projectId') : projectId }));
    await dispatch(getReportCategory({}));
    if (isASE && !projectId) {
      await dispatch(getSelectProjects({}));
    }
  }, [dispatch, projectId]);
  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <Card sx={{ display: 'flex', mb: 3.5 }}>
      <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
        <CardContent>
          <Typography component={'h2'} variant="h1" mb={3}>
            Add New Report
          </Typography>
          <FormikProvider value={formik}>
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
              {mutation.isError && <p>{mutation.error.message}</p>}
              <Grid container spacing={3}>
                {isASE && !projectId && (
                  <Grid item xs={12} sm={12}>
                    <JumboSelectField
                      fullWidth
                      label={'Select ProjectId*'}
                      valueKey="_id"
                      name={'project'}
                      variant="outlined"
                      labelKey={'uniqId'}
                      data={projects?.selectList}
                      value={projectIdASE}
                      onChange={(event) => {
                        projectSelect(event.target.value);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <Radio
                    checked={type === 'phase'}
                    onChange={(event) => {
                      setType(event.target.value);
                    }}
                    value="phase"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  Phase Wise
                  <Radio
                    checked={type === 'general'}
                    onChange={(event) => {
                      setType(event.target.value);
                    }}
                    value="general"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  Common
                </Grid>
                {type === 'phase' && (
                  <Grid item xs={12} sm={6}>
                    <JumboSelectField
                      fullWidth
                      label={'Select Phase*'}
                      valueKey="_id"
                      name={'phase'}
                      variant="outlined"
                      labelKey={'name'}
                      data={phases?.list}
                      value={phase}
                      onChange={(event) => {
                        setPhase(event.target.value);
                        setFieldValue('phase', event.target.value);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <JumboSelectField
                    fullWidth
                    label={'Select Category*'}
                    valueKey="_id"
                    name={'category'}
                    variant="outlined"
                    labelKey={'name'}
                    data={reportCategorys?.list}
                    value={reportCategory}
                    onChange={(event) => {
                      setReportCategory(event.target.value);
                      setFieldValue('category', event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField fullWidth name="name" label="Name*" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button fullWidth size="large" variant="outlined" component="label">
                    <input
                      type="file"
                      accept=".doc, .docx, .pdf, .xls, .xlsx, .csv"
                      onChange={(e) => fileUpload(e.target.files[0])}
                      className="c-width"
                    />
                  </Button>
                  <p style={{ margin: '0', fontSize: '14px' }}>Maximum file upload size is 2 MB*</p>
                </Grid>
                <Grid item xs={12}>
                  <JumboTextField fullWidth name="description" label="Description*" multiline rows={2} maxRows={4} />
                </Grid>
              </Grid>
              <br></br>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isSubmitting || mutation.isLoading}>
                    Save
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="inherit"
                    size="large"
                    sx={{ mb: 3 }}
                    onClick={() => navigate('/reports')}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </Div>
    </Card>
  );
};

export default AddEditReport;
