import React, { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Grid,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Button,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import Chart from 'chart.js/auto';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CategoryScale } from 'chart.js';
import List from '@mui/material/List';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { MessageOutlined } from '@mui/icons-material';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CardIconText from '@jumbo/shared/CardIconText/CardIconText';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { userType } from 'app/helpers/userType';
import TasksList2 from 'app/shared/widgets/TasksList2';
import Div from '@jumbo/shared/Div/Div';
import {
  getASERegionWise,
  getPARegionWise,
  getProjectDetails,
  getProjectDetailsForASE,
  getProjectRegionWiseDate,
  getProjectRegionWiseStatus,
  getUsersGraph,
} from 'app/redux/actions/Dashboard';
import { getBarGraphProjectData, getBarGraphUserData, getDoughnutGraphUserData } from 'app/helpers/barChart';
import BarChart from '@jumbo/components/Chart/BarChart';
import DoughnutChart from '@jumbo/components/Chart/DoughnutChart';
import AreaChart from '@jumbo/components/Chart/AreaChart';
Chart.register(CategoryScale);

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobileView = useMediaQuery('(max-width:575px)');
  const { isMA, isASE } = userType();
  const dashboardData = useSelector((state) => state?.dashboard);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [projectDetail, setProjectDetail] = useState({});
  const [projectDetailforASE, setProjectDetailforASE] = useState({});
  const [barProjectDataDate, setBarProjectDataDate] = useState();
  const [barProjectDataStatus, setBarProjectDataStatus] = useState();
  const [barASEData, setBarASEData] = useState();
  const [barPAData, setBarPAData] = useState();
  const [doughnutUserData, setDoughnutUserData] = useState();
  const [statusObj, setstatusObj] = useState({ status: 2 });

  const deviceTypeChart = {
    labels: ['Android', 'Ios', 'Web'],
    datasets: [
      {
        backgroundColor: ['#bdd52f', '#0493d1', '#ffc305'],
        borderColor: ['#bdd52f', '#0493d1', '#ffc305'],
        data: [1, 1, 1],
        barThickness: 40,
      },
    ],
  };

  const generalInfo = [
    {
      id: 1,
      title: `${projectDetail?.approvalRequests ?? 0} Approval Requests`,
      icon: <TaskAltOutlinedIcon fontSize={'small'} />,
      link: '/approve-project',
    },
    {
      id: 2,
      title: `${projectDetail?.totalNearCompletionProjects ?? 0} Near Completion Projects`,
      icon: <FactCheckOutlinedIcon fontSize={'small'} />,
      link: '/',
    },
    {
      id: 3,
      title: `${projectDetail?.newFeedbackRequest ?? 0} New Feedback Requests`,
      icon: <MessageOutlined fontSize={'small'} />,
      link: '/feedback',
    },
  ];
  const totalInfo = [
    {
      id: 1,
      title: 'Projects',
      count: projectDetail?.totalProjects ?? 0,
      icon: <ViewListOutlinedIcon fontSize={'small'} />,
      link: '/project',
      bgColor: '#9eb517',
      color: '#ffffff',
    },
    {
      id: 2,
      title: 'Pidilite Teams',
      count: projectDetail?.totalAse ?? 0,
      icon: <PersonOutlineOutlinedIcon fontSize={'small'} />,
      link: '/ase',
      bgColor: '#0493d1',
      color: '#ffffff',
    },
    {
      id: 3,
      title: 'Application Teams',
      count: projectDetail?.totalPa ?? 0,
      icon: <PersonOutlineOutlinedIcon fontSize={'small'} />,
      link: '/supervisor-admin',
      bgColor: '#cda116',
      color: '#ffffff',
    },
    {
      id: 4,
      title: 'Customer Teams',
      count: projectDetail?.totalClient ?? 0,
      icon: <PersonOutlineOutlinedIcon fontSize={'small'} />,
      link: '/client',
      bgColor: '#0068B3',
      color: '#ffffff',
    },
  ];
  useMemo(() => {
    setBarProjectDataDate(getBarGraphProjectData(dashboardData.projectRegionWiseDateList));
    setStartDate(dashboardData.startDate);
    setEndDate(dashboardData.endDate);
  }, [dashboardData.projectRegionWiseDateList]);
  useMemo(() => {
    setBarProjectDataStatus(getBarGraphProjectData(dashboardData.projectRegionWiseStatusList));
  }, [dashboardData.projectRegionWiseStatusList]);
  useMemo(() => {
    setBarASEData(getBarGraphUserData(dashboardData.ASERegionWiseList));
  }, [dashboardData.ASERegionWiseList]);
  useMemo(() => {
    setBarPAData(getBarGraphUserData(dashboardData.PARegionWiseList));
  }, [dashboardData.PARegionWiseList]);

  useEffect(async () => {
    if (startDate && endDate) {
      await dispatch(
        getProjectRegionWiseDate({
          startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
          endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
        }),
      );
    }
  }, [startDate, endDate]);
  useEffect(async () => {
    await dispatch(getProjectRegionWiseStatus(statusObj));
  }, [statusObj]);
  useEffect(async () => {
    if (isMA) {
      const response = await dispatch(getProjectDetails());
      if (response.status === 200) {
        setProjectDetail(response?.data?.data?.projectsDetailsList);
      }
      await dispatch(getProjectRegionWiseDate({}));
      await dispatch(getPARegionWise({ roleType: 3 }));
      await dispatch(getASERegionWise({ roleType: 2 }));
      const userChartResponse = await dispatch(getUsersGraph());
      if (userChartResponse.status === 200) {
        setDoughnutUserData(getDoughnutGraphUserData(userChartResponse?.data?.data?.userGraphData));
      }
    } else {
      const ASEresponse = await dispatch(getProjectDetailsForASE());
      if (ASEresponse.status === 200) {
        setProjectDetailforASE(ASEresponse?.data?.data?.projectsDetailsList);
      }
    }
  }, []);

  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          DASHBOARD
        </Typography>
      )}
      <Grid container spacing={3.75} mb={4}>
        {isMA ? (
          <Grid item xs={12} md={12}>
            <JumboCardQuick
              bgImage={`${ASSET_IMAGES}/bg-image.png`}
              sx={{ color: '#ffffff' }}
              title="Welcome Admin"
              color={'#ffffff'}
              wrapperSx={{ pt: 1 }}>
              <Grid container spacing={3.75} className="justify-space-around">
                <Grid item className="you_dashboard">
                  <Typography variant={'h5'} sx={{ color: '#ffffff' }}>
                    You Have
                  </Typography>
                  <List>
                    {generalInfo.map((item, index) => (
                      <ListItemButton
                        key={index}
                        onClick={() => navigate(item.link)}
                        sx={{
                          px: 0,
                          py: 0.25,

                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}>
                        <ListItemIcon sx={{ minWidth: 32, color: '#ffffff' }}>{item.icon}</ListItemIcon>
                        <ListItemText primary={<Typography>{item.title}</Typography>} />
                      </ListItemButton>
                    ))}
                  </List>
                </Grid>
                <Grid item className="portal_use_dashboard">
                  {projectDetail?.portalUsage && <AreaChart data={projectDetail?.portalUsage} />}
                </Grid>
                <Grid item className="total_dashboard">
                  <Typography variant={'h5'} sx={{ color: '#ffffff' }}>
                    Total
                  </Typography>
                  <List>
                    {totalInfo.map((item, index) => (
                      <ListItemButton
                        className="justify-end"
                        key={index}
                        onClick={() => navigate(item.link)}
                        sx={{
                          px: 0,
                          py: 0.25,

                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}>
                        <ListItemIcon sx={{ minWidth: 32, color: '#ffffff' }}>{item.icon}</ListItemIcon>
                        <ListItemText className="flex09" primary={<Typography>{item.title}</Typography>} />
                        <Button
                          style={{
                            backgroundColor: item.bgColor,
                            color: item.color,
                          }}
                          className="min-width-55"
                          size={'small'}
                          variant={'outlined'}>
                          {item.count}
                        </Button>
                      </ListItemButton>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </JumboCardQuick>
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <JumboCardQuick
              bgImage={`${ASSET_IMAGES}/bg-image.png`}
              sx={{ color: '#ffffff' }}
              color={'#ffffff'}
              title={`Welcome, ${projectDetailforASE?.userName}`}
              titleRight={`Region-${projectDetailforASE?.region}`}
              wrapperSx={{ pt: 1 }}>
              <Typography variant={'h5'} sx={{ color: '#ffffff' }}>
                Project Summary
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <CardIconText
                    icon={<img src={`${ASSET_IMAGES}/dashboradActive.png`} style={{ width: '55px' }} alt="Pidilite" />}
                    title={
                      <Typography variant={'h4'} color={'common.white'}>
                        {projectDetailforASE?.totalOngoingProjects}
                      </Typography>
                    }
                    subTitle={
                      <Typography variant={'h6'} color={'common.white'}>
                        Ongoing Projects
                      </Typography>
                    }
                    color="#27B0EC"
                    disableHoverEffect={true}
                    hideArrow={true}
                    variant={'outlined'}
                    sx={{ background: '#27B0EC' }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CardIconText
                    icon={<img src={`${ASSET_IMAGES}/dashboradInactive.png`} style={{ width: '55px' }} alt="Pidilite" />}
                    title={
                      <Typography variant={'h4'} color={'common.white'}>
                        {projectDetailforASE?.totalInActiveProjects}
                      </Typography>
                    }
                    subTitle={
                      <Typography variant={'h6'} color={'common.white'}>
                        Inactive Projects
                      </Typography>
                    }
                    color="#0493D1"
                    disableHoverEffect={true}
                    hideArrow={true}
                    variant={'outlined'}
                    sx={{ background: '#0493D1' }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CardIconText
                    icon={<img src={`${ASSET_IMAGES}/dashboradHold.png`} style={{ width: '55px' }} alt="Pidilite" />}
                    title={
                      <Typography variant={'h4'} color={'common.white'}>
                        {projectDetailforASE?.totalOnHoldProjects}
                      </Typography>
                    }
                    subTitle={
                      <Typography variant={'h6'} color={'common.white'}>
                        On Hold Projects
                      </Typography>
                    }
                    color="#106EB8"
                    disableHoverEffect={true}
                    hideArrow={true}
                    variant={'outlined'}
                    sx={{ background: '#106EB8' }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CardIconText
                    icon={<img src={`${ASSET_IMAGES}/dashboradCompleted.png`} style={{ width: '55px' }} alt="Pidilite" />}
                    title={
                      <Typography variant={'h4'} color={'common.white'}>
                        {projectDetailforASE?.totalArchivedProjects}
                      </Typography>
                    }
                    subTitle={
                      <Typography variant={'h6'} color={'common.white'}>
                        Archived Projects
                      </Typography>
                    }
                    color="#223F9A"
                    disableHoverEffect={true}
                    hideArrow={true}
                    variant={'outlined'}
                    sx={{ background: '#223F9A' }}
                  />
                </Grid>
              </Grid>
            </JumboCardQuick>
          </Grid>
        )}
        {isASE ? (
          <Grid item xs={12} md={12}>
            <TasksList2 scrollHeight={373} />
          </Grid>
        ) : (
          ''
        )}
      </Grid>

      {isMA ? (
        <>
          <Typography variant={'h1'} mb={3} sx={{ fontWeight: '500' }}>
            Projects Overview
          </Typography>
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant={'h3'} className="mobile-resign-wise" mb={4} sx={{ fontWeight: '500' }}>
                      Region Wise
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Div
                      className="mobile-displa-block"
                      sx={{ display: 'flex', flexDirection: 'row', marginBottom: '23px', flex: 1, justifyContent: 'end' }}>
                      <Typography variant={'p'} className="mobile-displa-block" m={1}>
                        Select Date Range
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="c-input-bg mobile-date"
                          label="From Date"
                          value={startDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                      </LocalizationProvider>
                      <Typography variant={'p'} m={1} className="mobile-display-none">
                        -
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="c-input-border mobile-date"
                          label="To Date"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                      </LocalizationProvider>
                    </Div>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Stack spacing={3}> */}
                {barProjectDataDate?.labels && <BarChart tilteY="Projects" chartData={barProjectDataDate} />}
                {/* </Stack>
                  </Grid>
                </Grid> */}
              </CardContent>
            </Div>
          </Card>
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                {/* <Typography variant={'h3'} mb={4}> */}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant={'h3'} mb={2} sx={{ fontWeight: '500' }}>
                      Status Wise
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9} sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'end' }}>
                    <Button
                      color="secondary"
                      sx={{ mr: 2, mb: 2, color: 'black' }}
                      size={'small'}
                      variant={statusObj?.status === 2 ? 'contained' : 'outlined'}
                      onClick={() => setstatusObj({ status: 2 })}>
                      On Going
                    </Button>
                    <Button
                      color="secondary"
                      sx={{ mr: 2, mb: 2, color: 'black' }}
                      size={'small'}
                      variant={statusObj?.status === 4 ? 'contained' : 'outlined'}
                      onClick={() => setstatusObj({ status: 4 })}>
                      On Hold
                    </Button>
                    <Button
                      color="secondary"
                      sx={{ mr: 2, mb: 2, color: 'black' }}
                      size={'small'}
                      variant={statusObj?.recentlyAdded ? 'contained' : 'outlined'}
                      onClick={() => setstatusObj({ recentlyAdded: true })}>
                      Recently Added
                    </Button>
                    <Button
                      color="secondary"
                      sx={{ mr: 2, mb: 2, color: 'black' }}
                      size={'small'}
                      variant={statusObj?.status === 5 ? 'contained' : 'outlined'}
                      onClick={() => setstatusObj({ status: 5 })}>
                      Near Completion
                    </Button>
                  </Grid>
                </Grid>
                {/* </Typography> */}
                {/* <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Stack spacing={3}> */}
                {barProjectDataStatus?.labels && <BarChart tilteY="Projects" chartData={barProjectDataStatus} />}
                {/* </Stack>
                  </Grid>
                </Grid> */}
              </CardContent>
            </Div>
          </Card>
          <br></br>
          <Typography variant={'h2'} mb={3} sx={{ fontWeight: '500' }}>
            Pidilite Team
          </Typography>
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography variant={'h3'} mb={2} sx={{ fontWeight: '500' }}>
                  Region Wise
                </Typography>
                {barASEData?.labels && <BarChart tilteY="Teams" chartData={barASEData} />}
              </CardContent>
            </Div>
          </Card>
          <br></br>
          <Typography variant={'h2'} mb={3} sx={{ fontWeight: '500' }}>
            Application Team
          </Typography>
          <Card sx={{ display: 'flex', mb: 7 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography variant={'h3'} mb={2} sx={{ fontWeight: '500' }}>
                  Region Wise
                </Typography>
                {barPAData?.labels && <BarChart tilteY="Application Teams" chartData={barPAData} />}
              </CardContent>
            </Div>
          </Card>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {doughnutUserData?.labels && <DoughnutChart chartData={doughnutUserData} title="User Type" />}
            </Grid>
            <Grid item xs={12} sm={6}>
              {deviceTypeChart?.labels && <DoughnutChart chartData={deviceTypeChart} title="Device Type" />}
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
