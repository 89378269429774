import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import 'yup-phone';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Loader from '@jumbo/components/Loader';
import { addUser, editUser, getUserRole, updateUser } from 'app/redux/actions/User';
import RoleConstants from '@jumbo/constants/RoleConstants';
import JumboAutoComplete from '@jumbo/components/JumboFormik/JumboAutoComplete';
import { getPa } from 'app/redux/actions/Pa';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 100 characters!')
    .max(100, 'Name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  mobile: yup
    .string('Enter your mobile')
    .required('The Mobile Number field is required')
    .phone('IN', true, 'Mobile Number is invalid'),
  email: yup
    .string('Enter your email')
    .email('Please enter a valid email address in the format example@domain.com')
    .required('The Email field is required'),
  designation: yup
    .string('Enter your designation')
    .min(2, 'Designation can set of between 2 and 100 characters!')
    .max(100, 'Designation can set of between 2 and 100 characters!')
    .required('The Designation field is required'),
  company: yup
    .string('Enter your Company')
    .required('The Company field is required'),
});

const AddEditClient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.user);
  const companys = useSelector((state) => state?.pa);

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [client, setClient] = useState('');
  const [loader, setLoader] = useState(true);
  const [role, setRole] = useState('');
  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      name: client?.name ? client?.name : '',
      email: client?.email ? client?.email : '',
      mobile: client?.mobileNumber ? client?.mobileNumber : '',
      designation: client?.designation ? client?.designation : '',
      company: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newPaData = {
        name: values.name.trim(),
        email: values.email.trim(),
        mobileNumber: values.mobile.trim(),
        designation: values.designation.trim(),
        companyId: values.company,
        roleId: role,
      };
      const response = await dispatch(id ? updateUser(id, newPaData) : addUser(newPaData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/client');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    dispatch(getUserRole({ role: RoleConstants.CLIENT }));
    dispatch(getPa({}));
    if (id) {
      dispatch(editUser(id)).then((response) => {
        if (response.status === 200) {
          setClient(response?.data?.data?.userDetail);
          setFieldValue('name', response?.data?.data?.userDetail?.name);
          setFieldValue('email', response?.data?.data?.userDetail?.email);
          setFieldValue('mobile', response?.data?.data?.userDetail?.mobileNumber);
          setFieldValue('designation', response?.data?.data?.userDetail?.designation);
          setFieldValue('company', response?.data?.data?.userDetail?.companyId ?? '');
          setLoader(false);
        } else {
          enqueueSnackbar(response?.data?.responseMessage, {
            variant: 'error',
          });
          setLoader(false);
        }
      });
    } else {
      setLoader(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    setRole(users?.roleList[0]?._id);
  }, [users]);

  const { setFieldValue, handleSubmit, isSubmitting, values } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {id ? 'Update Customer Team' : 'Add Customer Team'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="name" label="Name*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="email" label="Email*" disabled={id} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <JumboTextField fullWidth name="mobile" label="Mobile*" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <JumboTextField fullWidth name="designation" label="Designation*" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <JumboAutoComplete
                        options={companys.list ?? []}
                        label="Company*"
                        size="large"
                        getOptionLabel={(option) => option?.name}
                        name="company"
                        value={companys.list.filter((val) => val._id === values.company)?.[0]}
                        onChange={(evt, value) => {
                          setFieldValue('company', value?._id);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting || mutation.isLoading}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => navigate('/client')}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default AddEditClient;
