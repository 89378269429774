import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, Button, Grid, useMediaQuery, Autocomplete, Box, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom/dist';
import { userType } from 'app/helpers/userType';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getSelectProjects } from 'app/redux/actions/Project';
import { getFeedback } from 'app/redux/actions/Feedback';
import FeedbackListCard from '@jumbo/components/List/FeedbackListCard';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';

const Feedback = () => {
  const dispatch = useDispatch();
  const { isPA } = userType();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const mutation = { isError: false };
  const projects = useSelector((state) => state?.project);
  const feedbacks = useSelector((state) => state?.feedback);
  const mobileView = useMediaQuery('(max-width:575px)');
  const [projectValue, setProjectValue] = useState('');
  const [viewFeedback, setViewFeedback] = useState(false);
  const [stage, setStage] = useState(1);
  const stages = [1, 2, 3, 4];

  const projectSelect = (value) => {
    setViewFeedback(false);
    setFieldValue('name', value?.name ?? '');
    setProjectValue(value?._id ?? '');
  };
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (projectValue === '') {
        enqueueSnackbar('The ProjectId field is required', { variant: 'error' });
        return;
      }
      setSubmitting(true);
      await dispatch(getFeedback({ projectId: projectValue, stage }));
      setViewFeedback(true);
      setSubmitting(false);
    },
  });
  const changeTopic = async (val) => {
    setStage(val);
    await dispatch(getFeedback({ projectId: projectValue, stage: val }));
  };
  useEffect(async () => {
    if (searchParams.get('projectId')) {
      await dispatch(getFeedback({ projectId: searchParams.get('projectId'), stage }));
      setViewFeedback(true);
      !isPA && (await dispatch(getSelectProjects({})));
      setProjectValue(searchParams.get('projectId'));
    } else {
      if (isPA) {
        setProjectValue(localStorage.getItem('projectId'));
        await dispatch(getFeedback({ projectId: localStorage.getItem('projectId'), stage }));
        setViewFeedback(true);
      } else {
        await dispatch(getSelectProjects({}));
      }
    }
  }, [dispatch]);
  const { setFieldValue, handleSubmit, isSubmitting } = formik;

  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          VIEW FEEDBACK
        </Typography>
      )}
      {!isPA && (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id="projectId"
                        size="small"
                        name="projectId"
                        options={projects?.selectList}
                        autoHighlight
                        onChange={(e, values) => projectSelect(values)}
                        getOptionLabel={(option) => option.uniqId}
                        getOptionSelected={(option) => option?._id === projectValue}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.uniqId}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            label="Project Id*"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField size="small" disabled fullWidth name="name" label="Project Name" />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting || mutation.isLoading}>
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
      {viewFeedback && (
        <Card sx={{ display: 'flex', mb: 3.5, overflow: 'auto' }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              {stages.map((val) => (
                <Button
                  color="secondary"
                  sx={{ mr: 2, mb: 2, color: 'black' }}
                  size={'small'}
                  onClick={() => changeTopic(val)}
                  variant={stage === val ? 'contained' : 'outlined'}>
                  Stage {val}
                </Button>
              ))}
              {feedbacks.list.map((project, key) => (
                <FeedbackListCard item={project} />
              ))}
              {feedbacks?.list?.length === 0 ? (
                <Typography variant={'h3'} color={'text.secondary'} mt={2}>
                  The customer Feedback form will be active on completion of{' '}
                  {stage === 1 ? 25 : stage === 2 ? 50 : stage === 3 ? 75 : 100}% of the project and visible in this panel
                  upon submission by the customer.
                </Typography>
              ) : (
                ''
              )}
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default Feedback;
