import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Card, CardContent, Typography, Tooltip } from '@mui/material';
import Div from '@jumbo/shared/Div/Div';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const CardComponent = ({ title, children, Component, addUrl }) => {
  const navigate = useNavigate();
  return (
    <Card  sx={{ display: 'flex', mb: 3.5, overflow: 'auto' }}>
      <Div  className='px-sm-8' sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
        <CardContent>
          <Typography variant={'h2'} mb={3}>
            <Div className="c-row">
              <Div className="c-col-8">{title}</Div>
              <Div className="c-col-4 custom-row">
                {Component && <Component />}
                {addUrl && (
                  <Tooltip title="Add New" placement="top-start" arrow>
                    <Div
                      onClick={() => navigate(addUrl)}
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        marginLeft: '15px',
                        backgroundColor: '#BDD52F',
                      }}>
                      <img
                        src={`${ASSET_IMAGES}/addBtn.png`}
                        alt="Pidilite"
                        style={{ width: '22px', margin: '9px 9px 9px 9px' }}
                      />
                    </Div>
                  </Tooltip>
                )}
              </Div>
            </Div>
          </Typography>
          {children}
        </CardContent>
      </Div>
    </Card>
  );
};

CardComponent.propTypes = {
  children: PropTypes.node,
};

export default CardComponent;
