import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  SET_USER_INFORMATION,
} from '@jumbo/utils/constants/ActionTypes';
import axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import envData from 'env_config';
axios.defaults.withCredentials = true;

export const setAuthUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = (loading) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = (status) => {
  return (dispatch) => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};
export const userInformation = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_INFORMATION,
      payload: user,
    });
  };
};

export const changePassword = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post(`${envData.API_BASE_URL_AUTH}auth/change-password`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post(`${envData.API_BASE_URL_AUTH}auth/forgot-password`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post(`${envData.API_BASE_URL_AUTH}auth/reset-password`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
