import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getAllowedPermissions, getPermissionsList, updatePermissions } from 'app/redux/actions/Role';
import Loader from '@jumbo/components/Loader';
import { userType } from 'app/helpers/userType';
import Div from '@jumbo/shared/Div';

const useStyles = makeStyles(() => ({
  dFlex: {
    display: 'inline-block',
    width: '250px',
  },
  marginLeftBtn: {
    marginLeft: 10,
  },
  moduleName: {
    alignSelf: 'baseline',
  },
  cmCheckBox: {
    '& label': {
      alignItems: 'flex-start',
      marginBottom: '10px',
      '& span.MuiCheckbox-root': {
        paddingTop: '0',
      },
    },
  },
}));
const Permissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMA } = userType();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [permission, setPermission] = useState([]);
  const [rolesPermission, setRolesPermission] = useState([]);
  const [loader, setLoader] = useState(true);
  const [permissionData] = useState([]);
  const checkedArray = [];

  const handleChange = (event, rec) => {
    if (event.target.checked === true) {
      checkedArray.push(rec?._id);
      setRolesPermission([...rolesPermission, ...checkedArray]);
    }
    if (event.target.checked === false) {
      const filter = rolesPermission.filter(function (data) {
        return data !== rec?._id;
      });
      setRolesPermission(filter);
    }
  };

  const handleSubmit = () => {
    const roleAndPermissions = { roleId: id, permissionId: rolesPermission };
    dispatch(updatePermissions(roleAndPermissions)).then((response) => {
      if (response?.status === 200) {
        const result = JSON.parse(localStorage.getItem('User'));
        if (isMA) {
          dispatch(getPermissionsList()).then((res) => {
            res &&
              res.permissionList.map((item) => {
                return (
                  item?.permissionData &&
                  item?.permissionData.map((permission) => {
                    return permissionData.push(permission);
                  })
                );
              });
            localStorage.setItem('permission', JSON.stringify(permissionData));
            return null;
          });
        } else {
          dispatch(getAllowedPermissions(result?.user?.roleId?._id)).then((response) => {
            if (response?.status === 200) {
              localStorage.setItem('permission', JSON.stringify(response?.data?.data?.rolePermissions?.permissionId));
            }
          });
        }
        navigate('/role');
      } else {
        enqueueSnackbar(response?.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };
  useEffect(() => {
    const getPermissionList = async () => {
      let response = await dispatch(getPermissionsList());
      setPermission(response?.permissionList);
    };
    if (id) {
      setLoader(true);
      dispatch(getAllowedPermissions(id)).then((response) => {
        if (response?.status === 200) {
          if (response?.data?.data?.rolePermissions) {
            const data = response?.data?.data?.rolePermissions?.permissionId.map((item) => {
              return item?._id;
            });
            setRolesPermission(data);
          }
          setLoader(false);
        }
      });
    }
    getPermissionList();
  }, [dispatch, id]);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                Role & Permissions
              </Typography>
              {permission?.length
                ? permission.map((single, index) => (
                    <Grid key={index} container spacing={3}>
                      <Grid item xs={12} lg={2} className={classes?.moduleName}>
                        <Typography component="h4" variant="inherit">
                          {single?.module + ' : '}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} lg={10}>
                        {single?.permissionData?.length &&
                          single?.permissionData.map((rec) => (
                            <div className={classes?.dFlex} key={rec?._id}>
                              <Checkbox
                                key={rec?._id}
                                label={rec?.displayName}
                                checked={rolesPermission.includes(rec?._id)}
                                onChange={(event) => handleChange(event, rec)}
                                name={rec?.name}
                                className={classes?.cmCheckBox}
                                // disabled={rec?.required_permission}
                              />{' '}
                              {rec?.displayName}
                            </div>
                          ))}
                      </Grid>
                    </Grid>
                  ))
                : []}
              <br></br>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    onClick={handleSubmit}
                    // loading={isSubmitting || mutation.isLoading}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="inherit"
                    size="large"
                    sx={{ mb: 3 }}
                    onClick={() => navigate('/role')}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default Permissions;
