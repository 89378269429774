import { GET_DISCUSSION_LIST, GET_TICKET } from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  ticketList: [],
  discussionList: [],
  ticketStatus: true,
  ticketTitle: '',
  topicName: '',
  discussionNo: '',
  projectId: '',
  ProjectName: ''
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TICKET: {
      return {
        ...state,
        ticketList: action?.payload?.ticketList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_DISCUSSION_LIST: {
      return {
        ...state,
        discussionList: action?.payload?.discussionOnTicket,
        ticketStatus: action?.payload?.ticketStatus?.status,
        ticketTitle: action?.payload?.ticketStatus?.ticketTitle,
        topicName: action?.payload?.ticketStatus?.topicId?.name,
        discussionNo: action?.payload?.ticketStatus?.ticketNumber,
        projectId: action?.payload?.ticketStatus?.projectId?.uniqId,
        ProjectName: action?.payload?.ticketStatus?.projectId?.name
      };
    }
    default: {
      return state;
    }
  }
};
