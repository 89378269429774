import axios from 'axios';

import { GET_TICKET, GET_DISCUSSION_LIST } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';
import envData from 'env_config';

export const getTicket = ({ isExport, page, perPage, topicId, projectId, status }) => {
  return (dispatch) => {
    return axios
      .get('/discussion/list', { params: { isExport, page, perPage, topicId, projectId, status } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_TICKET,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addTicket = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('/discussion', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const uploadFileTicket = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post(`${envData.API_BASE_URL_AUTH}upload`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const discussionList = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`/discussion/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_DISCUSSION_LIST,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const notiftToASEMsg = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('/discussion/notify-to-ase', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addTicketReply = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('/discussion/reply', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateDiscussionStatus = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`discussion/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
