import React from 'react';
import { Card, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import ControlledAccordions from '../common/Accordion';

const RejectProjectListCard = ({ item }) => {
  return (
    <>
      <Card sx={{ mb: 1, boxShadow: 'none' }}>
        <ControlledAccordions expand={true} descriptionKey={'Description'} descriptionValue={item.description}>
          <div className="wrap">
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '25%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
                Name
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {item.name}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '16%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
                Location
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {item.city ?? '-'}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '16%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
                Start Date
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {moment(item.startDate).format('DD/MM/YYYY')}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '16%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
                End Date
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {moment(item.endDate).format('DD/MM/YYYY')}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '30%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
                Reason
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {item.notes ?? '-'}
              </Typography>
            </Div>
          </div>
        </ControlledAccordions>
      </Card>
    </>
  );
};
export default RejectProjectListCard;
