import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getZones } from 'app/redux/actions/Zone';
import Loader from '@jumbo/components/Loader';
import ZoneListCard from '@jumbo/components/List/ZoneListCard';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import CardComponent from '@jumbo/components/common/Card';

const Zone = () => {
  const dispatch = useDispatch();
  const mobileView = useMediaQuery('(max-width:575px)');
  const zones = useSelector((state) => state?.zone);
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    dispatch(getZones());
    setLoader(false);
  }, [deleted, dispatch]);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              MANAGE ZONE
            </Typography>
          )}
          <CardComponent title={`List of Zones`} addUrl={'/add-zone'}>
            {zones?.list?.map((data) => (
              <ZoneListCard key={data._id} item={data} setDeleted={setDeleted} deleted={deleted} />
            ))}
            {zones?.list.length === 0 && <NoDataPlaceholder />}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default Zone;
