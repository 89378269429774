import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, Grid, TextField, Tooltip, Box, Autocomplete, useMediaQuery } from '@mui/material';
import ArchiveProjectListCard from '@jumbo/components/List/ArchiveProjectListCard';
import Div from '@jumbo/shared/Div';
import { Form, FormikProvider, useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, getProjectsCsv, getSelectProjects } from 'app/redux/actions/Project';
import RoleConstants from '@jumbo/constants/RoleConstants';
import { getClients } from 'app/redux/actions/Client';
import { getCompanyPA, getUser, getUserClient, getUserRegion } from 'app/redux/actions/User';
import moment from 'moment';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import Loader from '@jumbo/components/Loader';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';
import { CSVLink } from 'react-csv';
import { projectCSvExport, projectCsvHeaders } from 'app/helpers/csvExport';

const ApproveProject = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const projects = useSelector((state) => state?.project);
  const users = useSelector((state) => state?.user);
  const [loader, setLoader] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [projectValue, setProjectValue] = useState('');
  const [clientValue, setClientValue] = useState('');
  const [aseValue, setAseValue] = useState('');
  const [paValue, setPaValue] = useState('');
  const [region, setRegion] = useState('');
  const [page, setPage] = useState(1);
  const [CsvData, setCsvData] = useState([]);
  const count = Math.ceil(projects?.totalItems / perPage);
  const _DATA = usePagination(projects?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const mutation = { isError: false };
  const formik = useFormik({
    initialValues: {
      name: '',
      totalArea: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let data = {
        projectId: projectValue ?? '',
        projectName: values.name.trim(),
        status: 6,
        totalArea: values.totalArea.trim(),
        clientId: clientValue,
        paId: paValue,
        aseId: aseValue,
        startDate: startDate ? moment(new Date(startDate)).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(new Date(endDate)).format('YYYY-MM-DD') : '',
        regionId: region,
        page: page,
        perPage: perPage,
      };
      await dispatch(getProjects(data));
      await dispatch(getProjectsCsv(data));
      setSubmitting(false);
    },
  });
  let csvReport = {
    headers: projectCsvHeaders,
    data: CsvData,
    filename: 'archived-project-list.csv',
  };
  const ExportComponent = () => (
    <CSVLink {...csvReport}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}
        >
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  useEffect(() => {
    setCsvData(projectCSvExport(projects.csvList));
  }, [projects.csvList]);
  useEffect(async () => {
    await dispatch(getProjects({ status: 6, page, perPage }));
    await dispatch(getSelectProjects({ status: 6 }));
    await dispatch(getProjectsCsv({ status: 6 }));
    await dispatch(getClients({}));
    await dispatch(getUserRegion());
    await dispatch(getUser({ role: RoleConstants.ASE }));
    await dispatch(getCompanyPA({ role: RoleConstants.PA }));
    await dispatch(getUserClient({ role: RoleConstants.CLIENT }));
    setLoader(false);
  }, [dispatch, page]);

  const { handleSubmit, isSubmitting } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              ARCHIVE PROJECTS
            </Typography>
          )}
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography component={'h2'} variant="h1" mb={3}>
                  Search
                </Typography>
                <FormikProvider value={formik}>
                  <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <Autocomplete
                          id="projectId"
                          size="small"
                          name="projectId"
                          options={projects?.selectList}
                          autoHighlight
                          onChange={(e, values) => setProjectValue(values?.uniqId)}
                          getOptionLabel={(option) => option.uniqId}
                          getOptionSelected={(option) => option?._id === projectValue}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.uniqId}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              label="Project Id"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <JumboTextField size="small" fullWidth name="name" label="Name" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Autocomplete
                          id="region-select-demo"
                          size="small"
                          name="region"
                          options={users?.regionList}
                          autoHighlight
                          onChange={(e, values) => setRegion(values?._id)}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option) => option?._id === region}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              label="Region"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <JumboTextField
                          size="small"
                          fullWidth
                          name="totalArea"
                          label="Area"
                          InputProps={{ endAdornment: 'sqmt.' }}
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <Autocomplete
                          id="country-select-demo"
                          size="small"
                          name="client"
                          options={users.clientList}
                          autoHighlight
                          onChange={(e, values) => setClientValue(values?._id)}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option) => option?._id === clientValue}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              label="Customer Team"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Autocomplete
                          id="ase"
                          size="small"
                          name="ase"
                          options={users.list}
                          autoHighlight
                          onChange={(e, values) => setAseValue(values?._id)}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option) => option?._id === aseValue}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              label="Pidilite Team"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Autocomplete
                          id="pa"
                          size="small"
                          name="pa"
                          options={users.paList}
                          autoHighlight
                          onChange={(e, values) => setPaValue(values?._id)}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option) => option?._id === paValue}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              label="Application Team"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            fullWidth
                            label="Start Date"
                            value={startDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setStartDate(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            fullWidth
                            label="End Date"
                            value={endDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setEndDate(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx={{ mb: 3 }}
                          loading={isSubmitting || mutation.isLoading}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Div>
          </Card>
          <br></br>
          <CardComponent title={'Archived List'} Component={ExportComponent}>
            {projects.list.map((project) => (
              <ArchiveProjectListCard key={project._id} item={project} />
            ))}
            {projects?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default ApproveProject;
