import { GET_OLD_REPORT, GET_REPORT, GET_REPORT_CATEGORY, GET_EXPORT_REPORT } from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  reportList: [],
  oldReportList: [],
  exportReportList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_CATEGORY: {
      return {
        ...state,
        list: action?.payload?.reportCategoryList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_REPORT: {
      return {
        ...state,
        reportList: action?.payload?.reportList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_OLD_REPORT: {
      return {
        ...state,
        oldReportList: action?.payload?.oldReportsList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_EXPORT_REPORT: {
      return {
        ...state,
        exportReportList: action?.payload?.exportReportList,
      };
    }
    default: {
      return state;
    }
  }
};
