import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardContent, useMediaQuery, Autocomplete, Box, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import PhaseListCard from '@jumbo/components/List/PhaseListCard';
import { userType } from 'app/helpers/userType';
import { getPhases } from 'app/redux/actions/Phase';
import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { getSelectProjects } from 'app/redux/actions/Project';
import { useSnackbar } from 'notistack';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';

const Phase = () => {
  const { isPA } = userType();
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const { enqueueSnackbar } = useSnackbar();
  const phases = useSelector((state) => state?.phase);
  const projects = useSelector((state) => state?.project);
  const [projectValue, setProjectValue] = useState('');
  const [viewPhase, setViewPhase] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const mutation = { isError: false };
  const mobileView = useMediaQuery('(max-width:575px)');
  const [page, setPage] = useState(1);
  const count = Math.ceil(phases?.totalItems / perPage);
  const _DATA = usePagination(phases?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const projectSelect = (value) => {
    setViewPhase(false);
    setFieldValue('name', value?.name ?? '');
    setProjectValue(value?._id ?? '');
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      if (projectValue === '') {
        enqueueSnackbar('The ProjectId field is required', { variant: 'error' });
        return;
      }
      setSubmitting(true);
      await dispatch(getPhases({ projectId: projectValue, page, perPage }));
      setViewPhase(true);
      setSubmitting(false);
    },
  });

  useEffect(async () => {
    if (isPA) {
      setProjectValue(localStorage.getItem('projectId'));
      await dispatch(getPhases({ projectId: localStorage.getItem('projectId'), page, perPage }));
      setViewPhase(true);
    } else {
      await dispatch(getSelectProjects({}));
      if (projectValue !== '') {
        await dispatch(getPhases({ projectId: projectValue, page, perPage }));
      }
    }
  }, [deleted, dispatch, page]);

  const { setFieldValue, handleSubmit, isSubmitting } = formik;

  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          PHASES
        </Typography>
      )}

      {!isPA && (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id="projectId"
                        size="small"
                        name="projectId"
                        options={projects?.selectList}
                        autoHighlight
                        onChange={(e, values) => projectSelect(values)}
                        getOptionLabel={(option) => option.uniqId}
                        getOptionSelected={(option) => option?._id === projectValue}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.uniqId}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            label="Project Id*"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField size="small" disabled fullWidth name="name" label="Project Name" />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting || mutation.isLoading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
      {viewPhase && projectValue !== '' && (
        <CardComponent title={'List of Phases'} addUrl={isPA ? '/add-phase' : `/add-phase/${projectValue}`}>
          {phases?.list?.map((val) => (
            <PhaseListCard key={val._id} item={val} setDeleted={setDeleted} deleted={deleted} />
          ))}
          {phases?.list?.length !== 0 ? (
            <JumboPagination count={count} page={page} handleChange={handleChangePage} />
          ) : (
            <NoDataPlaceholder />
          )}
        </CardComponent>
      )}
    </>
  );
};

export default Phase;
