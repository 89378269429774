import axios from 'axios';

import { GET_PROJECT_TOPIC, GET_TOPIC } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';

export const getTopic = ({ page, perPage }) => {
  return (dispatch) => {
    return axios
      .get('/discussion-topic/list', { params: { page, perPage } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_TOPIC,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getProjectTopic = ({ projectId }) => {
  return (dispatch) => {
    return axios
      .get('/discussion-topic/list', { params: { projectId } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PROJECT_TOPIC,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
export const addTopic = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('discussion-topic', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editTopic = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`discussion-topic/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateTopic = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`discussion-topic/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deleteTopic = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`discussion-topic/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
