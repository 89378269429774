import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Typography, Card, CardContent, Grid, useMediaQuery, Tooltip } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Loader from '@jumbo/components/Loader';
import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import ClientListMACard from '@jumbo/components/List/ClientListMACard';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import { userType } from 'app/helpers/userType';
import RoleConstants from '@jumbo/constants/RoleConstants';
import { getUser, getUserClient } from 'app/redux/actions/User';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import JumboPagination from '@jumbo/components/JumboPagination';
import CardComponent from '@jumbo/components/common/Card';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { clientCSvExport, clientsCsvHeaders } from 'app/helpers/csvExport';

const Client = () => {
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const mobileView = useMediaQuery('(max-width:575px)');
  const { enqueueSnackbar } = useSnackbar();
  const users = useSelector((state) => state?.user);
  const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));
  const { isMA } = userType();
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [CsvData, setCsvData] = useState([]);
  const mutation = { isError: false };
  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
  });
  const count = Math.ceil(users?.totalItems / perPage);
  const _DATA = usePagination(users?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const getPermission = () => {
    if (isMA) {
      setPermission({ createPermission: true, updatePermission: true, deletePermission: true });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
      };
      const clientPermission = permissionData?.filter((item) => {
        return item?.moduleName?.includes('Client') && item;
      });
      clientPermission?.length &&
        clientPermission.forEach(function (num) {
          if (num?.name === 'create-client') {
            permissionValue.createPermission = true;
          }
          if (num?.name === 'delete-client') {
            permissionValue.deletePermission = true;
          }
          if (num?.name === 'update-client') {
            permissionValue.updatePermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let data = {
        name: values.name.trim(),
        email: values.email.trim(),
        role: RoleConstants.CLIENT,
        page: 1,
        perPage,
      };
      await dispatch(getUser(data));
      await dispatch(getUserClient(data));
      setPage(1);
      setSubmitting(false);
    },
  });
  let csvReport = {
    headers: clientsCsvHeaders,
    data: CsvData,
    filename: 'clients-list.csv',
  };
  const ExportComponent = () => (
    <CSVLink {...csvReport}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}
        >
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  useEffect(() => {
    getPermission();
  }, []);
  useEffect(() => {
    setCsvData(clientCSvExport(users.clientList));
  }, [users.clientList]);
  useEffect(async () => {
    let value = { role: RoleConstants.CLIENT, page, perPage };
    const response = await dispatch(getUser(value));
    await dispatch(getUserClient({ role: RoleConstants.CLIENT }));
    if (response.status !== 200) {
      enqueueSnackbar(response?.data?.responseMessage, {
        variant: 'error',
      });
    }
    setLoader(false);
  }, [deleted, dispatch, enqueueSnackbar, page]);
  const { handleSubmit, isSubmitting } = formik;

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
            CUSTOMERS TEAMS
            </Typography>
          )}
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography component={'h2'} variant="h1" mb={3}>
                  Search
                </Typography>
                <FormikProvider value={formik}>
                  <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <JumboTextField size="small" fullWidth name="name" label="Name" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <JumboTextField size="small" fullWidth name="email" label="Email" />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx={{ mb: 3 }}
                          loading={isSubmitting || mutation.isLoading}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Div>
          </Card>
          <CardComponent title={`List of Customer Teams`} addUrl={'/add-client'} Component={ExportComponent}>
            {users?.list?.map((data) => (
              <ClientListMACard
                key={data._id}
                item={data}
                setDeleted={setDeleted}
                deleted={deleted}
                permission={permission}
              />
            ))}
            {users?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default Client;
