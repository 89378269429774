import React from 'react';
import { Card, Chip, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import JumboDdMenu from '../JumboDdMenu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ControlledAccordions from '../common/Accordion';
import ReplyIcon from '@mui/icons-material/Reply';

const TikitListCard2 = ({ item }) => {
  const navigate = useNavigate();

  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'view':
        navigate(`/discussion-view/${item?._id}`);
        break;
    }
  };
  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions>
        <div className="wrap">
        <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '12%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Project Id
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.projectId?.uniqId}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Discussion No.
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.ticketNumber}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '35%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Discussion Title
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.ticketTitle}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Start Date
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item?.createdAt).format('DD/MM/YYYY')}
            </Typography>
          </Div>
          <Div className="plus-icon-15" sx={{ flex: { xs: '0 1 auto', lg: 1 }, flexShrink: 0, px: 1 }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Status
            </Typography>
            {item.status ? (
              <Chip color={'primary'} size={'small'} label={'Open'} />
            ) : (
              <Chip color={'warning'} size={'small'} label={'Closed'} />
            )}
          </Div>
          <Div className="plus-icon-15" sx={{ width: '5%', flexShrink: 0, px: 1 }}>
            <JumboDdMenu
              icon={<MoreHorizIcon />}
              menuItems={[
                { icon: <VisibilityIcon />, title: 'View', action: 'view' },
                { icon: <ReplyIcon />, title: 'Reply', action: 'view' },
              ]}
              onClickCallback={handleItemAction}
            />
          </Div>
        </div>
      </ControlledAccordions>
    </Card>
  );
};
export default TikitListCard2;
