import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, Card, CardContent, Grid, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Massage from '@jumbo/components/Message/Massage';
import { addTicketReply, discussionList, notiftToASEMsg } from 'app/redux/actions/Discussion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { userType } from 'app/helpers/userType';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import { uploadFileS3, uploadFileS3signedUrl } from 'app/redux/actions/Report';
import envData from 'env_config';

const validationSchema = yup.object({
  message: yup.string('Enter your message').required('The Message field is required'),
});
const ViewDiscussion = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const discussions = useSelector((state) => state?.discussion);
  const mutation = { isError: false };
  const { isMA, isPA } = userType();
  const [adminMsg, setAdminMsg] = useState('');
  const [file, setFile] = useState();
  const [attachment, setAttachment] = useState();
  const [status, setStatus] = useState(true);

  const notiftToASE = async () => {
    if (adminMsg.trim() === '') {
      enqueueSnackbar('The Message field is required', { variant: 'error' });
    } else {
      const notiftToASEMsgData = { ticketId: id, comment: adminMsg.trim() };
      const response = await dispatch(notiftToASEMsg(notiftToASEMsgData));
      if (response.status === 200) {
        setAdminMsg('');
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    }
  };
  const fileUpload = async (val) => {
    const allowFile = ['jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'csv'];
    if (allowFile.includes(val.target.files[0].name.split('.').pop())) {
      const fileData = val.target.files[0];
      if (fileData && fileData.size <= 2097152) {
        setFile(val);
        const response = await dispatch(
          uploadFileS3signedUrl({
            key: `${Date.now()}-${fileData.name.replace(' ', '')}`,
            ContentType: fileData.type,
            folderName: envData.FOLDER_NAME_DISCUSSION,
          }),
        );
        if (response.status === 200) {
          setAttachment(response?.data?.data?.url);
          await dispatch(uploadFileS3({ url: response.data.data.signedUrl, data: fileData }));
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('The document may not be greater than 2 MB', {
          variant: 'error',
        });
        setFile({ ...(file.target.value = null) });
      }
    } else {
      enqueueSnackbar('Please enter only pdf, image, excel and docx file', {
        variant: 'error',
      });
    }
  };

  useEffect(async () => {
    dispatch(discussionList(id));
  }, [dispatch]);
  useEffect(async () => {
    setStatus(discussions?.ticketStatus);
  }, [discussions?.ticketStatus]);

  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          VIEW DISCUSSION THREAD
        </Typography>
      )}
      <Card sx={{ display: 'flex', mb: 3.5 }}>
        <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }} className="discussion-width">
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={10} sm={11}>
                <Typography variant={'h5'} mb={0.5} className="discussion-title">
                  {`Project Id - ${discussions?.projectId}`}
                </Typography>
                <Typography variant={'h5'} mb={0.5} className="discussion-title">
                  {`Project Name - ${discussions?.ProjectName}`}
                </Typography>
                <Typography variant={'h5'} mb={0.5}>
                  {`Discussion No. - ${discussions?.discussionNo}`}
                </Typography>
                <Typography variant={'h5'} mb={0.5}>
                  {`Discussion Category - ${discussions?.topicName}`}
                </Typography>
                <Typography variant={'h5'} mb={0.5} className="discussion-title">
                  {`Discussion Title - ${discussions?.ticketTitle}`}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1}>
                <Tooltip title="Back" placement="top-start" arrow>
                  <Div
                    style={{
                      width: '40px',
                      height: '40px',
                      marginLeft: 'auto',
                      borderRadius: '50%',
                      backgroundColor: '#BDD52F',
                    }}
                    onClick={() => navigate(isPA ? '/discussion' : '/view-thread')}>
                    <ArrowBackIcon style={{ width: '22px', margin: '9px' }} />
                  </Div>
                </Tooltip>
              </Grid>
            </Grid>

            {discussions?.discussionList?.map((val, i) => (
              <Massage data={val} key={val._id} color={i} />
            ))}
            {discussions?.discussionList?.length === 0 && <NoDataPlaceholder />}
            <br></br>
            {isMA && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={10}>
                  <TextField
                    fullWidth
                    name="message"
                    size="small"
                    label="Write Massage"
                    value={adminMsg}
                    onChange={(e) => setAdminMsg(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button fullWidth type="submit" variant="contained" onClick={notiftToASE}>
                    Notify to Pidilite Team
                  </Button>
                </Grid>
              </Grid>
            )}
            {!isMA && (
              <>
                {status && (
                  <>
                    <br></br>
                    <Formik
                      validateOnChange={true}
                      initialValues={{
                        message: '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={async (data, { resetForm, setSubmitting }) => {
                        setSubmitting(true);
                        const newPaData = {
                          comment: data.message.trim(),
                          ticketId: id,
                          attachment: attachment,
                        };
                        const response = await dispatch(addTicketReply(newPaData));
                        if (response.status === 200) {
                          enqueueSnackbar(response.data?.responseMessage, {
                            variant: 'success',
                          });
                          await dispatch(discussionList(id));
                          resetForm();
                          setFile({ ...(file.target.value = null) });
                        } else {
                          enqueueSnackbar(response.data?.responseMessage, {
                            variant: 'error',
                          });
                        }
                        setSubmitting(false);
                      }}>
                      {({ isSubmitting }) => (
                        <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
                          {mutation.isError && <p>{mutation.error.message}</p>}
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <JumboTextField
                                size="small"
                                fullWidth
                                name="message"
                                label="Add Reply*"
                                multiline
                                rows={3}
                                maxRows={4}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <Button fullWidth size="large" variant="outlined" component="label">
                                <input
                                  type="file"
                                  accept=".jpg, .jpeg, .png, .gif, .doc, .docx, .pdf, .xls, .xlsx, .csv"
                                  onChange={(e) => fileUpload(e)}
                                  className="c-width"
                                />
                              </Button>
                              <p style={{ margin: '0', fontSize: '14px' }}>Maximum file upload size is 2 MB</p>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{ mb: 3 }}
                                loading={isSubmitting || mutation.isLoading}>
                                Send
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
              </>
            )}
          </CardContent>
        </Div>
      </Card>
    </>
  );
};

export default ViewDiscussion;
