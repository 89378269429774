import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import 'yup-phone';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Div from '@jumbo/shared/Div';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { addUser, editUser, getUserRegion, getUserRole, updateUser } from 'app/redux/actions/User';
import Loader from '@jumbo/components/Loader';
import RoleConstants from '@jumbo/constants/RoleConstants';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 100 characters!')
    .max(100, 'Name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  region: yup.string('Enter your region').required('The Region field is required'),
  mobile: yup
    .string('Enter your mobile')
    .required('The Mobile Number field is required')
    .phone('IN', true, 'Mobile Number is invalid'),
  email: yup
    .string('Enter your email')
    .email('Please enter a valid email address in the format example@domain.com')
    .required('The Email field is required'),
  designation: yup
    .string('Enter your designation')
    .min(2, 'Designation can set of between 2 and 100 characters!')
    .max(100, 'Designation can set of between 2 and 100 characters!')
    .required('The Designation field is required'),
  division: yup.string('Enter your division').required('The Division field is required'),
});

const AddEditASE = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const users = useSelector((state) => state?.user);
  const [user, setUser] = useState('');
  const [region, setRegion] = useState('');
  const [division, setDivision] = useState('');
  const [role, setRole] = useState('');
  const [loader, setLoader] = useState(true);
  const mutation = { isError: false };
  const divisionArr = [
    { _id: 'PLUB', name: 'PLUB' },
    { _id: 'CIPY', name: 'CIPY' },
    { _id: 'MSP', name: 'MSP' },
  ];
  const formik = useFormik({
    initialValues: {
      name: user.name ? user.name : '',
      email: user.email ? user.email : '',
      mobile: user.mobileNumber ? user.mobileNumber : '',
      region: user.regionId ? user.regionId : '',
      designation: user.designation ? user.designation : '',
      division: user.division ? user.division : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newPaData = {
        name: values.name.trim(),
        email: values.email.trim(),
        mobileNumber: values.mobile.trim(),
        designation: values.designation.trim(),
        division: division,
        regionId: region,
        roleId: role,
      };
      const response = await dispatch(id ? updateUser(id, newPaData) : addUser(newPaData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/ase');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    dispatch(getUserRole({ role: RoleConstants.ASE }));
    dispatch(getUserRegion());
    if (id) {
      dispatch(editUser(id)).then((response) => {
        if (response.status === 200) {
          setUser(response?.data?.data?.userDetail);
          setFieldValue('name', response?.data?.data?.userDetail?.name);
          setFieldValue('email', response?.data?.data?.userDetail?.email);
          setFieldValue('mobile', response?.data?.data?.userDetail?.mobileNumber);
          setFieldValue('region', response?.data?.data?.userDetail?.regionId);
          setFieldValue('designation', response?.data?.data?.userDetail?.designation);
          setFieldValue('division', response?.data?.data?.userDetail?.division ?? '');
          setRegion(response?.data?.data?.userDetail?.regionId ?? '');
          setRole(response?.data?.data?.userDetail?.roleId ?? '');
          setDivision(response?.data?.data?.userDetail?.division ?? '');
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    setLoader(false);
  }, [dispatch, id]);

  useEffect(() => {
    setRole(users?.roleList[0]?._id);
  }, [users]);

  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {id ? 'Update Pidilite Team' : 'Add Pidilite Team'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="name" label="Name*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="email" label="Email*" disabled={id} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="mobile" label="Mobile*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboSelectField
                        fullWidth
                        label={'Region*'}
                        valueKey="_id"
                        name={'region'}
                        variant="outlined"
                        labelKey={'name'}
                        data={users?.regionList}
                        value={region}
                        onChange={(e) => {
                          setRegion(e.target.value);
                          setFieldValue('region', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="designation" label="Designation*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboSelectField
                        fullWidth
                        label={'Division*'}
                        valueKey="_id"
                        name={'division'}
                        variant="outlined"
                        labelKey={'name'}
                        data={divisionArr}
                        value={division}
                        onChange={(e) => {
                          setDivision(e.target.value);
                          setFieldValue('division', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting || mutation.isLoading}>
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => navigate('/ase')}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default AddEditASE;
