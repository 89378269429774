export const getBarGraphUserData = (GraphData) => {
  let label = [];
  let dataset = [];
  if (GraphData?.length) {
    GraphData.forEach((element) => {
      label = [...label, element.regionName];
      dataset = [...dataset, element.totalUser];
    });
    return {
      labels: [...label],
      datasets: [
        {
          backgroundColor: '#0493D1',
          borderColor: '#0493D1',
          data: dataset,
          barThickness: 40,
        },
      ],
    };
  }
};

export const getBarGraphProjectData = (GraphData) => {
  let label = [];
  let dataset = [];
  if (GraphData?.length) {
    GraphData.forEach((element) => {
      label = [...label, element.regionName];
      dataset = [...dataset, element.totalProject];
    });
    return {
      labels: [...label],
      datasets: [
        {
          backgroundColor: '#0493D1',
          borderColor: '#0493D1',
          data: dataset,
          barThickness: 40,
        },
      ],
    };
  }
};

export const getDoughnutGraphUserData = (GraphData) => {
  let label = [];
  let dataset = [];
  if (GraphData?.length) {
    GraphData.forEach((element) => {
      label = [...label, element.userRole];
      dataset = [...dataset, element.totalUser];
    });
    return {
      labels: [...label],
      datasets: [
        {
          backgroundColor: ['#bdd52f', '#0493d1', '#ffc305'],
          borderColor: ['#bdd52f', '#0493d1', '#ffc305'],
          data: dataset,
          barThickness: 40,
        },
      ],
    };
  }
};
