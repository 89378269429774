import React from 'react';
import Div from '@jumbo/shared/Div';
import { ASSET_IMAGES } from '../../utils/constants/paths';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const Logo = ({ mini, mode, sx }) => {
  const mobileView = useMediaQuery('(max-width:575px)');

  return (
    <Div sx={{ display: 'inline-flex', ...sx }}>
      <NavLink to={'/'}>
        {!mini ? (
          <img
            src={mode === 'light' ? `${ASSET_IMAGES}/disha_logo.png` : `${ASSET_IMAGES}/logo-white.png`}
            alt="Pidilite"
            style={{ width: mobileView ? '100px' : '120px' }}
          />
        ) : (
          <img
            src={mode === 'light' ? `${ASSET_IMAGES}/logo-short.png` : `${ASSET_IMAGES}/logo-short-white.png`}
            alt="Jumbo React"
          />
        )}
      </NavLink>
    </Div>
  );
};

Logo.defaultProps = {
  mode: 'light',
};

export default Logo;
