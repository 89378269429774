import React from 'react';
import Chart from 'react-google-charts';
// import { useMediaQuery } from '@mui/material';

const Gantt = ({ chatData }) => {
  // const mobileView = useMediaQuery('(max-width:575px)');
  const options = {
    gantt: {
      criticalPathEnabled: false,
      // labelMaxWidth: mobileView ? 300 : 450,
    },
  };
  return (
    <Chart
      width={'100%'}
      height={chatData.length * 50}
      chartType="Gantt"
      loader={<div>Loading Chart</div>}
      data={chatData}
      options={options}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};

export default Gantt;
