import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, LinearProgress } from '@mui/material';
import { Stack } from '@mui/system';
import styled from '@mui/material/styles/styled';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import { getProjectListForASE } from 'app/redux/actions/Dashboard';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  flex: 1,
}));
const TasksList2 = ({ scrollHeight }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state) => state?.dashboard);
  const [statusObj, setstatusObj] = useState({ recentlyAdded: true });

  useEffect(async () => {
    await dispatch(getProjectListForASE(statusObj));
  }, [statusObj]);

  return (
    <JumboCardQuick
      className="product-list-responsive"
      title={
        <Typography
          component={'div'}
          className="product-list-section"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography variant={'h2'} className="project-list-title" mb={0}>
            Project List
          </Typography>

          <Stack
            direction={'row'}
            spacing={1}
            sx={{
              flex: 1,
              justifyContent: 'end',
            }}>
            <Button
              className="project-list-btn"
              color="secondary"
              sx={{ color: 'black' }}
              size={'small'}
              variant={statusObj?.recentlyAdded === true ? 'contained' : 'outlined'}
              onClick={() => setstatusObj({ recentlyAdded: true })}>
              Recently Added
            </Button>
            <Button
              className="project-list-btn"
              color="secondary"
              sx={{ color: 'black' }}
              size={'small'}
              variant={statusObj?.status === 2 ? 'contained' : 'outlined'}
              onClick={() => setstatusObj({ status: 2 })}>
              On Going
            </Button>
            <Button
              className="project-list-btn"
              color="secondary"
              sx={{ color: 'black' }}
              size={'small'}
              variant={statusObj?.status === 4 ? 'contained' : 'outlined'}
              onClick={() => setstatusObj({ status: 4 })}>
              On Hold
            </Button>
            <Button
              className="project-list-btn"
              color="secondary"
              sx={{ color: 'black' }}
              size={'small'}
              variant={statusObj?.status === 5 ? 'contained' : 'outlined'}
              onClick={() => setstatusObj({ status: 5 })}>
              Near Completion
            </Button>
            <Button
              className="project-list-btn"
              color="secondary"
              sx={{ color: 'black' }}
              size={'small'}
              variant={statusObj?.status === 8 ? 'contained' : 'outlined'}
              onClick={() => setstatusObj({ status: 8 })}>
              Inactive
            </Button>
          </Stack>
        </Typography>
      }
      headerSx={{
        borderBottom: 1,
        borderColor: 'divider',

        '& .MuiCardHeader-action': {
          my: -0.75,
        },
      }}
      wrapperSx={{ p: 0 }}>
      <JumboScrollbar
        autoHeight
        autoHeightMin={scrollHeight ? scrollHeight : 408}
        autoHide
        autoHideDuration={200}
        autoHideTimeout={500}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#27b0ec' }}>
              <TableCell sx={{ color: '#ffffff', fontSize: '16px' }}>ID</TableCell>
              <TableCell sx={{ color: '#ffffff', fontSize: '16px' }} align="right">
                Name
              </TableCell>
              <TableCell sx={{ color: '#ffffff', fontSize: '16px' }} align="right">
                Start Date
              </TableCell>
              <TableCell sx={{ color: '#ffffff', fontSize: '16px' }} align="right">
                End Date
              </TableCell>
              <TableCell sx={{ color: '#ffffff', fontSize: '16px' }} align="right">
                Progress
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboardData?.projectListForASE.map((row) => (
              <TableRow key={row._id} sx={{ color: 'black' }} onClick={() => navigate(`/view-project/${row._id}`)}>
                <TableCell component="th" scope="row">
                  {row.uniqId}
                </TableCell>
                <TableCell align="right">{row?.name}</TableCell>
                <TableCell align="right">{moment(row.startDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell align="right">{moment(row.endDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell align="right">
                  <Div
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 0.5,
                    }}>
                    <StyledLinearProgress variant={'determinate'} value={row.progress} color="secondary" />
                    <Typography variant={'body1'} ml={1}>{`${row.progress}%`}</Typography>
                  </Div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {dashboardData?.projectListForASE?.length === 0 ? <NoDataPlaceholder /> : ''}
      </JumboScrollbar>
    </JumboCardQuick>
  );
};

export default TasksList2;
