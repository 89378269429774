import axios from 'axios';

import { GET_TASK } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';

export const getTasks = ({ projectId, page, perPage, phaseId }) => {
  return (dispatch) => {
    return axios
      .get('/task/list', { params: { projectId, page, perPage, phaseId } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_TASK,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addTask = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('task', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editTask = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`task/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateTask = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`task/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deleteTask = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`task/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getGanttChart = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`task/chart/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
