import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Typography, Card, CardContent, Grid, useMediaQuery, Autocomplete, TextField, Box, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { getCompanyPA, getUser, getUserRegion } from 'app/redux/actions/User';
import Loader from '@jumbo/components/Loader';
import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import PAListMACard from '@jumbo/components/List/PAListMACard';
import RoleConstants from '@jumbo/constants/RoleConstants';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import { userType } from 'app/helpers/userType';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';
import { PACSvExport, PAsCsvHeaders } from 'app/helpers/csvExport';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const Users = () => {
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const mobileView = useMediaQuery('(max-width:575px)');
  const { enqueueSnackbar } = useSnackbar();
  const mutation = { isError: false };
  const users = useSelector((state) => state?.user);
  const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));
  const { isMA } = userType();
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useState(true);
  const [region, setRegion] = useState('');
  const [page, setPage] = useState(1);
  const [CsvData, setCsvData] = useState([]);
  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
  });
  const count = Math.ceil(users?.totalItems / perPage);
  const _DATA = usePagination(users?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const getPermission = () => {
    if (isMA) {
      setPermission({ createPermission: true, updatePermission: true, deletePermission: true });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
      };
      const UserPermission = permissionData?.filter((item) => {
        return item?.moduleName?.includes('User') && item;
      });
      UserPermission?.length &&
        UserPermission.forEach(function (num) {
          if (num?.name === 'create-user') {
            permissionValue.createPermission = true;
          }
          if (num?.name === 'delete-user') {
            permissionValue.deletePermission = true;
          }
          if (num?.name === 'update-user') {
            permissionValue.updatePermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobileNo: '',
      companyName: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let data = {
        name: values.name.trim(),
        email: values.email.trim(),
        mobileNumber: values.mobileNo.trim(),
        companyName: values.companyName.trim(),
        regionId: region,
        role: RoleConstants.PA,
        page: 1,
        perPage,
      };
      await dispatch(getUser(data));
      await dispatch(getCompanyPA(data));
      setPage(1);
      setSubmitting(false);
    },
  });
  let csvReport = {
    headers: PAsCsvHeaders,
    data: CsvData,
    filename: 'pa-list.csv',
  };
  const ExportComponent = () => (
    <CSVLink {...csvReport}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}>
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  useEffect(() => {
    getPermission();
  }, []);
  useEffect(() => {
    setCsvData(PACSvExport(users.paList));
  }, [users.paList]);
  useEffect(async () => {
    let value = { role: RoleConstants.PA, page, perPage };
    const response = await dispatch(getUser(value));
    await dispatch(getCompanyPA({ role: RoleConstants.PA }));
    await dispatch(getUserRegion());
    if (response.status !== 200) {
      enqueueSnackbar(response?.data?.responseMessage, {
        variant: 'error',
      });
    }
    setLoader(false);
  }, [deleted, dispatch, enqueueSnackbar, page]);
  const { handleSubmit, isSubmitting } = formik;

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              MANAGE APPLICATION TEAMS
            </Typography>
          )}
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography component={'h2'} variant="h1" mb={3}>
                  Search
                </Typography>
                <FormikProvider value={formik}>
                  <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={5}>
                        <JumboTextField size="small" fullWidth name="companyName" label="Company Name" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Autocomplete
                          id="region-select-demo"
                          size="small"
                          name="region"
                          options={users?.regionList}
                          autoHighlight
                          onChange={(e, values) => setRegion(values?._id)}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option) => option?._id === region}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              label="Region"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <JumboTextField size="small" fullWidth name="name" label="Name" />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <JumboTextField size="small" fullWidth name="email" label="Email" />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <JumboTextField size="small" fullWidth name="mobileNo" label="Mobile No." />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx={{ mb: 3 }}
                          loading={isSubmitting || mutation.isLoading}>
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Div>
          </Card>
          <CardComponent title={`List of Application Teams`} addUrl={'/add-supervisor'} Component={ExportComponent}>
            {users?.list?.map((data) => (
              <PAListMACard key={data._id} item={data} setDeleted={setDeleted} deleted={deleted} permission={permission} />
            ))}
            {users?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default Users;
