import { GET_PERMISSION, GET_PERMISSION_LIST, GET_ROLE } from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  permissions: [],
  permissionsData: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLE: {
      return {
        ...state,
        list: action?.payload?.roleList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_PERMISSION: {
      return {
        ...state,
        permissions: action?.payload?.rolePermissions,
      };
    }
    case GET_PERMISSION_LIST: {
      return {
        ...state,
        permissionsData: action?.payload?.permissionList,
      };
    }
    default: {
      return state;
    }
  }
};
