import React from 'react';
import { Card, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Div from '@jumbo/shared/Div';
import JumboDdMenu from '../JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import moment from 'moment';
import ControlledAccordions from '../common/Accordion';

const ArchiveProjectListCard = ({ item }) => {
  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions expand={true} descriptionKey={'Description'} descriptionValue={item.description}>
        <Div
          sx={{
            width: { xs: 'auto', lg: '16%' },
            flexShrink: 0,
            px: 1,
            flex: { xs: '1', lg: '0 1 auto' },
          }}
        >
          <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
            ID
          </Typography>
          <Typography variant={'h5'} fontSize={14}>
            {item.uniqId ?? '-'}
          </Typography>
        </Div>
        <Div
          sx={{
            width: { xs: 'auto', lg: '30%' },
            flexShrink: 0,
            px: 1,
            flex: { xs: '1', lg: '0 1 auto' },
          }}
        >
          <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
            <CalendarTodayOutlinedIcon
              sx={{
                verticalAlign: 'middle',
                fontSize: '1rem',
                mt: -0.25,
                mr: 1,
              }}
            />
            {moment(item.startDate).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant={'h5'} fontSize={14}>
            {item.name}
          </Typography>
        </Div>
        <Div
          sx={{
            width: { xs: 'auto', lg: '20%' },
            flexShrink: 0,
            px: 1,
            flex: { xs: '1', lg: '0 1 auto' },
          }}
        >
          <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
            Location
          </Typography>
          <Typography variant={'h5'} fontSize={14}>
            {item.city ?? '-'}
          </Typography>
        </Div>
        <Div
          sx={{
            width: { xs: 'auto', lg: '20%' },
            flexShrink: 0,
            px: 1,
            flex: { xs: '1', lg: '0 1 auto' },
          }}
        >
          <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
            End Date
          </Typography>
          <Typography variant={'h5'} fontSize={14}>
            {moment(item.endDate).format('DD/MM/YYYY')}
          </Typography>
        </Div>
        <Div sx={{ width: '5%', flexShrink: 0, px: 1 }}>
          <JumboDdMenu
            icon={<MoreHorizIcon />}
            menuItems={[{ icon: <RemoveRedEyeIcon />, title: 'View', action: 'view' }]}
          />
        </Div>
      </ControlledAccordions>
    </Card>
  );
};
export default ArchiveProjectListCard;
