import axios from 'axios';

import { GET_CLIENT } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';

export const getClients = ({ page, search, type }) => {
  return (dispatch) => {
    return axios
      .get('/client/list', { params: { page: page, search: search, type: type } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_CLIENT,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addClient = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('client', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editClient = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`client/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateClient = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`client/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deleteClient = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`client/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
