import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { alpha, Card, CardContent, Typography } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import './auth.css';
import { getAssetPath } from 'app/utils/appHelpers';
import { LoadingButton } from '@mui/lab';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import { ASSET_IMAGES } from '../../utils/constants/paths';
import { forgotPassword } from 'app/redux/actions/Auth';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address in the format example@domain.com')
    .required('The Email field is required'),
});
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const onForgotPassword = (email) => {
    dispatch(forgotPassword({ email })).then((response) => {
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/login');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };
  const mutation = { isError: false };
  return (
    <Div className="login-wrapper" sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: 4 }}>
      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}>
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            background: `#a2a6a9 url(${getAssetPath(`${ASSET_IMAGES}/Pidilite-Disha-Login.jpg`)}) no-repeat center`,
            backgroundSize: 'cover',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: alpha('#000000', 0.65),
            },
          }}>
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
              minHeight: { md: 250 },
            }}>
            <Div sx={{ mb: 2 }}>
              <Typography variant={'h3'} color={'inherit'} fontWeight={500} mb={3}>
                Forgot password
              </Typography>
              <Typography variant={'body1'} mb={1} sx={{ maxWidth: 270 }}>
                Enter your email id to receive an email with the link to proceed with resetting your password.
              </Typography>
            </Div>
            <Div sx={{ mt: 'auto' }}>
              <img src={`${ASSET_IMAGES}/disha_logo.png`} style={{ width: '120px' }} alt="Pidilite" />
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              height: '100%',
            }}>
            <Formik
              validateOnChange={true}
              initialValues={{
                email: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                onForgotPassword(data.email);
                setSubmitting(false);
              }}>
              {({ isSubmitting }) => (
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Div sx={{ mb: 2 }}>
                    <Div sx={{ mt: 1, mb: 3 }}>
                      <JumboTextField fullWidth name="email" label="Enter Registered Email Address*" />
                    </Div>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Send
                    </LoadingButton>
                  </Div>
                </Form>
              )}
            </Formik>
            <Typography variant={'body1'} mt={'auto'}>
              <NavLink style={{ textDecoration: 'none' }} to="/login">
                Down back to login?
              </NavLink>
            </Typography>
          </Div>
        </CardContent>
      </Card>
    </Div>
  );
};

export default ForgotPassword;
