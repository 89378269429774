import React from 'react';
import { Card, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import ControlledAccordions from '../common/Accordion';

const AseListCard = ({ item }) => {
  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions>
        <div className="wrap">
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Name
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.name}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Designation
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.designation}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Mobile
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.mobileNumber}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Email
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.email}
            </Typography>
          </Div>
        </div>
      </ControlledAccordions>
    </Card>
  );
};
export default AseListCard;
