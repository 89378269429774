import React from 'react';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const aseMenus = [
  {
    label: ' ',
    type: 'section',
    children: [
      {
        uri: '/',
        label: 'Dashboard',
        type: 'nav-item',
        icon: <HomeOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Projects',
        type: 'collapsible',
        icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/project',
            label: 'Project Details',
            type: 'nav-item',
          },
          {
            uri: '/phases',
            label: 'Phases',
            type: 'nav-item',
          },
          {
            uri: '/work-schedule',
            label: 'Work Schedule',
            type: 'nav-item',
          },
        ],
      },
      {
        label: 'Reports',
        type: 'collapsible',
        icon: <AssessmentOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/reports',
            label: 'View Report',
            type: 'nav-item',
          },
          {
            uri: '/add-report',
            label: 'Add Report',
            type: 'nav-item',
          },
        ],
      },
      {
        uri: '/discussion',
        label: 'Discussion Forum',
        type: 'nav-item',
        icon: <EmojiObjectsOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/feedback',
        label: 'Feedback',
        type: 'nav-item',
        icon: <ChatOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Notification',
        type: 'collapsible',
        icon: <NotificationsNoneOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/notification',
            label: 'List All',
            type: 'nav-item',
          },
          {
            uri: '/send-notification',
            label: 'Send Notification',
            type: 'nav-item',
          },
        ],
      },
    ],
  },
];

export default aseMenus;
