import { GET_PROJRCT, GET_SELECT_PROJRCT, GET_PROJRCT_CSV, GET_PROJRCT_NAME } from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  selectList: [],
  csvList: [],
  projectName: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJRCT: {
      return {
        ...state,
        list: action?.payload?.projectList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_SELECT_PROJRCT: {
      return {
        ...state,
        selectList: action?.payload?.projectList,
      };
    }
    case GET_PROJRCT_CSV: {
      return {
        ...state,
        csvList: action?.payload?.projectList,
      };
    }
    case GET_PROJRCT_NAME: {
      return {
        ...state,
        projectName: action?.payload?.name,
      };
    }
    default: {
      return state;
    }
  }
};
