import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Card, CardContent, Dialog, Grid, LinearProgress, TextField, Tooltip, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import JumboDdMenu from '../JumboDdMenu/JumboDdMenu';
import JumboConfirmDialog from '../JumboConfirmDialog/JumboConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { deleteTask, editTask, updateTask } from 'app/redux/actions/WorkSchedule';
import JumboTextField from '../JumboFormik/JumboTextField';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import JumboSelectField from '../JumboFormik/JumboSelectField';
import ControlledAccordions from '../common/Accordion';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'name can set of between 2 and 100 characters!')
    .max(100, 'name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  description: yup
    .string('Enter your description')
    .min(5, 'Description can set of between 5 and 255 characters!')
    .max(255, 'Description can set of between 5 and 255 characters!')
    .required('The Description field is required'),
  areaCompleted: yup
    .number()
    .required('The AreaCompleted field is required')
    .min(0, 'Please enter number value greater than -1'),
  areaCovered: yup.number().required('The Total Area field is required').min(1, 'Please enter number value greater than 0'),
});

const WorkScheduleListCard = ({ item, setDeleted, deleted, taskId }) => {
  const dispatch = useDispatch();
  const phases = useSelector((state) => state?.phase);
  const { enqueueSnackbar } = useSnackbar();
  const [deletePopup, setDeletePopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [phaseId, setPhaseId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDateEdit, setEndDateEdit] = useState(false);
  const [areaToBeCompletedIsLocked, setAreaToBeCompletedIsLocked] = useState(false);
  const mutation = { isError: false };

  const errorMsg = (error) => {
    enqueueSnackbar(error, { variant: 'error' });
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      areaCovered: '',
      areaCompleted: '',
      comment: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      if (phaseId === '') {
        errorMsg('The phaseId field is required');
        return;
      } else if (!startDate) {
        errorMsg('The Start Date field is required');
        return;
      } else if (!endDate) {
        errorMsg('The End Date field is required');
        return;
      }
      if (endDateEdit && values.comment === '') {
        errorMsg('The Comment field is required');
        return;
      }
      setSubmitting(true);
      let newProjectData = {
        name: values.name.trim(),
        description: values.description.trim(),
        areaToBeCovered: +values.areaCovered,
        areaToBeCompleted: +values.areaCompleted,
        comment: values.comment === '' ? null : values.comment,
        phaseId,
        startDate,
        endDate,
      };

      const response = await dispatch(updateTask(item._id, newProjectData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        setOpen(false);
        setEndDateEdit(false);
        resetForm();
        setPhaseId('');
        setStartDate(null);
        setEndDate(null);
        setDeleted(!deleted);
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });

  const handleConfirmDelete = () => {
    dispatch(deleteTask([item?._id])).then((res) => {
      if (res?.status === 200) {
        enqueueSnackbar(res.data?.responseMessage, {
          variant: 'success',
        });
        setDeleted(!deleted);
      } else {
        enqueueSnackbar(res.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };
  const handleEditTask = async (id) => {
    await dispatch(editTask(id)).then(async (response) => {
      if (response.status === 200) {
        const taskDetail = response?.data?.data?.taskDetail;
        setFieldValue('name', taskDetail.name);
        setFieldValue('description', taskDetail.description);
        setFieldValue('areaCovered', taskDetail.areaToBeCovered);
        setFieldValue('areaCompleted', taskDetail.areaToBeCompleted);
        setEndDate(taskDetail?.endDate);
        setStartDate(taskDetail?.startDate);
        setPhaseId(taskDetail?.phaseId);
        setAreaToBeCompletedIsLocked(taskDetail?.areaToBeCompletedIsLocked);
        setOpen(true);
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };

  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'edit':
        handleEditTask(item._id);
        break;
      case 'delete':
        setDeletePopup(true);
        break;
    }
  };
  useEffect(() => {
    if (taskId === item._id) {
      handleEditTask(taskId);
    }
  }, [taskId]);

  const { setFieldValue, handleSubmit, isSubmitting } = formik;

  return (
    <>
      <Card sx={{ mb: 1, boxShadow: 'none' }}>
        <ControlledAccordions
          expand={true}
          descriptionKey={'Description'}
          descriptionValue={item.description}
          comment={item.comment}>
          <div className="wrap">
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '21%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Phase Name
              </Typography>
              <Typography variant={'h5'} className="pl-sm-5" fontSize={14}>
                {item?.phase?.name}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '33%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Task Name
              </Typography>
              <Typography variant={'h5'} className="pl-sm-5" fontSize={14}>
                {item?.name}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '18%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Start Date
              </Typography>
              <Typography variant={'h5'} fontSize={14} className="pl-sm-5"> 
                <CalendarTodayOutlinedIcon
                  sx={{
                    verticalAlign: 'middle',
                    fontSize: '1rem',
                    mt: -0.25,
                    mr: 1,
                  }}
                />
                {moment(item.startDate).format('DD/MM/YYYY')}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '16%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Total Area (sqmt)
              </Typography>
              <Typography variant={'h5'} fontSize={14} className="pl-sm-5">
                {item?.areaToBeCovered}
              </Typography>
            </Div>
            <Div className="plus-icon-15 linear-progress"  sx={{ width: '15%', flexShrink: 0, px: 2 }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Area Completed
              </Typography>
              <Tooltip title={`${item.progress}%`} placement="top-start" arrow>
                <LinearProgress 
                  className="w-100 pl-sm-5"
                  variant={'determinate'}
                  color="secondary"
                  value={item.progress}
                  sx={{
                    height: 6,
                    borderRadius: 2,
                  }}
                />
              </Tooltip>
            </Div>
            <Div className="plus-icon-15" sx={{ width: '12%', flexShrink: 0, px: 1 }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                End Date
              </Typography>
              <Typography variant={'h5'} fontSize={14} className="pl-sm-5">
                {moment(item.endDate).format('DD/MM/YYYY')}
              </Typography>
            </Div>
            <Div sx={{ width: '5%', flexShrink: 0, px: 1 }}>
              <JumboDdMenu
                icon={<MoreHorizIcon />}
                menuItems={[
                  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
                  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
                ]}
                onClickCallback={handleItemAction}
              />
            </Div>
          </div>
        </ControlledAccordions>
        <JumboConfirmDialog
          open={deletePopup}
          title={`Confirm delete `}
          message={`Are you sure, you want to  delete this task?`}
          onClose={() => setDeletePopup(false)}
          onConfirm={handleConfirmDelete}
        />
      </Card>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={() => {
          setOpen(false);
          setEndDateEdit(false);
        }}>
        <Card sx={{ display: 'flex' }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                Update Task
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <JumboSelectField
                        size="small"
                        fullWidth
                        label={'Select Phase*'}
                        valueKey="_id"
                        name={'phase'}
                        variant="outlined"
                        labelKey={'name'}
                        data={phases.list}
                        value={phaseId}
                        onChange={(e) => setPhaseId(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField size="small" fullWidth name="name" label="Task Name*" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField size="small" fullWidth name="areaCovered" label="Total Area (Sqmt)" disabled />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="Start Date*"
                          value={startDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="End Date*"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setEndDate(newValue);
                            setEndDateEdit(true);
                          }}
                          renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField
                        size="small"
                        fullWidth
                        name="areaCompleted"
                        label="Area Completed (Sqmt)*"
                        disabled={areaToBeCompletedIsLocked}
                      />
                    </Grid>
                    {endDateEdit && (
                      <Grid item xs={12}>
                        <JumboTextField fullWidth name="comment" label="Reason for date change*" multiline rows={2} maxRows={4} />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <JumboTextField fullWidth name="description" label="Description*" multiline rows={2} maxRows={4} />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting || mutation.isLoading}>
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        color="inherit"
                        variant="contained"
                        sx={{ mb: 3 }}
                        onClick={() => setOpen(false)}>
                        Cancel
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      </Dialog>
    </>
  );
};
export default WorkScheduleListCard;
