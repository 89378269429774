import React from "react";
import PropTypes from "prop-types";

import { Autocomplete, TextField } from "@mui/material";

const JumboAutoComplete = ({
  value,
  label,
  options,
  onChange,
  isMulti,
  error,
  helperText,
  required,
  fullWidth,
  size,
  getOptionLabel,
  variant,
  name,
  ...rest
}) => {
  return (
    <Autocomplete
      {...rest}
      multiple={isMulti}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={onChange}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          variant={variant}
          label={label}
          fullWidth={fullWidth}
          error={error || helperText !== ""}
          helperText={helperText}
          required={required}
          size={size}
        />
      )}
    />
  );
};

JumboAutoComplete.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  size: PropTypes.string,
  getOptionLabel: PropTypes.func,
  variant: PropTypes.string,
  name:PropTypes.string
};

JumboAutoComplete.defaultProps = {
  options: [],
  fullWidth: true,
  required: false,
  size: "small",
  variant: "outlined",
  error: false,
  helperText: "",
  onChange: () => {},
  getOptionLabel: () => {},
};

export default JumboAutoComplete;
