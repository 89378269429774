import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Radio,
} from '@mui/material';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { userType } from 'app/helpers/userType';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getSelectProjects } from 'app/redux/actions/Project';
import { useTheme } from '@emotion/react';
import { getUser } from 'app/redux/actions/User';
import { sendNotification } from 'app/redux/actions/Notification';

const validationSchema = yup.object({
  title: yup
    .string('Enter your title')
    .min(4, 'Title can set of between 4 and 255 characters!')
    .max(255, 'Title can set of between 4 and 255 characters!')
    .required('The Title field is required'),
  message: yup
    .string('Enter your message')
    .min(4, 'Message can set of between 4 and 255 characters!')
    .max(255, 'Message can set of between 4 and 255 characters!')
    .required('The Message field is required'),
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
const SendNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const projects = useSelector((state) => state?.project);
  const [projectId, setProjectId] = useState('');
  const [client, setClient] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [pa, setPa] = useState([]);
  const [paData, setPaData] = useState([]);
  const [ase, setAse] = useState([]);
  const [aseData, setAseData] = useState([]);
  const [type, setType] = useState('selected');
  const { isPA } = userType();
  const mutation = { isError: false };
  const formik = useFormik({
    initialValues: {
      message: '',
      title: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const userIds = [...client, ...pa, ...ase];

      if (!isPA && projectId === '') {
        enqueueSnackbar('The ProjectId field is required', { variant: 'error' });
        return;
      }
      if (userIds.length === 0) {
        enqueueSnackbar('Please select stakeholders.', { variant: 'error' });
        return;
      }

      setSubmitting(true);
      const newNotificationData = {
        message: values.message.trim(),
        title: values.title.trim(),
        userIds,
        projectId: localStorage.getItem('projectId') ? localStorage.getItem('projectId') : projectId,
      };
      const response = await dispatch(sendNotification(newNotificationData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  const handleChangeClient = (event) => {
    const {
      target: { value },
    } = event;
    setClient(typeof value === 'string' ? value.split(',') : value);
    setType('selected');
  };
  const handleChangeAse = (event) => {
    const {
      target: { value },
    } = event;
    setAse(typeof value === 'string' ? value.split(',') : value);
    setType('selected');
  };
  const handleChangePa = (event) => {
    const {
      target: { value },
    } = event;
    setPa(typeof value === 'string' ? value.split(',') : value);
    setType('selected');
  };
  const projectSelect = async (value) => {
    setProjectId(value);
    await getUsers(value);
  };
  const getUsers = async (value) => {
    const response = await dispatch(getUser({ projectId: value }));
    if (response.status === 200) {
      const userData = response.data.data;
      setAseData(userData?.aseList);
      setPaData(userData?.supervisorList);
      setClientData(userData?.clientList);
      setAse([]);
      setPa([]);
      setClient([]);
      setType('selected');
    }
  };
  useEffect(async () => {
    if (isPA && localStorage.getItem('projectId')) {
      await getUsers(localStorage.getItem('projectId'));
    } else {
      await dispatch(getSelectProjects({}));
    }
  }, [dispatch]);
  function getStyles(name, assignASE, theme) {
    return {
      fontWeight: assignASE.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }
  const { handleSubmit, isSubmitting } = formik;
  return (
    <Card sx={{ display: 'flex', mb: 3.5 }}>
      <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
        <CardContent>
          <Typography component={'h2'} variant="h1" mb={3}>
            Send Notification
          </Typography>
          <FormikProvider value={formik}>
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
              {mutation.isError && <p>{mutation.error.message}</p>}
              <Grid container spacing={3}>
                {!isPA && (
                  <Grid item xs={12} sm={6}>
                    <JumboSelectField
                      fullWidth
                      label={'Select ProjectId*'}
                      valueKey="_id"
                      name={'project'}
                      variant="outlined"
                      labelKey={'uniqId'}
                      data={projects?.selectList}
                      value={projectId}
                      onChange={(event) => {
                        projectSelect(event.target.value);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Radio
                    checked={type === 'selected'}
                    onChange={(event) => {
                      setType(event.target.value);
                    }}
                    value="selected"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  Selected
                  <Radio
                    checked={type === 'all'}
                    onChange={(event) => {
                      setType(event.target.value);
                      setAse(aseData.map((item) => item._id));
                      setPa(paData.map((item) => item._id));
                      setClient(clientData.map((item) => item._id));
                    }}
                    value="all"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  All
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-chip-label">Pidilite Teams</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={ase}
                      onChange={handleChangeAse}
                      input={<OutlinedInput id="select-multiple-chip" label="Pidilite Teams" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => {
                            const name = aseData.filter((val) => val?._id === value);
                            return <Chip key={name[0]?.id} label={name[0]?.name} />;
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}>
                      {aseData.map((val) => (
                        <MenuItem key={val._id} value={val._id} style={getStyles(val.name, ase, theme)}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-chip-label">Application Teams</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={pa}
                      onChange={handleChangePa}
                      input={<OutlinedInput id="select-multiple-chip" label="Application Teams" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => {
                            const name = paData.filter((val) => val?._id === value);
                            return <Chip key={name[0]?.id} label={name[0]?.name} />;
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}>
                      {paData.map((val) => (
                        <MenuItem key={val._id} value={val._id} style={getStyles(val.name, pa, theme)}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-chip-label">Customer Teams</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={client}
                      onChange={handleChangeClient}
                      input={<OutlinedInput id="select-multiple-chip" label="Customer Teams" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => {
                            const name = clientData.filter((val) => val?._id === value);
                            return <Chip key={name[0]?.id} label={name[0]?.name} />;
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}>
                      {clientData.map((val) => (
                        <MenuItem key={val._id} value={val._id} style={getStyles(val.name, client, theme)}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <JumboTextField fullWidth name="title" label="Notification Title*" />
                </Grid>
                <Grid item xs={12}>
                  <JumboTextField fullWidth name="message" label="Message*" multiline rows={2} maxRows={4} />
                </Grid>
              </Grid>
              <br></br>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isSubmitting || mutation.isLoading}>
                    Save
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="inherit"
                    size="large"
                    sx={{ mb: 3 }}
                    onClick={() => navigate('/')}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </Div>
    </Card>
  );
};

export default SendNotification;
