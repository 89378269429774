import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, LinearProgress, Tooltip, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Div from '@jumbo/shared/Div';
import JumboDdMenu from '../JumboDdMenu/JumboDdMenu';
import JumboConfirmDialog from '../JumboConfirmDialog/JumboConfirmDialog';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { deletePhase } from 'app/redux/actions/Phase';
import ControlledAccordions from '../common/Accordion';
import moment from 'moment';

const PhaseListCard = ({ item, setDeleted, deleted }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePopup, setDeletePopup] = useState(false);

  const handleConfirmDelete = () => {
    dispatch(deletePhase([item?._id])).then((res) => {
      if (res?.status === 200) {
        enqueueSnackbar(res.data?.responseMessage, {
          variant: 'success',
        });
        setDeleted(!deleted);
      } else {
        enqueueSnackbar(res.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };
  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'edit':
        navigate(`/edit-phase/${item?._id}`);
        break;
      case 'delete':
        setDeletePopup(true);
        break;
    }
  };
  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions
        expand={true}
        descriptionKey={'Description'}
        descriptionValue={item.description}
        comment={item.comment}
      >
        <div className="wrap">
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '30%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Phase Name
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.name}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '30%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
              Area (Sqmt)
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.areaToBeCovered}
            </Typography>
            <Typography variant={'h5'} mb={0.5} fontSize={14}></Typography>
            <Typography fontSize={'12px'} variant={'body1'}></Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '30%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Start Date
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item.startDate).format('DD/MM/YYYY')}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '30%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              End Date
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item.endDate).format('DD/MM/YYYY')}
            </Typography>
          </Div>
          <Div className="plus-icon-15 " sx={{ width: '20%', flexShrink: 0, px: 2 }}>
            <Tooltip title={`${item.progress ?? 0}%`} placement="top-start" arrow>
              <LinearProgress
                className="w-100"
                variant={'determinate'}
                color="secondary"
                value={item.progress ?? 0}
                sx={{
                  height: 6,
                  borderRadius: 2,
                }}
              />
            </Tooltip>
          </Div>
          <Div className="plus-icon-15" sx={{ width: '5%', flexShrink: 0, px: 1 }}>
            <JumboDdMenu
              icon={<MoreHorizIcon />}
              menuItems={[
                { icon: <EditIcon />, title: 'Edit', action: 'edit' },
                { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
              ]}
              onClickCallback={handleItemAction}
            />
          </Div>
        </div>
      </ControlledAccordions>
      <JumboConfirmDialog
        open={deletePopup}
        title={`Confirm delete `}
        message={`Are you sure, you want to  delete this phase?`}
        onClose={() => setDeletePopup(false)}
        onConfirm={handleConfirmDelete}
      />
    </Card>
  );
};
export default PhaseListCard;
