import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardContent, LinearProgress } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from '@jumbo/components/Loader';
import moment from 'moment';
import styled from '@mui/material/styles/styled';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import CardIconText from '@jumbo/shared/CardIconText/CardIconText';
import { getPAProjectDetails } from 'app/redux/actions/Dashboard';
import Div from '@jumbo/shared/Div/Div';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  flex: 1,
}));
const Dashboard = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [projectDetail, setProjectDetail] = useState();
  const color = ['secondary', 'info', 'error', 'primary', 'inherit', 'secondary', 'info', 'error', 'primary', 'inherit'];

  useEffect(async () => {
    await dispatch(getPAProjectDetails(localStorage.getItem('projectId'))).then(async (response) => {
      if (response.status === 200) {
        setProjectDetail(response?.data?.data?.dashboardData);
      }
    });
    setLoader(false);
  }, [dispatch]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              Dashboard
            </Typography>
          )}
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent className="px-xs-10">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Div
                      sx={{
                        width: { xs: 'auto' },
                        flexShrink: 0,
                        px: 1,
                        flex: { xs: '1', lg: '0 1 auto' },
                      }}>
                      <Typography fontSize={'15px'} mb={0.5} variant={'body1'}>
                        Project Name
                      </Typography>
                      <Typography variant={'h1'} fontSize={21}>
                        {projectDetail?.projectName}
                      </Typography>
                    </Div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Div
                      sx={{
                        width: { xs: 'auto' },
                        flexShrink: 0,
                        px: 1,
                        flex: { xs: '1', lg: '0 1 auto' },
                      }}>
                      <Typography fontSize={'15px'} mb={0.5} variant={'body1'}>
                        Project ID
                      </Typography>
                      <Typography variant={'h1'} fontSize={21}>
                        {projectDetail?.projectId}
                      </Typography>
                    </Div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Div
                      sx={{
                        width: { xs: 'auto' },
                        flexShrink: 0,
                        px: 1,
                        flex: { xs: '1', lg: '0 1 auto' },
                      }}>
                      <Typography fontSize={'15px'} mb={0.5} variant={'body1'}>
                        Location
                      </Typography>
                      <Typography variant={'h1'} fontSize={21}>
                        {projectDetail?.location}
                      </Typography>
                    </Div>
                  </Grid>
                </Grid>
              </CardContent>
            </Div>
          </Card>
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent className="px-xs-15">
                <Grid container spacing={3} className="w-100">
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant={'h2'} mb={3}>
                      Project Progress
                    </Typography>
                    {projectDetail?.phase &&
                      projectDetail?.phase.map((val, i) => (
                        <Div
                          sx={{
                            width: { xs: 'auto' },
                            flexShrink: 0,
                            px: 1,
                            flex: { xs: '1', lg: '0 1 auto' },
                          }}>
                          <Typography variant={'h3'} mb={0.5} fontSize={16}>
                            {val.name}
                          </Typography>
                          <Typography fontSize={'15px'} variant={'body1'}>
                            <Div
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 0.5,
                              }}>
                              <StyledLinearProgress variant={'determinate'} color={color[i]} value={val.progress} />
                              <Typography variant={'body1'} color={'text.secondary'} ml={1}>{`${val.progress}%`}</Typography>
                            </Div>
                          </Typography>
                        </Div>
                      ))}
                    {projectDetail?.phase?.length === 0 ? <NoDataPlaceholder /> : ''}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Div sx={{ mt: 'auto', textAlign: 'center' }}>
                      <img src={`${ASSET_IMAGES}/dashboradImg.png`} style={{ width: '250px' }} alt="Pidilite" />
                    </Div>
                  </Grid>
                  <Grid
                    className="border-left-lg-none"
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      minHeight: 'auto',
                      paddingLeft: '0',
                      paddingTop: '0',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                    <Div
                      className="border-bottom-lg-none"
                      sx={{
                        width: '100%',
                        height: '50%',
                        flexShrink: 0,
                        px: 1,
                        flex: { xs: '1', lg: '0 1 auto' },
                        textAlign: 'center',
                        position: 'relative',
                      }}>
                      <div className="content-center">
                        <Typography variant={'h3'} mb={0.5} fontSize={18}>
                          Project Completion Date
                        </Typography>
                        <Typography fontSize={'20px'} variant={'body1'}>
                          {moment(projectDetail?.projectCompletionDate).format('DD/MM/YYYY')}
                        </Typography>
                      </div>
                    </Div>
                    <Div
                      sx={{
                        width: '100%',
                        height: '50%',
                        flexShrink: 0,
                        px: 1,
                        flex: { xs: '1', lg: '0 1 auto' },
                        textAlign: 'center',
                        position: 'relative',
                      }}>
                      <div className="content-center">
                        <Typography variant={'h3'} mb={0.5} fontSize={18}>
                          Days To go
                        </Typography>
                        <Typography fontSize={'20px'} variant={'body1'}>
                          {projectDetail?.remainingDays}
                        </Typography>
                      </div>
                    </Div>
                  </Grid>
                </Grid>
              </CardContent>
            </Div>
          </Card>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>
              <Card sx={{ display: 'flex', mb: 3.5 }}>
                <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                  <CardContent className="px-xs-15">
                    <Typography variant={'h2'} mb={2}>
                      Latest Reports
                    </Typography>
                    {projectDetail?.latestReport &&
                      projectDetail?.latestReport.map((val) => (
                        <Div className="report-icon-1" sx={{ display: 'flex' }}>
                          <Div
                            className="report-icon"
                            sx={{
                              width: '32px',
                              height: '32px',
                              border: val.attachment ? '1px solid #979494' : '1px solid #fff',
                              borderRadius: '50%',
                              cursor: 'pointer',
                            }}>
                            {val.attachment && (
                              <Div
                                sx={{ marginTop: '4px', textAlign: 'center' }}
                                onClick={() => window.open(val.attachment)}>
                                <img
                                  src={`${ASSET_IMAGES}/${
                                    val?.attachmentExt === 'pdf'
                                      ? 'pdfImg.png'
                                      : val?.attachmentExt === 'doc' || val?.attachmentExt === 'docx'
                                      ? 'doc.png'
                                      : val?.attachmentExt === 'xls' ||
                                        val?.attachmentExt === 'xlsx' ||
                                        val?.attachmentExt === 'csv'
                                      ? 'xls.png'
                                      : 'no-file.png'
                                  }`}
                                  style={{ width: '20px' }}
                                  alt="PDF"
                                />
                              </Div>
                            )}
                          </Div>
                          <Div
                            sx={{
                              width: { xs: 'auto' },
                              flexShrink: 0,
                              px: 1,
                              flex: { xs: '1', lg: '0 1 auto' },
                              mb: 1.5,
                            }}>
                            <Typography variant={'h4'} mb={0.5} fontSize={14}>
                              {val.name}
                            </Typography>
                            <Typography fontSize={'12px'} color="#979494" variant={'body1'}>
                              {moment(val.createdAt).format('DD/MM/YYYY')}
                            </Typography>
                          </Div>
                        </Div>
                      ))}
                    {projectDetail?.latestReport?.length === 0 ? <NoDataPlaceholder /> : ''}
                  </CardContent>
                </Div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Card sx={{ display: 'flex', mb: 3.5 }}>
                <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                  <CardContent className="px-xs-15">
                    <Typography variant={'h2'} mb={2}>
                      Quick Navigation
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <CardIconText
                          icon={<img src={`${ASSET_IMAGES}/dashboardCal.png`} style={{ width: '40px' }} alt="Pidilite" />}
                          title={
                            <Typography variant={'h4'} color={'common.white'}>
                              Update
                            </Typography>
                          }
                          subTitle={
                            <Typography variant={'h6'} color={'common.white'}>
                              Work Schedule
                            </Typography>
                          }
                          color="#D4A311"
                          disableHoverEffect={true}
                          hideArrow={true}
                          variant={'outlined'}
                          sx={{ background: '#D4A311', cursor: 'pointer' }}
                          onClick={() => navigate('/work-schedule')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CardIconText
                          icon={<img src={`${ASSET_IMAGES}/dashboardReport.png`} style={{ width: '40px' }} alt="Pidilite" />}
                          title={
                            <Typography variant={'h4'} color={'common.white'}>
                              Update
                            </Typography>
                          }
                          subTitle={
                            <Typography variant={'h6'} color={'common.white'}>
                              Report
                            </Typography>
                          }
                          color="#0493D1"
                          disableHoverEffect={true}
                          hideArrow={true}
                          variant={'outlined'}
                          sx={{ background: '#0493D1', cursor: 'pointer' }}
                          onClick={() => navigate('/reports')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CardIconText
                          icon={
                            <img src={`${ASSET_IMAGES}/dashboardDiscussion.png`} style={{ width: '40px' }} alt="Pidilite" />
                          }
                          title={
                            <Typography variant={'h4'} color={'common.white'}>
                              View
                            </Typography>
                          }
                          subTitle={
                            <Typography variant={'h6'} color={'common.white'}>
                              Discussions
                            </Typography>
                          }
                          color="#FF0C0C"
                          disableHoverEffect={true}
                          hideArrow={true}
                          variant={'outlined'}
                          sx={{ background: '#FF0C0C', cursor: 'pointer' }}
                          onClick={() => navigate('/discussion')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CardIconText
                          icon={
                            <img
                              src={`${ASSET_IMAGES}/dashboardNotification.png`}
                              style={{ width: '40px' }}
                              alt="Pidilite"
                            />
                          }
                          title={
                            <Typography variant={'h4'} color={'common.white'}>
                              View
                            </Typography>
                          }
                          subTitle={
                            <Typography variant={'h6'} color={'common.white'}>
                              Notifications
                            </Typography>
                          }
                          color="#223F9A"
                          disableHoverEffect={true}
                          hideArrow={true}
                          variant={'outlined'}
                          sx={{ background: '#223F9A', cursor: 'pointer' }}
                          onClick={() => navigate('/notification')}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Div>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Dashboard;
