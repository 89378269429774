import React from 'react';
import { Card, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Div from '@jumbo/shared/Div';
import moment from 'moment';
import ControlledAccordions from '../common/Accordion';

const DeleteProjectListCard = ({ item }) => {
  return (
    <>
      <Card sx={{ mb: 1, boxShadow: 'none' }}>
        <ControlledAccordions expand={true} descriptionKey={'Description'} descriptionValue={item.description}>
          <Div
            sx={{
              width: { xs: 'auto', lg: '10%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              ID
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.uniqId ?? '-'}
            </Typography>
          </Div>
          <Div
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              <CalendarTodayOutlinedIcon
                sx={{
                  verticalAlign: 'middle',
                  fontSize: '1rem',
                  mt: -0.25,
                  mr: 1,
                }}
              />
              {moment(item.startDate).format('DD/MM/YYYY')}
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.name}
            </Typography>
          </Div>
          <Div
            sx={{
              width: { xs: 'auto', lg: '16%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              Location
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.city ?? '-'}
            </Typography>
          </Div>

          <Div
            sx={{
              width: { xs: 'auto', lg: '16%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              Deleted On
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item.updatedAt).format('DD/MM/YYYY')}
            </Typography>
          </Div>
          <Div
            sx={{
              width: { xs: 'auto', lg: '16%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              Deleted By
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.deletedBy?.name}
            </Typography>
          </Div>
          <Div
            sx={{
              width: { xs: 'auto', lg: '30%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              Reason
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.notes ?? '-'}
            </Typography>
          </Div>
        </ControlledAccordions>
      </Card>
    </>
  );
};
export default DeleteProjectListCard;
