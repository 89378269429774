import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import '../../routes/Auth/auth.css';
import { AuhMethods } from 'app/services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import Div from '@jumbo/shared/Div/Div';

const pages = [];

const SupervisorLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const result = JSON.parse(localStorage.getItem('User'));

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const onLogout = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout()).then((response) => {
      if (response.status === 200) {
        navigate('/login');
      }
    });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar position="static" className="login-wrapper custom-header">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}>
              <Div sx={{ mt: 'auto' }}>
                <img src={`${ASSET_IMAGES}/disha_logo.png`} style={{ width: '110px' }} alt="Pidilite" />
              </Div>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              {/* <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit">
                <MenuIcon />
              </IconButton> */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}>
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { md: 'flex', justifyContent: 'center' } }}>
              <Typography variant="h1" component="h2" className='fs-sm-20'>
                {`Welcome, ${result?.user?.name} `}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Logout">
                <IconButton onClick={onLogout} sx={{ p: 0 }}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default SupervisorLayout;
