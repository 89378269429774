import React, { useState } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Card, CardContent, Dialog, Grid, LinearProgress, Tooltip, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Chip from '@mui/material/Chip';
import Div from '@jumbo/shared/Div';
import JumboDdMenu from '../JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import RestoreIcon from '@mui/icons-material/Restore';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import JumboConfirmDialog from '../JumboConfirmDialog/JumboConfirmDialog';
import { getProjects, updateProjectStatus } from 'app/redux/actions/Project';
import JumboTextField from '../JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import ControlledAccordions from '../common/Accordion';

const validationSchema = yup.object({
  reason: yup
    .string('Enter your Reason')
    .min(4, 'Reason can set of between 4 and 255 characters!')
    .max(255, 'Reason can set of between 4 and 255 characters!')
    .required('The Reason field is required'),
});

const ProjectListCard = ({ item, page, perPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePopup, setDeletePopup] = useState(false);
  const [open, setOpen] = useState(false);
  const menuItems =
    item.status === 0
      ? [
          { icon: <EditIcon />, title: 'Edit', action: 'edit' },
          { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
        ]
      : [
          { icon: <EditIcon />, title: 'Edit', action: 'edit' },
          { icon: <VisibilityIcon />, title: 'View', action: 'view' },
          { icon: <RestoreIcon />, title: 'Work Schedule', action: 'work-schedule' },
          { icon: <AssessmentIcon />, title: 'Report', action: 'report' },
          { icon: <EmojiObjectsIcon />, title: 'Discussion', action: 'discussion' },
          { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
        ];
  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      await updateStatus({ status: 7, notes: values.reason.trim() });
      setSubmitting(false);
    },
  });
  const updateStatus = async (data) => {
    const response = await dispatch(updateProjectStatus(item?._id, data));
    if (response.status === 200) {
      enqueueSnackbar(response.data?.responseMessage, {
        variant: 'success',
      });
      await dispatch(getProjects({ page, perPage }));
    } else {
      enqueueSnackbar(response.data?.responseMessage, {
        variant: 'error',
      });
    }
    setOpen(false);
  };
  const handleConfirmDelete = () => {
    setOpen(true);
  };
  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'edit':
        navigate(`/edit-project/${item?._id}`);
        break;
      case 'view':
        navigate(`/view-project/${item?._id}`);
        break;
      case 'work-schedule':
        navigate(`/work-schedule/${item?._id}`);
        break;
      case 'report':
        navigate(`/view-report/${item?._id}`);
        break;
      case 'delete':
        setDeletePopup(true);
        break;
      case 'discussion':
        navigate(`/view-thread/${item?._id}`);
        break;
    }
  };
  const { handleSubmit, isSubmitting } = formik;

  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions
        expand={true}
        descriptionKey={'Description'}
        descriptionValue={item.description}
        comment={item.comment}>
        <div className="wrap">
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '10%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              ID
            </Typography>
            <Typography variant={'h5'} fontSize={14} className="pl-sm-5">
              {item.uniqId ?? '-'}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '26%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              <CalendarTodayOutlinedIcon
                sx={{
                  verticalAlign: 'middle',
                  fontSize: '1rem',
                  mt: -0.25,
                  mr: 1,
                }}
              />
              {moment(item.startDate).format('DD/MM/YYYY')}
            </Typography>
            <Typography variant={'h5'} className="pl-sm-5" fontSize={14}>
              {item.name}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '10%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Location
            </Typography>
            <Typography variant={'h5'} fontSize={14} className="pl-sm-5">
              {item.city ?? '-'}
            </Typography>
          </Div>
          <Div className="plus-icon-15" sx={{ width: '20%', flexShrink: 0, px: 2 }}>
            <Tooltip title={`${item.progress ?? 0}%`} placement="top-start" arrow>
              <LinearProgress
                variant={'determinate'}
                color="secondary"
                value={item.progress ?? 0}
                sx={{
                  height: 6,
                  borderRadius: 2,
                }}
              />
            </Tooltip>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '16%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              End Date
            </Typography>
            <Typography variant={'h5'} fontSize={14} className="pl-sm-5">
              {moment(item.endDate).format('DD/MM/YYYY')}
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', lg: 1 }, flexShrink: 0, px: 1 }}>
            <Typography 
              fontSize={'12px'}
              variant={'h6'}
              mb={0.25}
              sx={{
                display: { xs: 'none', lg: 'block' },
              }}>
              Status
            </Typography>
            {item.status === 0 ? (
              <Chip style={{ backgroundColor: '#772f00', color: '#ffffff' }} size={'small'} label="Created" />
            ) : item.status === 1 ? (
              <Chip color="success" size={'small'} label="Approved" />
            ) : item.status === 2 ? (
              <Chip style={{ backgroundColor: '#f78005', color: '#ffffff' }} size={'small'} label="On Going" />
            ) : item.status === 3 ? (
              <Chip color="error" size={'small'} label="Rejected" />
            ) : item.status === 4 ? (
              <Chip color="primary" size={'small'} label="On Hold" />
            ) : item.status === 5 ? (
              <Chip style={{ backgroundColor: '#8100f2', color: '#ffffff' }} size={'small'} label="Near Completion" />
            ) : item.status === 6 ? (
              <Chip color="default" size={'small'} label="Archived" />
            ) : item.status === 7 ? (
              <Chip color="error" size={'small'} label="Deleted" />
            ) : (
              <Chip color="error" size={'small'} label="InActive" />
            )}
          </Div>
          <Div sx={{ width: '5%', flexShrink: 0, px: 1 }}>
            <JumboDdMenu icon={<MoreHorizIcon />} menuItems={menuItems} onClickCallback={handleItemAction} />
          </Div>
        </div>
      </ControlledAccordions>
      <JumboConfirmDialog
        open={deletePopup}
        title={`Confirm delete `}
        message={`Are you sure, you want to  delete this project?`}
        onClose={() => setDeletePopup(false)}
        onConfirm={handleConfirmDelete}
      />
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <Card sx={{ display: 'flex' }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                Reason For Deleting Project
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="reason" label="Reason" multiline rows={3} maxRows={4} />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting || mutation.isLoading}>
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      </Dialog>
    </Card>
  );
};
export default ProjectListCard;
