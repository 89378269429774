import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Typography,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  LinearProgress,
  Button,
  useMediaQuery,
  Radio,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import styled from '@mui/material/styles/styled';
import ProjectViewWorkListCard from '@jumbo/components/List/ProjectViewWorkListCard';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import TikitListCard from '@jumbo/components/List/TikitListCard';
import { editProject, getProjectTeam } from 'app/redux/actions/Project';
import { useParams } from 'react-router-dom';
import { getTicket } from 'app/redux/actions/Discussion';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import { getPhases } from 'app/redux/actions/Phase';
import { getExportReport, getReport } from 'app/redux/actions/Report';
import ReportListCard from '@jumbo/components/List/ReportListCard';
import { phaseCSvExport, phasesCsvHeaders, reportsCsvHeaders, reportCSvExport } from 'app/helpers/csvExport';
import { CSVLink } from 'react-csv';
import CardComponent from '@jumbo/components/common/Card';
import { getProjectTopic } from 'app/redux/actions/Topic';
import AseListCard from '@jumbo/components/List/AseListCard';
import PartnerListCard from '@jumbo/components/List/PartnerListCard';
import ClientListCard from '@jumbo/components/List/ClientListCard';
import moment from 'moment';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  flex: 1,
}));

const ViewProject = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const topics = useSelector((state) => state?.topic);
  const discussionTopics = useSelector((state) => state?.discussion);
  const phases = useSelector((state) => state?.phase);
  const reports = useSelector((state) => state?.report);
  const [phase, setPhase] = useState('');
  const [projectDetail, setProjectDetail] = useState();
  const [ProjectTeam, setProjectTeam] = useState();
  const [viewTopic, setViewTopic] = useState('');
  const [CsvPhaseData, setCsvPhaseData] = useState([]);
  const [CsvReportData, setCsvReportData] = useState([]);
  const [type, setType] = useState();
  const [view, setView] = useState(false);

  const changeTopic = async (topicId) => {
    setViewTopic(topicId);
    await dispatch(getTicket({ isExport: true, topicId, projectId: id }));
  };
  const selectPhase = async (val) => {
    setPhase(val);
    await dispatch(getReport({ phaseId: val, isExport: true, projectId: id }));
    setView(true);
  };
  let csvPhase = {
    headers: phasesCsvHeaders,
    data: CsvPhaseData,
    filename: 'project-phase-list.csv',
  };
  const ExportPhaseComponent = () => (
    <CSVLink {...csvPhase}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}>
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  let csvReport = {
    headers: reportsCsvHeaders,
    data: CsvReportData,
    filename: 'project-report-list.csv',
  };
  const ExportReportComponent = () => (
    <CSVLink {...csvReport}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}>
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  useEffect(async () => {
    if (topics?.projectTopicList.length !== 0) {
      changeTopic(topics?.projectTopicList[0]?._id);
    }
  }, [topics.projectTopicList]);
  useEffect(async () => {
    if (type === 'general') {
      await dispatch(getReport({ isExport: true, isGeneral: true, projectId: id }));
      setView(true);
    }
  }, [type]);
  useEffect(() => {
    setCsvPhaseData(phaseCSvExport(phases.list));
    setCsvReportData(reportCSvExport(reports.exportReportList));
  }, [phases.list, reports.exportReportList]);
  useEffect(async () => {
    await dispatch(getProjectTopic({ projectId: id }));
    await dispatch(getPhases({ isExport: true, projectId: id }));
    if (id) {
      await dispatch(getExportReport(id));
      await dispatch(getReport({ projectId: id, isExport: true }));
      setView(true);
      await dispatch(editProject(id)).then(async (response) => {
        if (response.status === 200) {
          setProjectDetail(response?.data?.data?.projectDetail);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
      await dispatch(getProjectTeam(id)).then(async (response) => {
        if (response.status === 200) {
          setProjectTeam(response?.data?.data?.projectDetail);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
  }, [dispatch]);
  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          VIEW PROJECT
        </Typography>
      )}
      <Card sx={{ display: 'flex', mb: 3.5 }}>
        <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <CardContent>
            {/* <Typography variant={'h2'} mb={1}>
              {projectDetail?.name}
            </Typography> */}
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} sm={4}>
                <Div
                  sx={{
                    width: { xs: 'auto' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}>
                  <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                    Project Name
                  </Typography>
                  <Typography variant={'h3'} fontSize={18}>
                    {projectDetail?.name}
                  </Typography>
                </Div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Div
                  sx={{
                    width: { xs: 'auto' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}>
                  <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                    Location
                  </Typography>
                  <Typography variant={'h3'} fontSize={18}>
                    {projectDetail?.city}
                  </Typography>
                </Div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Div
                  sx={{
                    width: { xs: 'auto' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}>
                  <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                    Total Area
                  </Typography>
                  <Typography variant={'h3'} fontSize={18}>
                    {`${projectDetail?.totalArea} sqmt`}
                  </Typography>
                </Div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Div
                  sx={{
                    width: { xs: 'auto' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}>
                  <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                    Start Date
                  </Typography>
                  <Typography variant={'h3'} fontSize={18}>
                    {projectDetail?.startDate ? moment(projectDetail.startDate).format('DD/MM/YYYY') : ''}
                  </Typography>
                </Div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Div
                  sx={{
                    width: { xs: 'auto' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}>
                  <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                    End Date
                  </Typography>
                  <Typography variant={'h3'} fontSize={18}>
                    {projectDetail?.endDate ? moment(projectDetail.endDate).format('DD/MM/YYYY') : ''}
                  </Typography>
                </Div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant={'h4'} mb={1}>
                  Overall project Status
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Div
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 0.5,
                  }}>
                  <StyledLinearProgress variant={'determinate'} color="secondary" value={projectDetail?.progress} />
                  <Typography variant={'body1'} color={'text.secondary'} ml={1}>{`${projectDetail?.progress}%`}</Typography>
                </Div>
              </Grid>
            </Grid>
          </CardContent>
        </Div>
      </Card>
      <CardComponent title={'Work Schedule'} Component={ExportPhaseComponent}>
        {phases?.list?.map((data) => (
          <ProjectViewWorkListCard key={data.id} item={data} />
        ))}
        {phases?.list?.length === 0 ? <NoDataPlaceholder /> : ''}
      </CardComponent>
      <CardComponent title={'Reports'} Component={ExportReportComponent}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Radio
              checked={type === 'phase'}
              onChange={(event) => {
                setType(event.target.value);
                setView(false);
                setPhase('');
              }}
              value="phase"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
              className="py-sm-0"
            />
            Phase Wise
            <Radio
              checked={type === 'general'}
              onChange={(event) => {
                setType(event.target.value);
              }}
              value="general"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
              className="py-sm-0 pl-sm-10"
            />
            Common
          </Grid>
          {type === 'phase' && (
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth className="form-control">
                <InputLabel id={'label'}>Select Phase</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phase}
                  label="Select Phase"
                  onChange={(event) => {
                    selectPhase(event.target.value);
                  }}>
                  {phases?.list?.map((val) => (
                    <MenuItem value={val._id}>{val.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <br></br>
        {view && (
          <>
            {reports.reportList.map((report) => (
              <ReportListCard key={report.id} item={report} />
            ))}
            {reports.reportList?.length === 0 && <NoDataPlaceholder />}
          </>
        )}
      </CardComponent>
      <Card sx={{ display: 'flex', mb: 3.5, overflow: 'auto' }}>
        <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <CardContent>
            <Typography variant={'h2'} mb={3}>
              Discussion Threads
            </Typography>
            <Div className="discussion-threads-responsive">
              <Div className="sub-discussion-threads">
                {topics?.projectTopicList?.map((val) => (
                  <Button
                    className="discussion-threads-btn"
                    color="secondary"
                    sx={{ mr: 2, mb: 2, color: 'black' }}
                    size={'small'}
                    onClick={() => changeTopic(val._id)}
                    variant={viewTopic === val._id ? 'contained' : 'outlined'}>
                    {val.name}
                  </Button>
                ))}
              </Div>
            </Div>
            <br></br>
            <Div className="ticket-responsive">
              {discussionTopics?.ticketList?.map((data) => (
                <TikitListCard key={data._id} item={data} />
              ))}
            </Div>
            {discussionTopics?.ticketList?.length === 0 && <NoDataPlaceholder />}
          </CardContent>
        </Div>
      </Card>
      <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
        TEAMS
      </Typography>
      <CardComponent title={`Pidilite Team`}>
        {ProjectTeam?.aseId?.map((val) => (
          <AseListCard key={val._id} item={val} />
        ))}
        {ProjectTeam?.aseId?.length === 0 && <NoDataPlaceholder />}
      </CardComponent>
      <CardComponent title={`Application Team`}>
        {ProjectTeam?.supervisorId?.map((val) => (
          <PartnerListCard key={val._id} item={val} />
        ))}
        {ProjectTeam?.supervisorId?.length === 0 && <NoDataPlaceholder />}
      </CardComponent>
      <CardComponent title={`Customer Team`}>
        {ProjectTeam?.clientId?.map((val) => (
          <ClientListCard key={val._id} item={val} />
        ))}
        {ProjectTeam?.clientId?.length === 0 && <NoDataPlaceholder />}
      </CardComponent>
    </>
  );
};

export default ViewProject;
