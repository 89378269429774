import React from 'react';
import Page from '@jumbo/shared/Page';
import Login from './Auth/Login';
import ForgotPassword from './Auth/ForgotPassword';
import AddEditRegion from './Pages/Region/AddEditRegion';
import AddEditPA from './Pages/PA/AddEditPA';
import AddEditRole from './Pages/Role/AddEditRole';
import ManagePermission from './Pages/Role/ManagePermission';
import {
  PA,
  Region,
  Role,
  Project,
  Supervisor,
  Client,
  Zone,
  ASE,
  SupervisorAdmin,
  Error404,
  Dashboard,
  Feedback,
  Phase,
  Report,
  ComingSoon,
  Log,
} from './Pages';
import AddEditProject from './Pages/Project/AddEditProject';
import ApproveProject from './Pages/Project/ApproveProject';
import RejectProject from './Pages/Project/RejectProject';
import ArchiveProject from './Pages/Project/ArchiveProject';
import DeleteProject from './Pages/Project/DeleteProject';
import PrivateRoute from './PrivateRoute';
import AddEditClient from './Pages/Client/AddEditClient';
import AddEditZone from './Pages/Zone/AddEditZone';
import AddEditASE from './Pages/ASE/AddEditASE';
import AddEditSupervisor from './Pages/SupervisorAdmin/AddEditSupervisor';
import ChangePassword from './Pages/User/ChangePassword';
import EditProfile from './Pages/User/EditProfile';
import WorkSchedule from './Pages/Supervisor/WorkSchedule';
import ReportCategory from './Pages/Report/ReportCategory';
import AddEditReportCategory from './Pages/Report/AddEditReportCategory';
import Topic from './Pages/Discussion/Topic';
import AddEditTopic from './Pages/Discussion/AddEditTopic';
import ViewThread from './Pages/Discussion/ViewThread';
import AddEditPhase from './Pages/Phase/AddEditPhase';
import ProjectDetails from './Pages/Supervisor/ProjectDetails';
import AddEditReport from './Pages/Report/AddEditReport';
import ViewProject from './Pages/Project/ViewProject';
import viewDiscussion from './Pages/Discussion/viewDiscussion';
import SupervisorDashboard from './Pages/Supervisor/Dashboard';
import OldReport from './Pages/Report/OldReport';
import ViewReport from './Pages/Report/ViewReport';
import Notification from './Pages/Supervisor/Notification';
import ResetPassword from './Auth/ResetPassword';
import SendNotification from './Pages/Supervisor/Notification/Send';
import ViewPA from './Pages/PA/ViewPA';

const routesForPA = [
  {
    path: '/supervisor',
    element: (
      <PrivateRoute>
        <Page layout={'solo-page'} component={Supervisor} />
      </PrivateRoute>
    ),
  },
  {
    path: '/phases',
    element: (
      <PrivateRoute>
        <Page component={Phase} />
      </PrivateRoute>
    ),
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Page component={SupervisorDashboard} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-phase',
    element: (
      <PrivateRoute>
        <Page component={AddEditPhase} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-phase/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditPhase} />
      </PrivateRoute>
    ),
  },
  {
    path: '/project',
    element: (
      <PrivateRoute>
        <Page component={ProjectDetails} />
      </PrivateRoute>
    ),
  },
  {
    path: '/work-schedule',
    element: (
      <PrivateRoute>
        <Page component={WorkSchedule} />
      </PrivateRoute>
    ),
  },
  {
    path: '/reports',
    element: (
      <PrivateRoute>
        <Page component={Report} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-report',
    element: (
      <PrivateRoute>
        <Page component={AddEditReport} />
      </PrivateRoute>
    ),
  },
  {
    path: '/discussion',
    element: (
      <PrivateRoute>
        <Page component={ViewThread} />
      </PrivateRoute>
    ),
  },
];
const routesForASE = [];
const routesForMA = [
  {
    path: '/category',
    element: (
      <PrivateRoute>
        <Page component={ReportCategory} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-category',
    element: (
      <PrivateRoute>
        <Page component={AddEditReportCategory} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-category/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditReportCategory} />
      </PrivateRoute>
    ),
  },
  {
    path: '/discussion-topic',
    element: (
      <PrivateRoute>
        <Page component={Topic} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-topic',
    element: (
      <PrivateRoute>
        <Page component={AddEditTopic} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-topic/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditTopic} />
      </PrivateRoute>
    ),
  },
  {
    path: '/feedback-download',
    element: (
      <PrivateRoute>
        <Page component={ComingSoon} />
      </PrivateRoute>
    ),
  },
  {
    path: '/logs',
    element: (
      <PrivateRoute>
        <Page component={Log} />
      </PrivateRoute>
    ),
  },
  {
    path: '/mobile-app',
    element: (
      <PrivateRoute>
        <Page component={ComingSoon} />
      </PrivateRoute>
    ),
  },
  {
    path: '/email-templates',
    element: (
      <PrivateRoute>
        <Page component={ComingSoon} />
      </PrivateRoute>
    ),
  },
  {
    path: '/sms-templates',
    element: (
      <PrivateRoute>
        <Page component={ComingSoon} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-supervisor/:companyId',
    element: (
      <PrivateRoute>
        <Page component={AddEditSupervisor} />
      </PrivateRoute>
    ),
  },
  {
    path: '/region',
    element: (
      <PrivateRoute>
        <Page component={Region} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-region',
    element: (
      <PrivateRoute>
        <Page component={AddEditRegion} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-region/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditRegion} />
      </PrivateRoute>
    ),
  },
  {
    path: '/role',
    element: (
      <PrivateRoute>
        <Page component={Role} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-role',
    element: (
      <PrivateRoute>
        <Page component={AddEditRole} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-role/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditRole} />
      </PrivateRoute>
    ),
  },
  {
    path: '/role/manage-permission',
    element: (
      <PrivateRoute>
        <Page component={ManagePermission} />
      </PrivateRoute>
    ),
  },
  {
    path: '/role/manage-permission/:id',
    element: (
      <PrivateRoute>
        <Page component={ManagePermission} />
      </PrivateRoute>
    ),
  },
  {
    path: '/approve-project',
    element: (
      <PrivateRoute>
        <Page component={ApproveProject} />
      </PrivateRoute>
    ),
  },
  {
    path: '/archive-project',
    element: (
      <PrivateRoute>
        <Page component={ArchiveProject} />
      </PrivateRoute>
    ),
  },
  {
    path: '/project-delete',
    element: (
      <PrivateRoute>
        <Page component={DeleteProject} />
      </PrivateRoute>
    ),
  },
  {
    path: '/client',
    element: (
      <PrivateRoute>
        <Page component={Client} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-client',
    element: (
      <PrivateRoute>
        <Page component={AddEditClient} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-client/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditClient} />
      </PrivateRoute>
    ),
  },
  {
    path: '/zone',
    element: (
      <PrivateRoute>
        <Page component={Zone} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-zone',
    element: (
      <PrivateRoute>
        <Page component={AddEditZone} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-zone/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditZone} />
      </PrivateRoute>
    ),
  },
  {
    path: '/ase',
    element: (
      <PrivateRoute>
        <Page component={ASE} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-ase',
    element: (
      <PrivateRoute>
        <Page component={AddEditASE} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-ase/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditASE} />
      </PrivateRoute>
    ),
  },
  {
    path: '/supervisor-admin',
    element: (
      <PrivateRoute>
        <Page component={SupervisorAdmin} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-supervisor',
    element: (
      <PrivateRoute>
        <Page component={AddEditSupervisor} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-supervisor/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditSupervisor} />
      </PrivateRoute>
    ),
  },
];
const routesForAll = [
  {
    path: '/discussion-view/:id',
    element: (
      <PrivateRoute>
        <Page component={viewDiscussion} />
      </PrivateRoute>
    ),
  },
  {
    path: '/change-password',
    element: (
      <PrivateRoute>
        <Page component={ChangePassword} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-profile',
    element: (
      <PrivateRoute>
        <Page component={EditProfile} />
      </PrivateRoute>
    ),
  },
  {
    path: '/old-report/:phaseId/:latestReportId/:categoryId/:projectId',
    element: (
      <PrivateRoute>
        <Page component={OldReport} />
      </PrivateRoute>
    ),
  },
  {
    path: '/work-schedule/:id/:taskId',
    element: (
      <PrivateRoute>
        <Page component={WorkSchedule} />
      </PrivateRoute>
    ),
  },
  {
    path: '/feedback',
    element: (
      <PrivateRoute>
        <Page component={Feedback} />
      </PrivateRoute>
    ),
  },
  {
    path: '/notification',
    element: (
      <PrivateRoute>
        <Page component={Notification} />
      </PrivateRoute>
    ),
  },
  {
    path: '/send-notification',
    element: (
      <PrivateRoute>
        <Page component={SendNotification} />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    element: (
      <PrivateRoute>
        <Page component={Error404} layout={'solo-page'} />
      </PrivateRoute>
    ),
  },
];
const routesForASEOrMA = [
  {
    path: '/phases',
    element: (
      <PrivateRoute>
        <Page component={Phase} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-phase/:projectId',
    element: (
      <PrivateRoute>
        <Page component={AddEditPhase} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-phase/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditPhase} />
      </PrivateRoute>
    ),
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Page component={Dashboard} />
      </PrivateRoute>
    ),
  },
  {
    path: '/reports',
    element: (
      <PrivateRoute>
        <Page component={Report} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-report',
    element: (
      <PrivateRoute>
        <Page component={AddEditReport} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-report/:projectId',
    element: (
      <PrivateRoute>
        <Page component={AddEditReport} />
      </PrivateRoute>
    ),
  },
  {
    path: '/view-report/:projectId',
    element: (
      <PrivateRoute>
        <Page component={ViewReport} />
      </PrivateRoute>
    ),
  },
  {
    path: '/view-thread',
    element: (
      <PrivateRoute>
        <Page component={ViewThread} />
      </PrivateRoute>
    ),
  },
  {
    path: '/view-thread/:projectId',
    element: (
      <PrivateRoute>
        <Page component={ViewThread} />
      </PrivateRoute>
    ),
  },
  {
    path: '/pa',
    element: (
      <PrivateRoute>
        <Page component={PA} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-pa',
    element: (
      <PrivateRoute>
        <Page component={AddEditPA} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-pa/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditPA} />
      </PrivateRoute>
    ),
  },
  {
    path: '/view-pa/:id',
    element: (
      <PrivateRoute>
        <Page component={ViewPA} />
      </PrivateRoute>
    ),
  },
  {
    path: '/project',
    element: (
      <PrivateRoute>
        <Page component={Project} />
      </PrivateRoute>
    ),
  },
  {
    path: '/view-project/:id',
    element: (
      <PrivateRoute>
        <Page component={ViewProject} />
      </PrivateRoute>
    ),
  },
  {
    path: '/project-reject',
    element: (
      <PrivateRoute>
        <Page component={RejectProject} />
      </PrivateRoute>
    ),
  },
  {
    path: '/add-project',
    element: (
      <PrivateRoute>
        <Page component={AddEditProject} />
      </PrivateRoute>
    ),
  },
  {
    path: '/edit-project/:id',
    element: (
      <PrivateRoute>
        <Page component={AddEditProject} />
      </PrivateRoute>
    ),
  },
  {
    path: '/work-schedule/:id',
    element: (
      <PrivateRoute>
        <Page component={WorkSchedule} />
      </PrivateRoute>
    ),
  },
];

const routesForNotAuthenticatedOnly = [
  {
    path: '/login',
    element: <Page component={Login} layout={'solo-page'} disableSmLogin={true} />,
  },
  {
    path: '/forgot-password',
    element: <Page component={ForgotPassword} layout={'solo-page'} />,
  },
  {
    path: '/reset-password',
    element: <Page component={ResetPassword} title={'Reset'} layout={'solo-page'} />,
  },
  {
    path: '/set-password',
    element: <Page component={ResetPassword} title={'Set'} layout={'solo-page'} />,
  },
  {
    path: '*',
    element: <Page component={Login} layout={'solo-page'} />,
  },
];

const routes = [
  ...routesForASEOrMA,
  ...routesForPA,
  ...routesForASE,
  ...routesForMA,
  ...routesForAll,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForASEOrMA,
  routesForPA,
  routesForASE,
  routesForMA,
  routesForAll,
  routesForNotAuthenticatedOnly,
};
