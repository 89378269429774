import React, { useEffect, useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Dialog,
} from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import WorkScheduleListCard from '@jumbo/components/List/WorkScheduleListCard';
import { useMediaQuery } from '@mui/material';
import Div from '@jumbo/shared/Div';
import { getPhases } from 'app/redux/actions/Phase';
import { addTask, getGanttChart, getTasks } from 'app/redux/actions/WorkSchedule';
import { ganttChat } from 'app/helpers/ganttChart';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import Gantt from '@jumbo/components/Chart/Gantt';
import CloseIcon from '@mui/icons-material/Close';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { div2PDF } from 'app/helpers/ganttChartToPDF';
import moment from 'moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getProjectName } from 'app/redux/actions/Project';
const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'name can set of between 2 and 100 characters!')
    .max(100, 'name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  description: yup
    .string('Enter your description')
    .min(5, 'Description can set of between 5 and 255 characters!')
    .max(255, 'Description can set of between 5 and 255 characters!')
    .required('The Description field is required'),
  areaCovered: yup.number().required('The Total Area field is required').min(1, 'Please enter number value greater than 0'),
});

const WorkSchedule = () => {
  const dispatch = useDispatch();
  const mobileView = useMediaQuery('(max-width:575px)');
  const { id, taskId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const perPage = envData.PER_PAGE;
  const phases = useSelector((state) => state?.phase);
  const projects = useSelector((state) => state?.project);
  const tasks = useSelector((state) => state?.workSchedule);
  const [phase, setPhase] = useState('');
  const [phaseName, setPhaseName] = useState('');
  const [phaseId, setPhaseId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [phaseStartDate, setPhaseStartDate] = useState(null);
  const [phaseEndDate, setPhaseEndDate] = useState(null);
  const [phaseData, setPhaseData] = useState();
  const [taskData, setTaskData] = useState([]);
  const [taskPhaseId, setTaskPhaseId] = useState('');
  const [deleted, setDeleted] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [chatDataNotFound, setChatDataNotFound] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const count = Math.ceil(tasks?.totalItems / perPage);
  const _DATA = usePagination(tasks?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const mutation = { isError: false };
  const errorMsg = (error) => {
    enqueueSnackbar(error, { variant: 'error' });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      areaCovered: '',
      areaCompleted: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      if (phaseId === '') {
        errorMsg('The phaseId field is required');
        return;
      } else if (!startDate) {
        errorMsg('The Start Date field is required');
        return;
      } else if (!endDate) {
        errorMsg('The End Date field is required');
        return;
      }
      setSubmitting(true);
      let newProjectData = {
        name: values.name.trim(),
        description: values.description.trim(),
        areaToBeCovered: +values.areaCovered,
        phaseId: phaseId?._id,
        startDate,
        endDate,
      };
      const response = await dispatch(addTask(newProjectData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        resetForm();
        setPhaseId('');
        setStartDate(null);
        setEndDate(null);
        setTaskPhaseId(phaseId?._id);
        await dispatch(
          getTasks({ projectId: id ? id : localStorage.getItem('projectId'), page, perPage, phaseId: phaseId?._id }),
        );
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });

  const selectPhase = async (val) => {
    setPhase(val);
    setPhaseName(val.name);
    setPhaseStartDate(val.startDate);
    setPhaseEndDate(val.endDate);
    await dispatch(getGanttChart(val._id)).then(async (response) => {
      if (response.status === 200) {
        const taskChartData = response.data.data.taskChartData;
        setTaskData(taskChartData);
        setPhaseData(response.data.data.phaseData);
        if (taskChartData.length !== 0) {
          setChatDataNotFound(true);
          setChatData(ganttChat(taskChartData));
        } else {
          setChatDataNotFound(false);
        }
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };

  useEffect(async () => {
    await dispatch(
      getTasks({ projectId: id ? id : localStorage.getItem('projectId'), page, perPage, phaseId: taskPhaseId }),
    );
  }, [deleted, dispatch, taskPhaseId, page]);

  useEffect(() => {
    dispatch(getProjectName(id ? id : localStorage.getItem('projectId')));
    dispatch(getPhases({ projectId: id ? id : localStorage.getItem('projectId') }));
  }, [deleted, dispatch]);

  const { setFieldValue, handleSubmit, isSubmitting } = formik;

  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          {`WORK SCHEDULE - ${projects?.projectName}`}
        </Typography>
      )}
      <Card sx={{ display: 'flex', mb: 3.5 }}>
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant={'h2'}>Create Task</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormikProvider value={formik}>
              <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className="form-control">
                      <InputLabel id={'label'} size="small">
                        Select Phase*
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select1"
                        value={phaseId}
                        label="Select Phase*"
                        onChange={(event) => {
                          setPhaseId(event.target.value);
                          setFieldValue('areaCovered', event.target.value.areaToBeCovered);
                        }}>
                        {phases.list.map((val) => (
                          <MenuItem size="small" value={val} key={val._id}>
                            {val.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        disabled
                        label="Phase Start Date"
                        value={phaseId?.startDate ?? null}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        disabled
                        label="Phase End Date"
                        value={phaseId?.endDate ?? null}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <JumboTextField size="small" fullWidth name="name" label="Task Name*" />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="Task Start Date*"
                        value={startDate}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="Task End Date*"
                        value={endDate}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <JumboTextField size="small" fullWidth name="areaCovered" label="Total Area (Sqmt)" disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <JumboTextField fullWidth name="description" label="Description*" multiline rows={2} maxRows={4} />
                  </Grid>
                </Grid>
                <br></br>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{ mb: 3 }}
                      loading={isSubmitting || mutation.isLoading}>
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </AccordionDetails>
        </Accordion>
      </Card>

      <Card sx={{ display: 'flex' , mb: 3.5}}>
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant={'h2'}>Update Task</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth className="form-control">
                  <InputLabel id={'label'}>Select Phase*</InputLabel>
                  <Select
                    labelId="phase-select-label"
                    id="phase-select"
                    value={taskPhaseId}
                    label="Select Phase*"
                    onChange={(event) => {
                      setTaskPhaseId(event.target.value);
                    }}>
                    {phases.list.map((val) => (
                      <MenuItem value={val._id}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {taskPhaseId !== '' && (
              <>
                {tasks?.list?.map((val, key) => (
                  <WorkScheduleListCard key={key} item={val} taskId={taskId} setDeleted={setDeleted} deleted={deleted} />
                ))}
                {tasks?.list?.length !== 0 ? (
                  <JumboPagination count={count} page={page} handleChange={handleChangePage} />
                ) : (
                  <NoDataPlaceholder />
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Card>
      
      <Card sx={{ display: 'flex', mb: 3.5 }}>
        <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <CardContent className="px-xs-15">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography component={'h2'} variant="h1" mb={3}>
                  View Gantt Chart
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth className="form-control">
                  <InputLabel id={'label'}>Select Phase*</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={phase}
                    label="Select Phase*"
                    onChange={(event) => {
                      selectPhase(event.target.value);
                    }}>
                    {phases.list.map((val) => (
                      <MenuItem value={val}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            {phaseName !== '' && (
              <>
                <Typography variant={'h5'} mb={3} className="phase-main">
                  <Grid container spacing={3} className="phase-inner">
                    <Grid item xs={10} sm={11} className="text-white">
                      {`Phase - ${phaseName} (${moment(phaseStartDate).format('DD/MM/YYYY')} - ${moment(phaseEndDate).format(
                        'DD/MM/YYYY',
                      )})`}
                    </Grid>
                    {chatDataNotFound ? (
                      <Grid item xs={2} sm={1}>
                        <Tooltip title="View Chart" placement="top-start" arrow>
                          <Div
                            onClick={() => setOpen(true)}
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              marginLeft: 'auto',
                              backgroundColor: '#BDD52F',
                            }}>
                            <RemoveRedEyeIcon style={{ width: '24px', margin: '9px' }} />
                          </Div>
                        </Tooltip>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Typography>
                {chatDataNotFound ? <Gantt chatData={chatData} /> : <NoDataPlaceholder />}
              </>
            )}
          </CardContent>
        </Div>
      </Card>
      <Dialog
        className="custom-modal"
        fullWidth
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              overflow: 'scroll',
              width: '100%',
              maxWidth: '900px',
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}>
        <Card sx={{ display: 'flex' }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Div sx={{ display: 'flex', justifyContent: 'flex-end', marginBlock: '10px' }}>
                <Tooltip title="Download PDF" placement="top-start" arrow>
                  <Div
                    onClick={(e) => div2PDF(e)}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: '#BDD52F',
                    }}>
                    <img
                      src={`${ASSET_IMAGES}/download.png`}
                      alt="Pidilite"
                      style={{ width: '22px', margin: '12px 9px 9px 9px' }}
                    />
                  </Div>
                </Tooltip>
                <Div
                  onClick={() => setOpen(false)}
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    marginLeft: '10px',
                    backgroundColor: '#BDD52F',
                  }}>
                  <CloseIcon style={{ width: '24px', margin: '9px' }} />
                </Div>
              </Div>
              <div class="div2PDF">
                <Div sx={{ margin: '0 10px' }}>
                  <Typography variant={'h2'} mb={3} className="phase-main">
                    <Grid container spacing={3} className="phase-inner">
                      <Grid item xs={12} sm={12} className="text-white">
                        {`${phaseData?.name} Work Schedule for ${phaseData?.projectId?.name}`}
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography variant={'h2'} mb={5} className="phase-main-2">
                    <Grid container spacing={3} className="phase-inner">
                      <Grid item xs={12} sm={12} className="text-black">
                        {`${moment(phaseData?.startDate).format('DD/MM/YYYY')} - ${moment(phaseData?.endDate).format(
                          'DD/MM/YYYY',
                        )} (${phaseData?.areaToBeCovered} Sqmt)`}
                      </Grid>
                    </Grid>
                  </Typography>
                  <Gantt chatData={chatData} />
                  <center>
                    <Typography variant={'h5'} mb={3} mt={3}>
                      {`Task Details as on ${moment(new Date()).format('MMM Do YYYY')}`}
                    </Typography>
                  </center>
                  <div className="chart-table-main">
                    <table className="chart-table">
                      <tr>
                        <th>Task name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Progress</th>
                      </tr>
                      {taskData.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val?.name}</td>
                            <td>{moment(val?.startDate).format('DD/MM/YYYY')}</td>
                            <td>{moment(val?.endDate).format('DD/MM/YYYY')}</td>
                            <td>{`${val.progress}%`}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  <br></br>
                  <Div
                    sx={{
                      py: 2,
                      px: { lg: 6, xs: 4 },
                      borderTop: 2,
                      borderColor: 'divider',
                      bgcolor: 'background.paper',
                    }}>
                    <Div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant={'body1'} color={'text.primary'}>
                        © Copyright Pidilite 2023. All Rights Reserved.
                      </Typography>
                    </Div>
                  </Div>
                </Div>
              </div>
            </CardContent>
          </Div>
        </Card>
      </Dialog>
    </>
  );
};

export default WorkSchedule;
