import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import 'yup-phone';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { editUser, updateUser } from 'app/redux/actions/User';
import Loader from '@jumbo/components/Loader';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 100 characters!')
    .max(100, 'Name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  designation: yup
    .string('Enter your designation')
    .min(2, 'Designation can set of between 2 and 100 characters!')
    .max(100, 'Designation can set of between 2 and 100 characters!')
    .required('The Designation field is required'),
  mobile: yup.string('Enter your mobile').required('The Mobile Number field is required').phone('IN'),
  email: yup
    .string()
    .email('Please enter a valid email address in the format example@domain.com')
    .required('The Email field is required'),
});

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState('');
  const [loader, setLoader] = useState(true);
  const result = JSON.parse(localStorage.getItem('User'));
  const [id] = useState(result?.user?._id);

  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      name: user?.name ? user?.name : '',
      email: user?.email ? user?.email : '',
      mobile: user?.mobileNumber ? user?.mobileNumber : '',
      designation: user?.designation ? user?.designation : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newUserData = {
        name: values.name.trim(),
        email: values.email.trim(),
        designation: values.designation.trim(),
        mobileNumber: values.mobile.trim(),
        roleId: user?.roleId,
      };
      const response = await dispatch(updateUser(id, newUserData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        localStorage.setItem(
          'User',
          JSON.stringify({ token: JSON.parse(localStorage.getItem('AUTH_TOKEN')), user: response.data.data.user }),
        );
        navigate('/');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (id) {
      dispatch(editUser(id)).then((response) => {
        if (response.status === 200) {
          setUser(response?.data?.data?.userDetail);
          setFieldValue('name', response?.data?.data?.userDetail?.name);
          setFieldValue('email', response?.data?.data?.userDetail?.email);
          setFieldValue('designation', response?.data?.data?.userDetail?.designation);
          setFieldValue('mobile', response?.data?.data?.userDetail?.mobileNumber);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent className="px-xs-15">
              <Typography component={'h2'} variant="h1" mb={3}>
                Update Profile
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="name" label="Name" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="email" label="Email" disabled />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="designation" label="Designation" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="mobile" label="Mobile" />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || mutation.isLoading}>
                        Save
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default EditProfile;
