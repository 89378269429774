import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Card, CardContent, Dialog, Grid, LinearProgress, Tooltip, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Gantt from '../Chart/Gantt';
import { getGanttChart } from 'app/redux/actions/WorkSchedule';
import { ganttChat } from 'app/helpers/ganttChart';
import CloseIcon from '@mui/icons-material/Close';
import ControlledAccordions from '../common/Accordion';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { div2PDF } from 'app/helpers/ganttChartToPDF';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';

const ProjectViewWorkListCard = ({ item }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [view, setView] = useState(false);
  const selectPhase = async () => {
    setOpen(true);
    await dispatch(getGanttChart(item._id)).then(async (response) => {
      if (response.status === 200) {
        const taskChartData = response.data.data.taskChartData;
        setTaskData(taskChartData);
        if (taskChartData.length !== 0) {
          setChatData(ganttChat(taskChartData));
          setView(true);
        } else {
          setView(false);
        }
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };

  return (
    <>
      <Card sx={{ mb: 1, boxShadow: 'none' }}>
        <ControlledAccordions>
          <div className="wrap">
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '20%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Phase
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {item?.name}
              </Typography>

              <Typography variant={'h5'} mb={0.5} fontSize={14}></Typography>
              <Typography fontSize={'12px'} variant={'body1'}></Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '10%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Area
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {item?.areaToBeCovered}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '30%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Description
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {item?.description}
              </Typography>
            </Div>
            <Div sx={{ width: '20%', flexShrink: 0, px: 2 }} className='progress-bar'>
              <Tooltip title={`${item.progress}%`} placement="top-start" arrow>
                <LinearProgress
                  variant={'determinate'}
                  color="secondary"
                  value={item.progress}
                  sx={{
                    height: 6,
                    borderRadius: 2,
                  }}
                />
              </Tooltip>
            </Div>
            <Div sx={{ width: '15%', flexShrink: 0, px: 1 }} className='full-width'>
              <Typography fontSize={'14px'} color="red" variant={'body1'} onClick={selectPhase}>
                View Gantt Chart
              </Typography>
            </Div>
          </div>
        </ControlledAccordions>
      </Card>
      <Dialog
        className="custom-modal"
        fullWidth
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              overflow: 'scroll',
              width: '100%',
              maxWidth: '900px', // Set your width here
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}>
        <Card sx={{ display: 'flex' }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              {view ? (
                <>
                  <Div sx={{ display: 'flex', justifyContent: 'flex-end', marginBlock: '10px' }}>
                    <Tooltip title="Download PDF" placement="top-start" arrow>
                      <Div
                        onClick={(e) => div2PDF(e)}
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          backgroundColor: '#BDD52F',
                        }}>
                        <img
                          src={`${ASSET_IMAGES}/download.png`}
                          alt="Pidilite"
                          style={{ width: '22px', margin: '12px 9px 9px 9px' }}
                        />
                      </Div>
                    </Tooltip>
                    <Div
                      onClick={() => setOpen(false)}
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        marginLeft: '10px',
                        backgroundColor: '#BDD52F',
                      }}>
                      <CloseIcon style={{ width: '24px', margin: '9px' }} />
                    </Div>
                  </Div>
                  <div class="div2PDF">
                    <Div sx={{ margin: '0 10px' }}>
                      <Typography variant={'h2'} mb={3} className="phase-main">
                        <Grid container spacing={3} className="phase-inner">
                          <Grid item xs={12} sm={12} className="text-white">
                            {`${item?.name} Work Schedule for ${item?.projectInfo?.name}`}
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography variant={'h2'} mb={5} className="phase-main-2">
                        <Grid container spacing={3} className="phase-inner">
                          <Grid item xs={12} sm={12} className="text-black">
                            {`${moment(item?.startDate).format('DD/MM/YYYY')} - ${moment(item?.endDate).format(
                              'DD/MM/YYYY',
                            )} (${item?.areaToBeCovered} Sqmt)`}
                          </Grid>
                        </Grid>
                      </Typography>
                      <Gantt chatData={chatData} />
                      <center>
                        <Typography variant={'h5'} mb={3} mt={3}>
                          {`Task Details as on ${moment(new Date()).format('MMM Do YYYY')}`}
                        </Typography>
                      </center>
                      <div className="chart-table-main">
                        <table className="chart-table">
                          <tr>
                            <th>Task name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Progress</th>
                          </tr>
                          {taskData.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val?.name}</td>
                                <td>{moment(val?.startDate).format('DD/MM/YYYY')}</td>
                                <td>{moment(val?.endDate).format('DD/MM/YYYY')}</td>
                                <td>{`${val.progress}%`}</td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                      <br></br>
                      <Div
                        sx={{
                          py: 2,
                          px: { lg: 6, xs: 4 },
                          borderTop: 2,
                          borderColor: 'divider',
                          bgcolor: 'background.paper',
                        }}>
                        <Div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant={'body1'} color={'text.primary'}>
                            © Copyright Pidilite 2023. All Rights Reserved.
                          </Typography>
                        </Div>
                      </Div>
                    </Div>
                  </div>
                </>
              ) : (
                <NoDataPlaceholder />
              )}
            </CardContent>
          </Div>
        </Card>
      </Dialog>
    </>
  );
};
export default ProjectViewWorkListCard;
