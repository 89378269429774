import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

const ControlledAccordions = ({ children, expand, descriptionKey, descriptionValue, comment }) => {
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleClick} square sx={{ borderRadius: 2, backgroundColor: '#f5f7fa' }}>
      <AccordionSummary
        className={`px-sm-8 ${expand ? '' : 'cursor-normal'}`}
        expandIcon={expand ? expanded ? <RemoveIcon /> : <AddIcon /> : false}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 3,
          flexDirection: 'row-reverse',

          '& .MuiAccordionSummary-content': {
            alignItems: 'center',

            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            borderRadius: 1,
            border: 1,
            height: 28,
            width: 28,
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
            transform: 'none',
            color: 'black',
            borderColor: 'primary.main',

            '& svg': {
              fontSize: '1.25rem',
            },
          },
        }}>
        {children}
      </AccordionSummary>
      {expand && (
        <AccordionDetails sx={{ borderTop: 1, borderColor: 'divider', p: (theme) => theme.spacing(2, 2, 2, 8.25) }}>
          <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
            {descriptionKey}
          </Typography>
          <Typography variant={'h5'} fontSize={14}>
            {descriptionValue}
          </Typography>
          {comment && (
            <>
              <br></br>
              <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
                Comment
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {comment}
              </Typography>
            </>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
ControlledAccordions.propTypes = {
  children: PropTypes.node,
};
export default ControlledAccordions;
