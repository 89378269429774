import React from 'react';
import { useDispatch } from 'react-redux';
import { alpha, Card, CardContent, Checkbox, FormControlLabel, Typography } from '@mui/material';
import * as yup from 'yup';
import 'yup-phone';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { ASSET_IMAGES } from '../../utils/constants/paths';
import { AuhMethods } from 'app/services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import RoleConstants from '@jumbo/constants/RoleConstants';

import './auth.css';
import JumboPasswordField from '@jumbo/components/JumboFormik/JumboPasswordField';
import { getAssetPath } from 'app/utils/appHelpers';
// import { requestForToken } from '../../../firebase';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address in the format example@domain.com')
    .required('The Email field is required'),
  password: yup.string('Enter your password').required('The Password field is required'),
});

const Login = ({ method = CurrentAuthMethod }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['access_token']);

  const onSignIn = (email, password) => {
    dispatch(
      // AuhMethods[method].onLogin({ email, password, deviceToken: JSON.parse(localStorage.getItem('FCM_TOKEN')) }),
      AuhMethods[method].onLogin({ email, password }),
    ).then((response) => {
      if (response.status === 200) {
        setCookie('access_token', response.data.data.token, {
          path: '/',
          secure: false,
          // httpOnly: true,
          sameSite: 'strict',
        });
        if (response.data.data.user.roleId.type !== RoleConstants.PA) {
          navigate('/');
        } else {
          navigate('/supervisor');
        }
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };
  const mutation = { isError: false };
  // useEffect(() => {
  //   requestForToken();
  // }, []);
  return (
    <Div
      className="login-wrapper"
      sx={{
        width: 720,
        maxWidth: '100%',
        margin: 'auto',
        p: 4,
      }}>
      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}>
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            background: `#a2a6a9 url(${getAssetPath(
              `${ASSET_IMAGES}/Pidilite-Disha-Login.jpg`,
              '640x428',
            )}) no-repeat center`,
            backgroundSize: 'cover',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: alpha('#000000', 0.65),
            },
          }}>
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
            }}>
            <Div sx={{ mb: 2 }}>
              <Typography variant={'h3'} color={'inherit'} fontWeight={500} mb={3}>
                Sign In
              </Typography>
              <Typography variant={'body1'}>Welcome to Pidilite Disha Application.</Typography>
              <Typography variant={'body1'} mb={2}>
                Sign in to access all features of the application.
              </Typography>
              {/* <Typography variant={'body1'}>
                <NavLink className="forget-text" to="/forgot-password" underline={'none'}>
                  Forgot your password? Recover Now
                </NavLink>
              </Typography> */}
            </Div>
            <Div sx={{ mt: 'auto' }}>
              <img src={`${ASSET_IMAGES}/disha_logo.png`} style={{ width: '120px' }} alt="Pidilite" />
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }} className="px-xs-15">
          <Formik
            validateOnChange={true}
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSignIn(data.email, data.password);
              setSubmitting(false);
            }}>
            {({ isSubmitting }) => (
              <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField fullWidth name="email" label="Email*" />
                </Div>
                <Div sx={{ mt: 1, mb: 2 }}>
                  <JumboPasswordField fullWidth name="password" label="Password*" />
                </Div>
                <Typography textAlign={'left'} variant={'body1'} sx={{ marginBottom: 0, marginTop: 1 }}>
                  <Link style={{ color: 'red' }} to="/forgot-password">
                    Forgot password?
                  </Link>
                </Typography>
                <Div sx={{ mb: 2 }}>
                  <FormControlLabel control={<Checkbox />} label="Remember me" />
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}>
                  Sign In
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login;
