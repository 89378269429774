import React from 'react';
import { Pagination } from '@mui/material';

const JumboPagination = ({ count, page, handleChange }) => {
  return (
    <Pagination
      count={count}
      page={page}
      style={{ justifyContent: 'center', display: 'flex' }}
      color="primary"
      onChange={handleChange}
    />
  );
};

export default JumboPagination;
