import React from 'react';
import { Bar } from 'react-chartjs-2';

export const BarChart = ({ chartData, tilteY }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ' : ';
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y}`;
            }
            return label;
          },
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: `Number of ${tilteY}`,
        },
      },
    },
    ticks: {
      precision: 0,
    },
  };
  return (
    <div className="chart-container">
      <Bar data={chartData} height={'60px'} options={options} />
    </div>
  );
};
export default BarChart;
