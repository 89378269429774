import axios from 'axios';

import { GET_LOG, GET_LOG_CSV } from '@jumbo/utils/constants/ActionTypes';
import { fetchError } from './Common';

export const getLogs = ({ regionId, userType, loginDate, page, perPage }) => {
  return (dispatch) => {
    return axios
      .get('/log/user-list', { params: { regionId, userType, loginDate, page, perPage } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_LOG,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getLogsCsv = ({ regionId, userType, loginDate }) => {
  return (dispatch) => {
    return axios
      .get('/log/user-list', { params: { regionId, userType, loginDate } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_LOG_CSV,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
