import React from 'react';
import { alpha, Card, CardContent, Typography } from '@mui/material';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import Div from '@jumbo/shared/Div';
import { ASSET_IMAGES } from '../../utils/constants/paths';
import './auth.css';
import JumboPasswordField from '@jumbo/components/JumboFormik/JumboPasswordField';
import { getAssetPath } from 'app/utils/appHelpers';
import { useSearchParams } from 'react-router-dom/dist';
import { resetPassword } from 'app/redux/actions/Auth';

const validationSchema = yup.object({
  newPassword: yup.string('Enter your new password').required('The New Password field is required'),
  confirmPassword: yup
    .string('Enter your confirm password')
    .required('The Confirm Password field is required')
    .oneOf([yup.ref('newPassword')], 'Passwords does not match'),
});

const ResetPassword = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const mutation = { isError: false };
  return (
    <Div
      className="login-wrapper"
      sx={{
        width: 720,
        maxWidth: '100%',
        margin: 'auto',
        p: 4,
      }}>
      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}>
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            background: `#a2a6a9 url(${getAssetPath(
              `${ASSET_IMAGES}/Pidilite-Disha-Login.jpg`,
              '640x428',
            )}) no-repeat center`,
            backgroundSize: 'cover',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: alpha('#000000', 0.65),
            },
          }}>
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
            }}>
            <Div sx={{ mb: 2 }}>
              <Typography variant={'h3'} color={'inherit'} fontWeight={500} mb={3}>
                {title} Password
              </Typography>
              <Typography variant={'body1'}>Welcome to Pidilite Disha App</Typography>
              <Typography variant={'body1'} mb={2}>
              Create your password to access the portal
              </Typography>
            </Div>
            <Div sx={{ mt: 'auto' }}>
              <img src={`${ASSET_IMAGES}/disha_logo.png`} style={{ width: '120px' }} alt="Pidilite" />
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }} className="px-xs-15">
          <Formik
            validateOnChange={true}
            initialValues={{
              newPassword: '',
              confirmPassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              const ResetPasswordData = {
                email: {
                  iv: searchParams.get('email'),
                  content: searchParams.get('content'),
                },
                newPassword: data.newPassword.trim(),
                confirmPassword: data.confirmPassword.trim(),
                pathname: location.pathname,
              };
              const response = await dispatch(resetPassword(ResetPasswordData));
              if (response.status === 200) {
                enqueueSnackbar(response.data?.responseMessage, {
                  variant: 'success',
                });
                navigate('/login');
              } else {
                enqueueSnackbar(response.data?.responseMessage, {
                  variant: 'error',
                });
              }
              setSubmitting(false);
            }}>
            {({ isSubmitting }) => (
              <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboPasswordField fullWidth name="newPassword" label="New Password*" />
                </Div>
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboPasswordField fullWidth name="confirmPassword" label="Confirm Password*" />
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}>
                  Sign In
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default ResetPassword;
