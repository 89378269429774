export const VISIBLE_DIALOG_OPTIONS = {
  NONE: 'none',
  CONTACT_EDIT: 'contact-edit-dialog',
  CONTACT_DETAIL: 'contact-detail-dialog',
  CONTACT_CONFIRM_DELETE: 'contact-confirm-delete',
  LABEL_EDIT: 'label-edit-dialog',
  LABEL_CONFIRM_DELETE: 'label-confirm-delete',
};
export const CONTACT_ADDED = 'set-on-contact-add';
export const CONTACT_DELETE_STARTED = 'set-on-contact-delete-start';
export const CONTACT_DELETE_COMPLETED = 'set-on-contact-delete-complete';
export const CONTACT_EDIT_STARTED = 'set-on-contact-edit-start';
export const CONTACT_EDIT_COMPLETED = 'set-on-contact-edit-complete';
export const CONTACT_READ_STARTED = 'set-on-contact-read-start';
export const CONTACT_READ_COMPLETED = 'set-on-contact-read-complete';
export const CONTACT_LIST_REFRESH_COMPLETED = 'set-on-contact-list-refresh-completed';
export const HIDE_VISIBLE_DIALOG = 'set-visible-dialog';

export const LABEL_ADDED = 'set-on-label-add';
export const LABEL_EDIT_STARTED = 'set-on-label-edit-start';
export const LABEL_EDIT_COMPLETED = 'set-on-label-edit-completed';
export const LABEL_DELETE_STARTED = 'set-on-label-delete-start';
export const LABEL_DELETE_COMPLETED = 'set-on-label-delete-complete';
export const LABEL_LIST_REFRESH_COMPLETED = 'set-on-label-list-refresh-completed';

export const ASSIGN_LABELS_TO_CONTACTS = 'set-on-assign-labels-to-contacts';
