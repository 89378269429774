import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  useMediaQuery,
  Autocomplete,
  Box,
  TextField,
  Radio,
} from '@mui/material';
import ReportListCard from '@jumbo/components/List/ReportListCard';
import { LoadingButton } from '@mui/lab';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import { userType } from 'app/helpers/userType';
import { getSelectProjects } from 'app/redux/actions/Project';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getPhases } from 'app/redux/actions/Phase';
import { getReport } from 'app/redux/actions/Report';
import JumboPagination from '@jumbo/components/JumboPagination';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';

const Report = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const { isPA } = userType();
  const { enqueueSnackbar } = useSnackbar();
  const projects = useSelector((state) => state?.project);
  const phases = useSelector((state) => state?.phase);
  const reports = useSelector((state) => state?.report);
  const [view, setView] = useState(false);
  const [projectValue, setProjectValue] = useState('');
  const [phase, setPhase] = useState('');
  const [viewReport, setViewReport] = useState(false);
  const [type, setType] = useState();
  const [page, setPage] = useState(1);
  const mutation = { isError: false };
  const count = Math.ceil(reports?.totalItems / perPage);
  const _DATA = usePagination(reports.reportList, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const projectSelect = (value) => {
    setViewReport(false);
    setFieldValue('name', value?.name ?? '');
    setProjectValue(value?._id ?? '');
    setView(false);
  };
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      if (projectValue === '') {
        enqueueSnackbar('The ProjectId field is required', { variant: 'error' });
        return;
      }
      setSubmitting(true);
      await dispatch(getPhases({ projectId: projectValue }));
      await dispatch(getReport({ projectId: projectValue, page, perPage }));
      setView(true);
      setViewReport(true);
      setSubmitting(false);
    },
  });
  const selectPhase = async (val) => {
    setPhase(val);
    setView(true);
    await dispatch(getReport({ phaseId: val._id, page, perPage }));
  };

  useEffect(async () => {
    if (isPA) {
      setProjectValue(localStorage.getItem('projectId'));
      await dispatch(getPhases({ projectId: localStorage.getItem('projectId') }));
      await dispatch(getReport({ projectId: localStorage.getItem('projectId'), page, perPage }));
      setView(true);
      setViewReport(true);
    } else {
      await dispatch(getSelectProjects({}));
    }
  }, [dispatch]);

  useEffect(async () => {
    if (type === 'general') {
      await dispatch(
        getReport({ isGeneral: true, page, perPage, projectId: isPA ? localStorage.getItem('projectId') : projectValue }),
      );
      setView(true);
    }
  }, [type]);

  useEffect(async () => {
    await dispatch(getReport({ phaseId: phase?._id, page, perPage }));
  }, [page]);
  const { setFieldValue, handleSubmit, isSubmitting } = formik;

  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          ALL REPORTS
        </Typography>
      )}
      {!isPA && (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id="projectId"
                        size="small"
                        name="projectId"
                        options={projects?.selectList}
                        autoHighlight
                        onChange={(e, values) => projectSelect(values)}
                        getOptionLabel={(option) => option.uniqId}
                        getOptionSelected={(option) => option?._id === projectValue}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.uniqId}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            label="Project Id*"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField size="small" disabled fullWidth name="name" label="Project Name" />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting || mutation.isLoading}>
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}

      {viewReport && projectValue !== '' && (
        <CardComponent title={'Reports List'} addUrl={isPA ? '/add-report' : `/add-report/${projectValue}`}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Radio
                checked={type === 'phase'}
                onChange={(event) => {
                  setType(event.target.value);
                  setView(false);
                  setPhase('');
                }}
                value="phase"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
                className="py-sm-0"
              />
              Phase Wise
              <Radio
                checked={type === 'general'}
                onChange={(event) => {
                  setType(event.target.value);
                }}
                value="general"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
                className="py-sm-0 pl-sm-10"
              />
              Common
            </Grid>
            {type === 'phase' && (
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth className="form-control">
                  <InputLabel id={'label'}>Select Phase*</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={phase}
                    label="Select Phase*"
                    onChange={(event) => {
                      selectPhase(event.target.value);
                    }}>
                    {phases.list.map((val) => (
                      <MenuItem value={val}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <br></br>
          {view && (
            <>
              {reports.reportList.map((report) => (
                <ReportListCard key={report.id} item={report} />
              ))}
              {reports.reportList?.length !== 0 ? (
                <JumboPagination count={count} page={page} handleChange={handleChangePage} />
              ) : (
                <NoDataPlaceholder />
              )}
            </>
          )}
        </CardComponent>
      )}
    </>
  );
};

export default Report;
