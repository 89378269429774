import React from 'react';
import axios from 'axios';

import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, updateLoadUser, userInformation } from '../../../redux/actions/Auth';
import { getAllowedPermissions, getPermissionsList } from 'app/redux/actions/Role';
import envData from 'env_config';

axios.defaults.withCredentials = true;
const JWTAuth = {
  onLogin: ({ email, password }) => {
    return (dispatch) => {
      try {
        dispatch(fetchStart());
        return axios
          .post(`${envData.API_BASE_URL_AUTH}auth/login`, {
            email,
            password,
          })
          .then((response) => {
            const permissionData = [];
            if (response.status === 200) {
              localStorage.setItem('AUTH_TOKEN', JSON.stringify(response.data.data.token));
              localStorage.setItem('User', JSON.stringify(response.data.data));
              dispatch(userInformation(response?.data?.data));
              if (response.data.data?.user?.roleId?.type === 1) {
                dispatch(getPermissionsList()).then((res) => {
                  res &&
                    res.permissionList.map((item) => {
                      return (
                        item?.permissionData &&
                        item?.permissionData.map((permission) => {
                          return permissionData.push(permission);
                        })
                      );
                    });
                  localStorage.setItem('permission', JSON.stringify(permissionData));
                  return null;
                });
              } else {
                dispatch(getAllowedPermissions(response.data.data?.user?.roleId?._id)).then((response) => {
                  if (response?.status === 200) {
                    localStorage.setItem('permission', JSON.stringify(response?.data?.data?.rolePermissions?.permissionId));
                  }
                });
              }
              return Promise.resolve(response);
            } else {
              dispatch(fetchError(response.data.responseMessage));
              return Promise.resolve(response);
            }
          })
          .catch(function (error) {
            dispatch(fetchError(error.data.responseMessage));
            return Promise.resolve(error);
          });
      } catch (error) {
        dispatch(fetchError(error.data.responseMessage));
      }
    };
  },

  onLogout: () => {
    return (dispatch) => {
      dispatch(fetchStart());
      const userData = JSON.parse(localStorage.getItem('User'));
      return axios
        .post(`${envData.API_BASE_URL_AUTH}auth/logout/` + userData?.user?._id)
        .then((response) => {
          if (response.status === 200) {
            localStorage.removeItem('AUTH_TOKEN');
            localStorage.clear();
            return Promise.resolve(response);
          } else {
            dispatch(fetchError(response.data.responseMessage));
            return Promise.resolve(response);
          }
        })
        .catch(function (error) {
          dispatch(fetchError(error.data.responseMessage));
          return Promise.resolve(error);
        });
    };
  },

  getAuthUser: (loaded = false, user) => {
    return (dispatch) => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      if (localStorage.getItem('AUTH_TOKEN')) {
        dispatch(setAuthUser(localStorage.getItem('AUTH_TOKEN')));
        dispatch(userInformation(JSON.parse(localStorage.getItem('User'))));
        dispatch(fetchSuccess());
      } else {
        dispatch(setAuthUser(user));
        dispatch(fetchSuccess());
      }
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
