import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button, Divider, IconButton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Div from '@jumbo/shared/Div';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { editPa, updatePa, addPa, getPaRegion } from 'app/redux/actions/Pa';
import Loader from '@jumbo/components/Loader';
import { Cancel } from '@mui/icons-material';
import { getUserRole } from 'app/redux/actions/User';
import RoleConstants from '@jumbo/constants/RoleConstants';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

const validationSchema = yup.object({
  companyName: yup
    .string('Enter your company name')
    .min(2, 'Company name can set of between 2 and 100 characters!')
    .max(100, 'Company name can set of between 2 and 100 characters!')
    .required('The Company field is required'),
  contactName: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 100 characters!')
    .max(100, 'Name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  address: yup
    .string('Enter your address')
    .min(5, 'Address can set of between 5 and 255 characters!')
    .max(255, 'Address can set of between 5 and 255 characters!')
    .required('The Address field is required'),
  contactMobile: yup
    .string('Enter your mobile')
    .required('The Mobile Number field is required')
    .phone('IN', true, 'Mobile Number is invalid'),
  contactEmail: yup
    .string('Enter your email')
    .email('Please enter a valid email address in the format example@domain.com')
    .required('The Email field is required'),
  note: yup
    .string('Enter your note')
    .min(5, 'Note can set of between 5 and 255 characters!')
    .max(255, 'Note can set of between 5 and 255 characters!')
    .required('The Note field is required'),
  region: yup.string('Enter your region').required('The Region field is required'),
  team: yup.array().of(
    yup.object().shape({
      name: yup
        .string('Enter your name')
        .min(2, 'Name can set of between 2 and 100 characters!')
        .max(100, 'Name can set of between 2 and 100 characters!')
        .required('The Name field is required'),
      designation: yup
        .string('Enter your designation')
        .min(2, 'Designation can set of between 2 and 100 characters!')
        .max(100, 'Designation can set of between 2 and 100 characters!')
        .required('The Designation field is required'),
      email: yup
        .string('Enter your email')
        .email('Please enter a valid email address in the format example@domain.com')
        .required('The Email field is required'),
      mobileNumber: yup
        .string('Enter your mobile')
        .required('The Mobile Number field is required')
        .phone('IN', true, 'Mobile Number is invalid'),
    }),
  ),
});

const AddEditPA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const companys = useSelector((state) => state?.pa);
  const users = useSelector((state) => state?.user);
  const [role, setRole] = useState('');

  const [pa, setPa] = useState('');
  const [team, setTeam] = useState([]);
  const [region, setRegion] = useState('');
  const [loader, setLoader] = useState(true);
  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      companyName: pa?.name ? pa?.name : '',
      address: pa?.address ? pa?.address : '',
      region: pa?.regionId ? pa?.regionId : '',
      contactName: pa?.contactName ? pa?.contactName : '',
      contactEmail: pa?.contactEmail ? pa?.contactEmail : '',
      contactMobile: pa?.contactMobile ? pa?.contactMobile : '',
      note: pa?.note ? pa?.note : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newPaData = {
        name: values.companyName.trim(),
        address: values.address.trim(),
        contactName: values.contactName.trim(),
        contactEmail: values.contactEmail.trim(),
        contactMobile: values.contactMobile.trim(),
        note: values.note.trim(),
        regionId: region,
        supervisors: values.team,
      };
      const response = await dispatch(id ? updatePa(id, newPaData) : addPa(newPaData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/pa');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    dispatch(getUserRole({ role: RoleConstants.PA }));
    dispatch(getPaRegion());
    if (id) {
      dispatch(editPa(id)).then((response) => {
        if (response.status === 200) {
          setTeam(response?.data?.data?.companySupervisors);
          setPa(response?.data?.data?.companyDetail);
          setFieldValue('companyName', response?.data?.data?.companyDetail?.name);
          setFieldValue('address', response?.data?.data?.companyDetail?.address);
          setFieldValue('region', response?.data?.data?.companyDetail?.regionId);
          setFieldValue('note', response?.data?.data?.companyDetail?.note);
          setFieldValue('contactMobile', response?.data?.data?.companyDetail?.contactMobile);
          setFieldValue('contactEmail', response?.data?.data?.companyDetail?.contactEmail);
          setFieldValue('contactName', response?.data?.data?.companyDetail?.contactName);
          setRegion(response?.data?.data?.companyDetail?.regionId);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    setRole(users?.roleList[0]?._id);
  }, [users]);

  const { setFieldValue, handleSubmit, isSubmitting, values } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {id ? 'Update PA Company' : 'Add PA Company'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="companyName" label="Company Name*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="address" label="Address*" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboSelectField
                        fullWidth
                        label={'Region*'}
                        valueKey="_id"
                        name={'region'}
                        variant="outlined"
                        labelKey={'name'}
                        data={companys?.regionList}
                        value={region}
                        onChange={(e) => {
                          setRegion(e.target.value);
                          setFieldValue('region', e.target.value);
                        }}
                        disabled={id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField fullWidth name="contactName" label="Name*" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField fullWidth name="contactEmail" label="Email*" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField fullWidth name="contactMobile" label="Mobile*" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="note" label="Note*" />
                    </Grid>
                  </Grid>
                  <br></br>
                  {id && (
                    <Typography component={'h3'} variant="h2" mb={2}>
                      Team Members
                    </Typography>
                  )}
                  {team?.map((data) => (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          <TextField disabled value={data?.name} fullWidth label="Full Name" />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField disabled value={data?.designation} fullWidth label="Designation" />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField disabled value={data?.email} fullWidth label="Email" />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField disabled value={data?.mobileNumber} fullWidth label="Mobile" />
                        </Grid>
                      </Grid>
                      <br></br>
                    </>
                  ))}
                  <FieldArray name="team">
                    {({ remove, push }) => (
                      <div>
                        <Divider className="mt-5" />
                        {values?.team?.length > 0 &&
                          values?.team.map((friend, index) => (
                            <div>
                              <br></br>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={3}>
                                  <JumboTextField fullWidth name={`team[${index}].name`} label="Full Name*" />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <JumboTextField fullWidth name={`team[${index}].designation`} label="Designation*" />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <JumboTextField fullWidth name={`team[${index}].email`} label="Email*" />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <JumboTextField fullWidth name={`team[${index}].mobileNumber`} label="Mobile*" />
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                  <IconButton size="large" onClick={() => remove(index)}>
                                    <Cancel />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        <br></br>
                        <Grid item xs={12} sm={2}>
                          <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            color="secondary"
                            sx={{ mb: 3, color: 'black' }}
                            onClick={() =>
                              push({
                                name: '',
                                designation: '',
                                email: '',
                                mobileNumber: '',
                                roleId: role,
                              })
                            }>
                            Add Team
                          </Button>
                        </Grid>
                      </div>
                    )}
                  </FieldArray>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting || mutation.isLoading}>
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => navigate('/pa')}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default AddEditPA;
