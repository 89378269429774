import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import contactsApp from './contactsApp';
import Common from './Common';
import Auth from './Auth';
import Role from './Role';
import Region from './Region';
import Pa from './Pa';
import User from './User';
import Client from './Client';
import Zone from './Zone';
import Report from './Report';
import Topic from './Topic';
import Project from './Project';
import Phase from './Phase';
import WorkSchedule from './WorkSchedule';
import Discussion from './Discussion';
import Feedback from './Feedback';
import Dashboard from './Dashboard';
import Notification from './Notification';
import Log from './Log';

const exportReducers = (history) => {
  return combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    role: Role,
    region: Region,
    pa: Pa,
    user: User,
    client: Client,
    zone: Zone,
    report: Report,
    topic: Topic,
    project: Project,
    contactsApp: contactsApp,
    phase: Phase,
    workSchedule: WorkSchedule,
    discussion: Discussion,
    feedback: Feedback,
    dashboard: Dashboard,
    notification: Notification,
    log: Log,
  });
};

export default exportReducers;
