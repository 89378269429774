import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getRegions } from 'app/redux/actions/Region';
import Loader from '@jumbo/components/Loader';
import RegionListCard from '@jumbo/components/List/RegionListCard';
import { userType } from 'app/helpers/userType';
import JumboPagination from '@jumbo/components/JumboPagination';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';

const Region = () => {
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const { enqueueSnackbar } = useSnackbar();
  const mobileView = useMediaQuery('(max-width:575px)');
  const regions = useSelector((state) => state?.region);
  const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));
  const { isMA } = userType();
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
  });
  const count = Math.ceil(regions?.totalItems / perPage);
  const _DATA = usePagination(regions?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const getPermission = () => {
    if (isMA) {
      setPermission({ createPermission: true, updatePermission: true, deletePermission: true });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
      };
      const regionPermission = permissionData?.filter((item) => {
        return item?.moduleName?.includes('Region') && item;
      });
      regionPermission?.length &&
        regionPermission.forEach(function (num) {
          if (num?.name === 'create-region') {
            permissionValue.createPermission = true;
          }
          if (num?.name === 'delete-region') {
            permissionValue.deletePermission = true;
          }
          if (num?.name === 'update-region') {
            permissionValue.updatePermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  useEffect(() => {
    getPermission();
  }, []);
  useEffect(async () => {
    let value = { page, perPage };
    const response = await dispatch(getRegions(value));
    if (response.status !== 200) {
      enqueueSnackbar(response?.data?.responseMessage, {
        variant: 'error',
      });
    }
    setLoader(false);
  }, [deleted, dispatch, enqueueSnackbar, page]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              MANAGE REGION
            </Typography>
          )}
          <CardComponent title={`List of Regions`} addUrl={'/add-region'}>
            {regions?.list?.map((data) => (
              <RegionListCard key={data._id} item={data} setDeleted={setDeleted} deleted={deleted} permission={permission} />
            ))}
            {regions?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default Region;
