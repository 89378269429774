import React from 'react';
import moment from 'moment';
import { Card, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import ControlledAccordions from '../common/Accordion';

const LogListCard = ({ item }) => {
  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions>
        <div className="wrap">
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              User Name
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.user?.name ?? '-'}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              User Type
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.user?.role?.roleName ?? '-'}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Region
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.user?.region?.name ?? '-'}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Login Date
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item.createdAt).format('DD/MM/YYYY')}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Login Time
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item.createdAt).format('hh:mm A')}
            </Typography>
          </Div>
        </div>
      </ControlledAccordions>
    </Card>
  );
};
export default LogListCard;
