import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery, Tooltip } from '@mui/material';
import ReportListCard from '@jumbo/components/List/ReportListCard';
import Div from '@jumbo/shared/Div';
import { useDispatch } from 'react-redux';
import { getOldReport } from 'app/redux/actions/Report';
import JumboPagination from '@jumbo/components/JumboPagination';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import { useNavigate, useParams } from 'react-router-dom';
import CardComponent from '@jumbo/components/common/Card';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OldReport = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phaseId, latestReportId, categoryId, projectId } = useParams();
  const perPage = envData.PER_PAGE;
  const [page, setPage] = useState(1);
  const [report, setReport] = useState();

  const count = Math.ceil(report?.totalItems ?? 0 / perPage);
  const _DATA = usePagination(report?.oldReportsList ?? [], perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(async () => {
    const obj = {
      latestReportId,
      categoryId,
      projectId,
      page,
      perPage,
    };
    if (phaseId === 'null') {
      obj.isGeneral = true;
    } else {
      obj.phaseId = phaseId;
    }
    const response = await dispatch(getOldReport(obj));
    if (response.status === 200) {
      setReport(response.data.data);
    }
  }, [phaseId, latestReportId, categoryId, projectId, page, dispatch]);

  const ExportComponent = () => (
    <Tooltip title="Back" placement="top-start" arrow>
      <Div
        style={{
          width: '40px',
          height: '40px',
          marginLeft: 'auto',
          borderRadius: '50%',
          backgroundColor: '#BDD52F',
        }}
        onClick={() => navigate('/reports')}>
        <ArrowBackIcon style={{ width: '22px', margin: '9px' }} />
      </Div>
    </Tooltip>
  );

  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          OLD REPORT
        </Typography>
      )}
      <CardComponent
        title={`Old Reports for "${report?.phaseName ?? 'Common Phase'}" in "${report?.categoryName ?? ''}"`}
        Component={ExportComponent}>
        {report?.oldReportsList?.map((val) => (
          <ReportListCard key={val.id} item={val} OldReport={true} />
        ))}
        {report?.oldReportsList?.length !== 0 ? (
          <JumboPagination count={count} page={page} handleChange={handleChangePage} />
        ) : (
          <NoDataPlaceholder />
        )}
      </CardComponent>
    </>
  );
};

export default OldReport;
