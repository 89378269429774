import React from 'react';
import { Typography, Grid, CardContent, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import JumboPasswordField from '@jumbo/components/JumboFormik/JumboPasswordField';
import { changePassword } from 'app/redux/actions/Auth';
import { AuhMethods } from 'app/services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import Div from '@jumbo/shared/Div';

const validationSchema = yup.object({
  currentPassword: yup.string('Enter your current password').required('The Current Password field is required'),
  newPassword: yup.string('Enter your new password').required('The New Password field is required'),
  confirmPassword: yup
    .string('Enter your confirm password')
    .required('The Confirm Password field is required')
    .oneOf([yup.ref('newPassword')], 'Passwords does not match'),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newPasswordData = {
        currentPassword: values.currentPassword.trim(),
        newPassword: values.newPassword.trim(),
        confirmPassword: values.confirmPassword.trim(),
      };
      const response = await dispatch(changePassword(newPasswordData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        dispatch(AuhMethods[CurrentAuthMethod].onLogout()).then((response) => {
          if (response.status === 200) {
            navigate('/login');
          }
        });
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });

  const { handleSubmit, isSubmitting } = formik;
  return (
    <>
      <Card sx={{ display: 'flex', mb: 3.5 }}>
        <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <CardContent className="px-xs-15">
            <Typography component={'h2'} variant="h1" mb={3}>
              Change Password
            </Typography>
            <FormikProvider value={formik}>
              <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <JumboPasswordField fullWidth name="currentPassword" label="Current Password" />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <JumboPasswordField fullWidth name="newPassword" label="New Password" />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <JumboPasswordField fullWidth name="confirmPassword" label="Confirm Password" />
                  </Grid>
                </Grid>
                <br></br>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      loading={isSubmitting || mutation.isLoading}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </CardContent>
        </Div>
      </Card>
    </>
  );
};

export default ChangePassword;
