import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Div from '@jumbo/shared/Div';
import { editPa, updatePa, addPa, getPaRegion } from 'app/redux/actions/Pa';
import Loader from '@jumbo/components/Loader';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

const ViewPA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const companys = useSelector((state) => state?.pa);

  const [pa, setPa] = useState('');
  const [team, setTeam] = useState([]);
  const [region, setRegion] = useState('');
  const [loader, setLoader] = useState(true);
  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      companyName: pa?.name ? pa?.name : '',
      address: pa?.address ? pa?.address : '',
      region: pa?.regionId ? pa?.regionId : '',
      contactName: pa?.contactName ? pa?.contactName : '',
      contactEmail: pa?.contactEmail ? pa?.contactEmail : '',
      contactMobile: pa?.contactMobile ? pa?.contactMobile : '',
      note: pa?.note ? pa?.note : '',
    },
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newPaData = {
        name: values.companyName.trim(),
        address: values.address.trim(),
        contactName: values.contactName.trim(),
        contactEmail: values.contactEmail.trim(),
        contactMobile: values.contactMobile.trim(),
        note: values.note.trim(),
        regionId: region,
        supervisors: values.team,
      };
      const response = await dispatch(id ? updatePa(id, newPaData) : addPa(newPaData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/pa');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    dispatch(getPaRegion());
    if (id) {
      dispatch(editPa(id)).then((response) => {
        if (response.status === 200) {
          setTeam(response?.data?.data?.companySupervisors);
          setPa(response?.data?.data?.companyDetail);
          setFieldValue('companyName', response?.data?.data?.companyDetail?.name);
          setFieldValue('address', response?.data?.data?.companyDetail?.address);
          setFieldValue('note', response?.data?.data?.companyDetail?.note);
          setFieldValue('contactMobile', response?.data?.data?.companyDetail?.contactMobile);
          setFieldValue('contactEmail', response?.data?.data?.companyDetail?.contactEmail);
          setFieldValue('contactName', response?.data?.data?.companyDetail?.contactName);
          setRegion(response?.data?.data?.companyDetail?.regionId);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);
  useEffect(() => {
    setFieldValue('region', companys?.regionList?.filter(val => val._id === region)[0]?.name || '');
  }, [companys?.regionList, region]);

  const { setFieldValue, handleSubmit } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {'Company Details'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="companyName" label="Company Name" InputProps={{
                        readOnly: true,
                      }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="address" label="Address" InputProps={{
                        readOnly: true,
                      }} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField fullWidth name='region' label="Region" InputProps={{
                        readOnly: true,
                      }} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField fullWidth name="contactName" label="Name" InputProps={{
                        readOnly: true,
                      }} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField fullWidth name="contactEmail" label="Email" InputProps={{
                        readOnly: true,
                      }} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <JumboTextField fullWidth name="contactMobile" label="Mobile" InputProps={{
                        readOnly: true,
                      }} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="note" label="Note" InputProps={{
                        readOnly: true,
                      }} />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Typography component={'h3'} variant="h2" mb={2}>
                    Team Members
                  </Typography>
                  {team?.map((data) => (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          <TextField InputProps={{
                            readOnly: true,
                          }} value={data?.name} fullWidth label="Full Name" />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField InputProps={{
                            readOnly: true,
                          }} value={data?.designation} fullWidth label="Designation" />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField InputProps={{
                            readOnly: true,
                          }} value={data?.email} fullWidth label="Email" />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField InputProps={{
                            readOnly: true,
                          }} value={data?.mobileNumber} fullWidth label="Mobile" />
                        </Grid>
                      </Grid>
                      <br></br>
                    </>
                  ))}
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default ViewPA;
