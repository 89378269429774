export const ganttChat = (taskChartData) => {
  let chatData = [
    [
      { type: 'string', label: 'Task ID' },
      { type: 'string', label: 'Task Name' },
      { type: 'date', label: 'Start Date' },
      { type: 'date', label: 'End Date' },
      { type: 'number', label: 'Duration' },
      { type: 'number', label: 'Percent Complete' },
      { type: 'string', label: 'Dependencies' },
    ],
  ];

  // eslint-disable-next-line array-callback-return
  taskChartData.map((val) => {
    chatData.push([val._id, val.name, new Date(val.startDate), new Date(val.endDate), null, val.progress, null]);
  });
  return chatData;
};
