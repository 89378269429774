export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SEND_FORGET_PASSWORD_EMAIL = 'SEND_FORGET_PASSWORD_EMAIL';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const UPDATE_LOAD_USER = 'UPDATE_LOAD_USER';
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const GET_ROLE = 'GET_ROLE';
export const GET_REGION = 'GET_REGION';
export const GET_PA = 'GET_PA';
export const GET_PA_REGION = 'GET_PA_REGION';
export const GET_USER = 'GET_USER';
export const GET_USER_REGION = 'GET_USER_REGION';
export const GET_USER_COMPANY = 'GET_USER_COMPANY';
export const GET_USER_ROLE = 'GET_USER_ROLE';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_PERMISSION_LIST = 'GET_PERMISSION_LIST';
export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_ZONE = 'GET_ZONE';
export const GET_ZONE_LIST = 'GET_ZONE_LIST';
export const GET_REPORT_CATEGORY = 'GET_REPORT_CATEGORY';
export const GET_TOPIC = 'GET_TOPIC';
export const GET_PROJECT_TOPIC = 'GET_PROJECT_TOPIC';
export const GET_PROJRCT = 'GET_PROJRCT';
export const GET_SELECT_PROJRCT = 'GET_SELECT_PROJRCT';
export const GET_COMPANY_PA = 'GET_COMPANY_PA';
export const GET_PHASE = 'GET_PHASE';
export const GET_USER_CLIENT = 'GET_USER_CLIENT';
export const GET_TASK = 'GET_TASK';
export const GET_REPORT = 'GET_REPORT';
export const GET_OLD_REPORT = 'GET_OLD_REPORT';
export const GET_TICKET = 'GET_TICKET';
export const GET_DISCUSSION_LIST = 'GET_DISCUSSION_LIST';
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const GET_PROJRCT_CSV = 'GET_PROJRCT_CSV';
export const GET_EXPORT_REPORT = 'GET_EXPORT_REPORT';
export const GET_USER_ASE = 'GET_USER_ASE';
export const GET_EXPORT_PA = 'GET_EXPORT_PA';
export const GET_PROJECT_REGION_WISE_DATE = 'GET_PROJECT_REGION_WISE_DATE';
export const GET_PROJECT_REGION_WISE_STATUS = 'GET_PROJECT_REGION_WISE_STATUS';
export const GET_PA_REGION_WISE = 'GET_PA_REGION_WISE';
export const GET_ASE_REGION_WISE = 'GET_ASE_REGION_WISE';
export const GET_PROJECT_FOR_ASE = 'GET_PROJECT_FOR_ASE';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_LOG = 'GET_LOG';
export const GET_LOG_CSV = 'GET_LOG_CSV';
export const GET_UNREAD_NOTIFICATION = 'GET_UNREAD_NOTIFICATION';
export const GET_PROJRCT_NAME = 'GET_PROJRCT_NAME';
