import React, { useState } from 'react';
import { Card, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import JumboDdMenu from '../JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { deleteCompany } from 'app/redux/actions/Pa';
import JumboConfirmDialog from '../JumboConfirmDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlledAccordions from '../common/Accordion';
import VisibilityIcon from '@mui/icons-material/Visibility';

const PartnerApplicatorListCard = ({ item, setDeleted, deleted, permission }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { updatePermission, deletePermission } = permission;
  const [deletePopup, setDeletePopup] = useState(false);

  const handleConfirmDelete = () => {
    dispatch(deleteCompany([item?._id])).then((res) => {
      if (res?.status === 200) {
        enqueueSnackbar(res.data?.responseMessage, {
          variant: 'success',
        });
        setDeleted(!deleted);
      } else {
        enqueueSnackbar(res.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };
  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'edit':
        navigate(`/edit-pa/${item?._id}`);
        break;
      case 'view':
        navigate(`/view-pa/${item?._id}`);
        break;
      case 'add-team':
        navigate(`/add-supervisor/${item?._id}`);
        break;
      case 'delete':
        setDeletePopup(true);
        break;
    }
  };

  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions expand={true} descriptionKey={'Note'} descriptionValue={item.note}>
        <div className="wrap">
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '24%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
              Company Name
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.name}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '24%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
              Region
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.regionId.name}
            </Typography>
          </Div>

          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '34%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
              Email
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.contactEmail}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '14%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography className="min-width-95" fontSize={'12px'} mb={0.5} variant={'body1'}>
              Contact No
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.contactMobile}
            </Typography>
          </Div>
          <Div sx={{ width: '5%', flexShrink: 0, px: 1 }}>
            <JumboDdMenu
              icon={<MoreHorizIcon />}
              menuItems={[
                { icon: <VisibilityIcon />, title: 'View', action: 'view' },
                { icon: <EditIcon />, title: 'Edit', action: 'edit', disabled: !updatePermission },
                { icon: <DeleteIcon />, title: 'Delete', action: 'delete', disabled: !deletePermission },
              ]}
              onClickCallback={handleItemAction}
            />
          </Div>
        </div>
      </ControlledAccordions>
      <JumboConfirmDialog
        open={deletePopup}
        title={`Confirm delete `}
        message={`Are you sure, you want to  delete this PA?`}
        onClose={() => setDeletePopup(false)}
        onConfirm={handleConfirmDelete}
      />
    </Card>
  );
};
export default PartnerApplicatorListCard;
