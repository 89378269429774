import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardContent, Dialog, Typography } from '@mui/material';
import JumboDdMenu from '../JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AssessmentOutlinedIcon from '@mui/icons-material/Assessment';
import './card.css';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import Div from '@jumbo/shared/Div';
import ControlledAccordions from '../common/Accordion';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ReportListCard = ({ item, OldReport }) => {
  const navigate = useNavigate();
  const [actions, setActions] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (OldReport) {
      setActions([
        { icon: <VisibilityIcon />, title: 'View Report', action: 'download', disabled: !item.attachment },
        { icon: <VisibilityIcon />, title: 'View Remark', action: 'remark' },
      ]);
    } else {
      setActions([
        { icon: <VisibilityIcon />, title: 'View Report', action: 'download', disabled: !item.attachment },
        { icon: <AssessmentOutlinedIcon />, title: 'Old Report', action: 'old-report' },
        { icon: <VisibilityIcon />, title: 'View Remark', action: 'remark' },
      ]);
    }
  }, [OldReport]);

  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'download':
      //   if (item.attachment) {
      //     const link = document.createElement("a");
      //     link.href = item.attachment;
      //     link.download = "1695815204544-responsive-pdf.pdf";
      //     link.click();
      // }
        document.getElementById(`download${item._id}`).click();
        break;
      case 'old-report':
        navigate(`/old-report/${item?.phaseId}/${item?._id}/${item?.category?._id}/${item?.projectId}`);
        break;
      case 'remark':
        setOpen(true);
        break;
    }
  };
  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions expand={true} descriptionKey={'Description'} descriptionValue={item.description}>
        <div className="wrap align-items-sm-top">
          <Div sx={{ display: { xs: 'none', lg: 'block' }, width: '5%', flexShrink: 0, px: 1 }}>
            {item?.attachment && (
              <Div sx={{ width: '32px', height: '32px', border: '1px solid #979494', borderRadius: '50%' }}>
                <Div sx={{ marginTop: '4px', textAlign: 'center' }}>
                  <img
                    src={`${ASSET_IMAGES}/${
                      item?.attachmentExt === 'pdf'
                        ? 'pdfImg.png'
                        : item?.attachmentExt === 'doc' || item?.attachmentExt === 'docx'
                        ? 'doc.png'
                        : item?.attachmentExt === 'xls' || item?.attachmentExt === 'xlsx' || item?.attachmentExt === 'csv'
                        ? 'xls.png'
                        : 'no-file.png'
                    }`}
                    style={{ width: '20px' }}
                    alt="doc"
                  />
                </Div>
              </Div>
            )}
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '21%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Name
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.name}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Category
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.category ? item?.category?.name : item?.categoryId?.name}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Phase Name
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.phase?.name ? item?.phase?.name : item?.phaseId?.name ? item?.phaseId?.name : 'Common'}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '15%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Uploaded On
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item.createdAt).format('DD/MM/YYYY')}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '40%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Remark
            </Typography>
            <Typography variant={'h5'} className="text-ellepsis" fontSize={14}>
              {item?.remark ?? 'No remark made'}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Remark By
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.remarkByUser ? item?.remarkByUser?.name : '-'}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '17%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Remark Date
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.remarkedAt ? moment(item.remarkedAt).format('DD/MM/YYYY') : '-'}
            </Typography>
          </Div>

          <Div className="plus-icon-15" sx={{ width: '5%', flexShrink: 0, px: 1 }}>
            <JumboDdMenu icon={<MoreHorizIcon />} menuItems={actions} onClickCallback={handleItemAction} />
          </Div>
        </div>
      </ControlledAccordions>
      <Link to={item?.attachment ?? ''} target="_blank" id={`download${item._id}`} download rel="noreferrer" hidden></Link>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <Card sx={{ display: 'flex' }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                Remark
              </Typography>
              <Typography component={'h3'} variant="h2" mb={3}>
                {item?.remark ?? 'No remark made'}
              </Typography>
            </CardContent>
          </Div>
        </Card>
      </Dialog>
    </Card>
  );
};
export default ReportListCard;
