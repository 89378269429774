import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getTopic } from 'app/redux/actions/Topic';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import TopicListCard from '@jumbo/components/List/TopicListCard';
import Loader from '@jumbo/components/Loader';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';

const Topic = () => {
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const mobileView = useMediaQuery('(max-width:575px)');
  const topics = useSelector((state) => state?.topic);
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const count = Math.ceil(topics?.totalItems / perPage);
  const _DATA = usePagination(topics?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    dispatch(getTopic({ page, perPage }));
    setLoader(false);
  }, [deleted, dispatch, page]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              MANAGE CATEGORIES FOR DISCUSSIONS
            </Typography>
          )}
          <CardComponent title={'Discussion Category List'} addUrl={'/add-topic'}>
            {topics?.list?.map((data) => (
              <TopicListCard key={data._id} item={data} setDeleted={setDeleted} deleted={deleted} />
            ))}
            {topics?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default Topic;
