import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import SupervisorLayout from 'app/layouts/SupervisorLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectProjects } from 'app/redux/actions/Project';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const Supervisor = () => {
  const [projectId, setProjectId] = useState('');
  const projects = useSelector((state) => state?.project);
  const result = JSON.parse(localStorage.getItem('User'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (projectId === '') {
      enqueueSnackbar('The ProjectId field is required', { variant: 'error' });
    } else {
      localStorage.setItem('projectId', projectId._id);
      localStorage.setItem('projectName', projectId.name);
      navigate('/');
    }
  };

  useEffect(async () => {
    await dispatch(getSelectProjects({ paId: result?.user?._id }));
  }, [dispatch]);
  return (
    <>
      <SupervisorLayout />
      <Card className="mx-0" sx={{ display: 'flex', m: 3.5 }}>
        <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <CardContent className="px-xs-15">
            <center>
              <br></br>
              <Typography variant="h1" component="h2">
                Select Project to view the dashboard
              </Typography>
              <br></br>
              <FormControl sx={{ m: 1, width: '250px' }}>
                <InputLabel id="demo-simple-select-helper-label">Select Project</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={projectId}
                  label="Select Project"
                  onChange={(e) => setProjectId(e.target.value)}>
                  {projects?.selectList?.map((val) => (
                    <MenuItem value={val} key={val._id}>
                      {val.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br></br>
              <br></br>
              <Button variant="contained" size="large" sx={{ mb: 3, width: '250px' }} onClick={handleSubmit}>
                Submit
              </Button>
            </center>
          </CardContent>
        </Div>
      </Card>
    </>
  );
};

export default Supervisor;
