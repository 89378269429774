import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';
import { useJumboTheme } from '@jumbo/hooks';

const JumboSelectField = (props) => {
  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    backgroundColor,
    label,
    ...rest
  } = props;
  const { theme } = useJumboTheme();

  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <FormControl variant={variant} size={size} fullWidth={fullWidth} required={required} className="form-control">
      {label && (
        <InputLabel error={error || helperText !== ''} id={'label-' + id || name}>
          {label}
        </InputLabel>
      )}
      <Select
        {...field}
        error={!!errorText}
        {...rest}
        name={name}
        labelId={'label-' + id || name}
        id={id || name}
        value={value}
        onChange={onChange}
        label={label ? (required ? label + ' *' : label) : null}
        displayEmpty={displayEmpty}
      >
        {placeholder && displayEmpty && <MenuItem value="">{placeholder}</MenuItem>}
        {data?.length ? (
          data.map((item, index) =>
            renderRow ? (
              renderRow(item, index)
            ) : (
              <MenuItem
                key={index}
                value={valueKey ? item[valueKey] : item}
                disabled={
                  window.location.pathname.includes('add-passenger')
                    ? item[labelKey].includes('Not available')
                      ? true
                      : false
                    : false
                }
              >
                {item[labelKey]}
              </MenuItem>
            ),
          )
        ) : (
          <MenuItem key={'1'} value={'no data'} disabled>
            No data found
          </MenuItem>
        )}
      </Select>
      {!props.disabled && <FormHelperText style={{ color: theme.palette.error.main }}>{errorText}</FormHelperText>}{' '}
    </FormControl>
  );
};

JumboSelectField.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  helperText: PropTypes.string,
  backgroundColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
};

JumboSelectField.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: 'large',
  error: false,
  helperText: '',
  valueKey: 'id',
  labelKey: 'title',
  backgroundColor: 'transparent',
  onChange: () => {},
};

export default JumboSelectField;
