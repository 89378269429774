import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import 'yup-phone';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { addUser, editUser, getUserCompany, getUserRegion, getUserRole, updateUser } from 'app/redux/actions/User';
import Loader from '@jumbo/components/Loader';
import { editPa } from 'app/redux/actions/Pa';
import RoleConstants from '@jumbo/constants/RoleConstants';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 100 characters!')
    .max(100, 'Name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  designation: yup
    .string('Enter your designation')
    .min(2, 'Designation can set of between 2 and 100 characters!')
    .max(100, 'Designation can set of between 2 and 100 characters!')
    .required('The Designation field is required'),
  region: yup.string('Enter your region').required('The Region field is required'),
  companyName: yup.string('Enter your company').required('The company field is required'),
  mobile: yup
    .string('Enter your mobile')
    .required('The Mobile Number field is required')
    .phone('IN', true, 'Mobile Number is invalid'),
  email: yup
    .string('Enter your email')
    .email('Please enter a valid email address in the format example@domain.com')
    .required('The Email field is required'),
});

const AddEditSupervisor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, companyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const users = useSelector((state) => state?.user);
  const [user, setUser] = useState('');
  const [region, setRegion] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [loader, setLoader] = useState(true);
  const [saveAdd, setSaveAdd] = useState(false);
  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      name: user.name ? user.name : '',
      email: user.email ? user.email : '',
      designation: user.designation ? user.designation : '',
      mobile: user.mobileNumber ? user.mobileNumber : '',
      companyName: user.companyId ? user.companyId : '',
      region: user.regionId ? user.regionId : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newPaData = {
        name: values.name.trim(),
        email: values.email.trim(),
        mobileNumber: values.mobile.trim(),
        regionId: region,
        roleId: role,
        companyId: company,
        designation: values.designation.trim(),
      };
      const response = await dispatch(id ? updateUser(id, newPaData) : addUser(newPaData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        if (saveAdd) {
          resetForm();
        } else {
          navigate('/supervisor-admin');
        }
        setSaveAdd(false);
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    dispatch(getUserRole({ role: RoleConstants.PA }));
    dispatch(getUserRegion());
    dispatch(getUserCompany());
    if (id) {
      dispatch(editUser(id)).then((response) => {
        if (response.status === 200) {
          setUser(response?.data?.data?.userDetail);
          setFieldValue('name', response?.data?.data?.userDetail?.name);
          setFieldValue('email', response?.data?.data?.userDetail?.email);
          setFieldValue('mobile', response?.data?.data?.userDetail?.mobileNumber);
          setFieldValue('designation', response?.data?.data?.userDetail?.designation);
          setFieldValue('region', response?.data?.data?.userDetail?.regionId);
          setFieldValue('companyName', response?.data?.data?.userDetail?.companyId);
          setRegion(response?.data?.data?.userDetail?.regionId ?? '');
          setCompany(response?.data?.data?.userDetail?.companyId ?? '');
          setRole(response?.data?.data?.userDetail?.roleId ?? '');
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    if (companyId) {
      setCompany(companyId);
      dispatch(editPa(companyId)).then((response) => {
        if (response.status === 200) {
          setRegion(response?.data?.data?.companyDetail?.regionId);
        }
      });
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, companyId]);
  useEffect(() => {
    const arr = users?.companyList?.filter((val) => val._id === company);
    if (arr.length !== 0) {
      setRegion(arr[0].regionId);
      setFieldValue('region', arr[0].regionId);
    }
  }, [company]);

  useEffect(() => {
    setRole(users?.roleList[0]?._id);
  }, [users]);
  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {id ? 'Update Application Team' : 'Add Application Team'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="name" label="Name*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="email" label="Email*" disabled={id} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="mobile" label="Mobile*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="designation" label="Designation*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboSelectField
                        fullWidth
                        label={'Company Name*'}
                        valueKey="_id"
                        name={'companyName'}
                        variant="outlined"
                        labelKey={'name'}
                        data={users?.companyList}
                        value={company}
                        disabled={companyId ? true : false}
                        onChange={(e) => {
                          setCompany(e.target.value);
                          setFieldValue('companyName', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboSelectField
                        fullWidth
                        label={'Region*'}
                        valueKey="_id"
                        name={'region'}
                        variant="outlined"
                        labelKey={'name'}
                        data={users?.regionList}
                        value={region}
                        disabled={true}
                        onChange={(e) => {
                          setRegion(e.target.value);
                          setFieldValue('region', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || mutation.isLoading}>
                        Save
                      </LoadingButton>
                    </Grid>
                    {companyId && (
                      <Grid item xs={12} sm={2}>
                        <Tooltip title="Save & add new supervisor" placement="top-start" arrow>
                          <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            onClick={() => setSaveAdd(true)}
                            loading={isSubmitting || mutation.isLoading}>
                            Save & New
                          </LoadingButton>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => navigate('/supervisor-admin')}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default AddEditSupervisor;
