import { GET_LOG, GET_LOG_CSV } from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  csvList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOG: {
      return {
        ...state,
        list: action?.payload?.userLogList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_LOG_CSV: {
      return {
        ...state,
        csvList: action?.payload?.userLogList,
      };
    }
    default: {
      return state;
    }
  }
};
