import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import Div from '@jumbo/shared/Div';
import AseListCard from '@jumbo/components/List/AseListCard';
import PartnerListCard from '@jumbo/components/List/PartnerListCard';
import ClientListCard from '@jumbo/components/List/ClientListCard';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { useMediaQuery } from '@mui/material';
import { editProject } from 'app/redux/actions/Project';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import Loader from '@jumbo/components/Loader';
import moment from 'moment';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import CardComponent from '@jumbo/components/common/Card';

const ProjectDetails = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(true);
  const [projectDetail, setProjectDetail] = useState(true);

  useEffect(async () => {
    await dispatch(editProject(localStorage.getItem('projectId'))).then(async (response) => {
      if (response.status === 200) {
        setProjectDetail(response?.data?.data?.projectDetail);
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
    setLoader(false);
  }, [dispatch]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              PROJECT DETAILS
            </Typography>
          )}
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent className="px-xs-15">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Div
                          sx={{
                            width: { xs: 'auto' },
                            flexShrink: 0,
                            px: 1,
                            flex: { xs: '1', lg: '0 1 auto' },
                          }}
                        >
                          <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                            Project Name
                          </Typography>
                          <Typography variant={'h3'} fontSize={18}>
                            {projectDetail?.name}
                          </Typography>
                        </Div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Div
                          sx={{
                            width: { xs: 'auto' },
                            flexShrink: 0,
                            px: 1,
                            flex: { xs: '1', lg: '0 1 auto' },
                          }}
                        >
                          <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                            ID
                          </Typography>
                          <Typography variant={'h3'} fontSize={18}>
                            {projectDetail?.uniqId}
                          </Typography>
                        </Div>
                      </Grid>
                    </Grid>
                    <br></br>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Div
                          sx={{
                            width: { xs: 'auto' },
                            flexShrink: 0,
                            px: 1,
                            flex: { xs: '1', lg: '0 1 auto' },
                          }}
                        >
                          <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                            Location
                          </Typography>
                          <Typography variant={'h3'} fontSize={18}>
                            {projectDetail?.city}
                          </Typography>
                        </Div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Div
                          sx={{
                            width: { xs: 'auto' },
                            flexShrink: 0,
                            px: 1,
                            flex: { xs: '1', lg: '0 1 auto' },
                          }}
                        >
                          <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                            Total Area
                          </Typography>
                          <Typography variant={'h3'} fontSize={18}>
                            {`${projectDetail?.totalArea} sqmt`}
                          </Typography>
                        </Div>
                      </Grid>
                    </Grid>
                    <br></br>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <Div
                          sx={{
                            width: { xs: 'auto' },
                            flexShrink: 0,
                            px: 1,
                            flex: { xs: '1', lg: '0 1 auto' },
                          }}
                        >
                          <Typography fontSize={'14px'} mb={0.5} variant={'body1'}>
                            Project Description
                          </Typography>
                          <Typography variant={'h3'} fontSize={18}>
                            {projectDetail?.description}
                          </Typography>
                        </Div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: 'auto' }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Div sx={{ mt: 'auto' }}>
                          <img src={`${ASSET_IMAGES}/dashboradImg.png`} style={{ width: '190px' }} alt="Pidilite" />
                        </Div>{' '}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Div
                          sx={{
                            width: { xs: 'auto' },
                            flexShrink: 0,
                            px: 1,
                            flex: { xs: '1', lg: '0 1 auto' },
                          }}
                        >
                          <Typography variant={'h3'} mb={0.5} fontSize={18}>
                            Project Completion Date
                          </Typography>
                          <Typography fontSize={'20px'} variant={'body1'}>
                            {projectDetail.endDate ? moment(projectDetail.endDate).format('DD/MM/YYYY') : ''}
                          </Typography>
                        </Div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Div>
          </Card>
          <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
            TEAMS
          </Typography>
          <CardComponent title={`Pidilite Team`}>
            {projectDetail?.aseId?.map((val) => (
              <AseListCard key={val._id} item={val} />
            ))}
            {projectDetail?.aseId?.length === 0 && <NoDataPlaceholder />}
          </CardComponent>
          <CardComponent title={`Application Team`}>
            {projectDetail?.supervisorId?.map((val) => (
              <PartnerListCard key={val._id} item={val} />
            ))}
            {projectDetail?.supervisorId?.length === 0 && <NoDataPlaceholder />}
          </CardComponent>
          <CardComponent title={`Customer Team`}>
            {projectDetail?.clientId?.map((val) => (
              <ClientListCard key={val._id} item={val} />
            ))}
            {projectDetail?.clientId?.length === 0 && <NoDataPlaceholder />}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default ProjectDetails;
