import axios from 'axios';

import { GET_REPORT_CATEGORY, GET_REPORT, GET_OLD_REPORT, GET_EXPORT_REPORT } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';
import envData from 'env_config';

export const getReportCategory = ({ page, perPage }) => {
  return (dispatch) => {
    return axios
      .get('/reports-category/list', { params: { page, perPage } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_REPORT_CATEGORY,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addReportCategory = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('reports-category', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editReportCategory = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`reports-category/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateReportCategory = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`reports-category/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deleteReportCategory = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`reports-category/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getReport = ({ isExport, phaseId, page, perPage, isGeneral, projectId }) => {
  return (dispatch) => {
    return axios
      .get('/report/list', { params: { isExport, phaseId, page, perPage, isGeneral, projectId } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_REPORT,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const uploadFile = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('/report/upload', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addReport = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('report', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getOldReport = ({ page, perPage, phaseId, latestReportId, categoryId, projectId, isGeneral }) => {
  return (dispatch) => {
    return axios
      .get('/report/list-old', {
        params: { page, perPage, phaseId, reportId: latestReportId, categoryId, projectId, isGeneral },
      })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_OLD_REPORT,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getExportReport = (projectId) => {
  return (dispatch) => {
    return axios
      .get(`/report/export/${projectId}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_EXPORT_REPORT,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const uploadFileS3signedUrl = ({ key, ContentType, folderName }) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`${envData.API_BASE_URL_AUTH}aws/signed-url`, { params: { key, ContentType, folderName } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const uploadFileS3 = ({ url, data }) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.setRequestHeader('Content-Type', data.type);
    // xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        return Promise.resolve(xhr.response);
      }
    });
    xhr.send(data);
  };
};
