import axios from 'axios';

import { GET_NOTIFICATION, GET_UNREAD_NOTIFICATION } from '@jumbo/utils/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import envData from 'env_config';

export const getNotifications = ({ type, page, perPage }) => {
  return (dispatch) => {
    return axios
      .get(`${envData.API_BASE_URL_AUTH}notification/list`, { params: { type, page, perPage } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_NOTIFICATION,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getUnReadNotifications = () => {
  return (dispatch) => {
    return axios
      .get(`${envData.API_BASE_URL_AUTH}notification/list`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_UNREAD_NOTIFICATION,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateNotificationStatus = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`${envData.API_BASE_URL_AUTH}notification/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const sendNotification = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post(`${envData.API_BASE_URL_AUTH}notification/send-manual`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
