import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import NotificationListCard from '@jumbo/components/List/NotificationListCard';
import CardComponent from '@jumbo/components/common/Card';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from 'app/redux/actions/Notification';
import Loader from '@jumbo/components/Loader';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import JumboPagination from '@jumbo/components/JumboPagination';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';

const Notification = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const notifications = useSelector((state) => state?.notification);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const count = Math.ceil(notifications?.totalItems / perPage);
  const _DATA = usePagination(notifications?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(async () => {
    await dispatch(getNotifications({ type: 'all', page, perPage }));
    setLoader(false);
  }, [dispatch, page]);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              NOTIFICATION
            </Typography>
          )}
          <CardComponent title={`List of notification`}>
            {notifications?.list?.map((data) => (
              <NotificationListCard key={data._id} item={data} />
            ))}
            {notifications?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default Notification;
