import React from 'react';
import Div from '@jumbo/shared/Div';
import { Typography, useMediaQuery } from '@mui/material';
import { ASSET_IMAGES } from '../../utils/constants/paths';

const NoDataPlaceholder = ({ children }) => {
  const mobileView = useMediaQuery('(max-width:575px)');
  if (children) return children;

  return (
    <Div sx={{ textAlign: 'center', p: 3, m: 'auto' }}>
      <img
        alt={'Not Found'}
        src={`${ASSET_IMAGES}/pages/no-data-png.png`}
        width={mobileView ? '250' : '300'}
        style={{ verticalAlign: 'middle' }}
      />
      <Typography variant={'h3'} color={'text.secondary'} mt={2}>
        No data available
      </Typography>
    </Div>
  );
};

export default NoDataPlaceholder;
