import React, { useState } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button, ButtonGroup, Card, CardContent, Dialog, Grid, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Div from '@jumbo/shared/Div';
import JumboTextField from '../JumboFormik/JumboTextField';
import { getProjects, updateProjectStatus } from 'app/redux/actions/Project';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import ControlledAccordions from '../common/Accordion';

const validationSchema = yup.object({
  reason: yup
    .string('Enter your Reason')
    .min(4, 'Reason can set of between 4 and 255 characters!')
    .max(255, 'Reason can set of between 4 and 255 characters!')
    .required('The Reason field is required'),
});

const ApproveProjectListCard = ({ item, page, perPage }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const mobileView = useMediaQuery('(max-width:575px)');

  const [open, setOpen] = useState(false);

  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      await updateStatus({ status: 3, notes: values.reason.trim() });
      setSubmitting(false);
    },
  });
  const updateStatus = async (data) => {
    const response = await dispatch(updateProjectStatus(item?._id, data));
    if (response.status === 200) {
      enqueueSnackbar(response.data?.responseMessage, {
        variant: 'success',
      });
      await dispatch(getProjects({ status: 0, page, perPage }));
    } else {
      enqueueSnackbar(response.data?.responseMessage, {
        variant: 'error',
      });
    }
  };
  const { handleSubmit, isSubmitting } = formik;
  return (
    <>
      <Card sx={{ mb: 1, boxShadow: 'none' }}>
        <ControlledAccordions expand={true} descriptionKey={'Description'} descriptionValue={item.description}>
          <Div
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              <CalendarTodayOutlinedIcon
                sx={{
                  verticalAlign: 'middle',
                  fontSize: '1rem',
                  mt: -0.25,
                  mr: 1,
                }}
              />
              {moment(item.startDate).format('DD/MM/YYYY')}
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.name}
            </Typography>
          </Div>
          <Div
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              Location
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item.city}
            </Typography>
          </Div>
          <Div
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              Pidilite Teams
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.createdBy?.name}
            </Typography>
          </Div>
          <Div
            sx={{
              width: { xs: 'auto', lg: '20%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}>
            <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
              End Date
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item.endDate).format('DD/MM/YYYY')}
            </Typography>
          </Div>
          <Div sx={{ width: `${mobileView ? '35' : '25'}%`, flexShrink: 0, px: 1 }}>
            <ButtonGroup variant="contained">
              <Button variant="contained" size="small" onClick={() => updateStatus({ status: 1 })}>
                Approve
              </Button>
              <Button
                sx={{ color: 'black' }}
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setOpen(true)}>
                Reject
              </Button>
            </ButtonGroup>
          </Div>
        </ControlledAccordions>
      </Card>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <Card sx={{ display: 'flex' }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                Rejected Reason
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="reason" label="Reason" multiline rows={3} maxRows={4} />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting || mutation.isLoading}>
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      </Dialog>
    </>
  );
};
export default ApproveProjectListCard;
