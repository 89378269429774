import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { addRole, editRole, updateRole } from '../../../redux/actions/Role';
import { useSnackbar } from 'notistack';
import Loader from '@jumbo/components/Loader';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import RoleConstants from '@jumbo/constants/RoleConstants';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 255 characters!')
    .max(255, 'Name can set of between 2 and 255 characters!')
    .required('The Name field is required'),
  description: yup
    .string('Enter your description')
    .min(5, 'Description can set of between 5 and 500 characters!')
    .max(500, 'Description can set of between 5 and 500 characters!')
    .required('The Description field is required'),
  type: yup.string('Enter your type').required('The Type field is required'),
});

const AddEditRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [role, setRole] = useState();
  const [type, setType] = useState('');
  const [loader, setLoader] = useState(true);
  const mutation = { isError: false };
  const optionData = [
    { id: RoleConstants.MA, name: 'MA' },
    { id: RoleConstants.ASE, name: 'Pidilite Team' },
    { id: RoleConstants.PA, name: 'Application Team' },
  ];
  const formik = useFormik({
    initialValues: {
      name: role?.roleName ? role?.roleName : '',
      description: role?.roleDescription ? role?.roleDescription : '',
      type: role?.type ? role?.type : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newRoleData = { roleName: values.name.trim(), roleDescription: values.description.trim(), type };
      const response = await dispatch(id ? updateRole(id, newRoleData) : addRole(newRoleData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/role');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    if (id) {
      dispatch(editRole(id)).then((response) => {
        if (response.status === 200) {
          setRole(response?.data?.data?.roleDetail);
          setFieldValue('name', response?.data?.data?.roleDetail?.roleName);
          setFieldValue('description', response?.data?.data?.roleDetail?.roleDescription);
          setFieldValue('type', response?.data?.data?.roleDetail?.type);
          setType(response?.data?.data?.roleDetail?.type);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);
  const { setFieldValue, handleSubmit, isSubmitting } = formik;

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {id ? 'Update Role' : 'Add Role'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="name" label="Role Name*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboSelectField
                        fullWidth
                        label={'Select Type*'}
                        valueKey="id"
                        name={'type'}
                        variant="outlined"
                        labelKey={'name'}
                        data={optionData}
                        value={type}
                        onChange={(e) => {
                          setType(e.target.value);
                          setFieldValue('type', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="description" label="Role Description*" multiline rows={3} maxRows={6} />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting || mutation.isLoading}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => navigate('/role')}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default AddEditRole;
