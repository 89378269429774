export const CONTENT_LAYOUT_ACTIONS = {
  SET_HEADER_OPTIONS: 'set-content-layout-header-options',
  SET_FOOTER_OPTIONS: 'set-content-layout-footer-options',
  SET_CONTENT_OPTIONS: 'set-content-layout-content-options',
  SET_ROOT_OPTIONS: 'set-content-layout-root-options',
  SET_BASE_OPTIONS: 'set-content-layout-base-options',
  SET_SIDEBAR_OPTIONS: 'set-content-layout-sidebar-options',
  SET_ALL_OPTIONS: 'set-content-layout-all-options',
  SET_MAIN_OPTIONS: 'set-content-layout-main-options',
  SET_WRAPPER_OPTIONS: 'set-content-layout-wrapper-options',
  RESET: 'reset-content-layout-options',
};
