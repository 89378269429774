import React, { useEffect, useState } from 'react';
import { Typography, Grid, FormControl, InputLabel, Select, MenuItem, useMediaQuery, Radio } from '@mui/material';
import ReportListCard from '@jumbo/components/List/ReportListCard';
import { useParams } from 'react-router-dom';
import { userType } from 'app/helpers/userType';
import { useDispatch, useSelector } from 'react-redux';
import { getPhases } from 'app/redux/actions/Phase';
import { getReport } from 'app/redux/actions/Report';
import JumboPagination from '@jumbo/components/JumboPagination';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import { getProjectName } from 'app/redux/actions/Project';
import CardComponent from '@jumbo/components/common/Card';

const ViewReport = () => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const perPage = envData.PER_PAGE;
  const { isPA } = userType();
  const phases = useSelector((state) => state?.phase);
  const reports = useSelector((state) => state?.report);
  const projects = useSelector((state) => state?.project);
  const [view, setView] = useState(false);
  const [phase, setPhase] = useState('');
  const [page, setPage] = useState(1);
  const [type, setType] = useState();
  const count = Math.ceil(reports?.totalItems / perPage);
  const _DATA = usePagination(reports.reportList, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const selectPhase = async (val) => {
    setPhase(val);
    setView(true);
    await dispatch(getReport({ phaseId: val, page, perPage }));
  };

  useEffect(() => {
    dispatch(getPhases({ projectId: projectId }));
    dispatch(getReport({ projectId: projectId, page, perPage }));
    dispatch(getProjectName(projectId));
    setView(true);
  }, [dispatch]);

  useEffect(async () => {
    await dispatch(getReport({ phaseId: phase, page, perPage }));
  }, [page]);
  useEffect(async () => {
    if (type === 'general') {
      await dispatch(getReport({ isGeneral: true, page, perPage }));
      setView(true);
    }
  }, [type]);

  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          {`REPORTS - ${projects?.projectName}`}
        </Typography>
      )}
      <CardComponent title={`Reports List`} addUrl={isPA ? '/add-report' : `/add-report/${projectId}`}>
        <Grid item xs={12} sm={12}>
          <Radio
            checked={type === 'phase'}
            onChange={(event) => {
              setType(event.target.value);
              setPhase('');
              setView(false);
            }}
            value="phase"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
            className="py-sm-0"
          />
          Phase Wise
          <Radio
            checked={type === 'general'}
            onChange={(event) => {
              setType(event.target.value);
            }}
            value="general"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
            className="py-sm-0 pl-sm-10"
          />
          Common
        </Grid>
        <br></br>
        {type === 'phase' && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth className="form-control">
                <InputLabel id={'label'}>Select Phase</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phase}
                  label="Select Phase"
                  onChange={(event) => {
                    selectPhase(event.target.value);
                  }}>
                  {phases.list.map((val) => (
                    <MenuItem value={val._id}>{val.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <br></br>
        {view && (
          <>
            {reports.reportList.map((report) => (
              <ReportListCard key={report.id} item={report} />
            ))}
            {reports.reportList?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </>
        )}
      </CardComponent>
    </>
  );
};

export default ViewReport;
