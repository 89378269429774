import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Typography, Grid } from '@mui/material';

import { getBgColorStyle, getBgImageStyle } from '@jumbo/utils';
import JumboBackdrop from '@jumbo/components/JumboBackdrop';

const JumboCardQuick = ({
  title,
  subheader,
  avatar,
  action,
  bgColor,
  bgImage,
  bgGradientDir,
  headerSx,
  footerProps,
  noWrapper,
  wrapperSx,
  backdrop,
  backdropColor,
  backdropOpacity,
  reverse,
  divider,
  sx,
  children,
  color,
  titleRight,
  ...restProps
}) => {
  const [bgStyle, setBgStyle] = React.useState({});

  React.useEffect(() => {
    if (bgImage) {
      setBgStyle(getBgImageStyle(bgImage));
    } else if (bgColor) {
      setBgStyle(getBgColorStyle(bgColor, bgGradientDir));
    } else {
      setBgStyle({});
    }
  }, [bgColor, bgImage, bgGradientDir]);

  return (
    <Card sx={{ ...bgStyle, position: 'relative', ...sx }} {...restProps}>
      <JumboBackdrop color={backdropColor} opacity={backdropOpacity} open={backdrop} />
      {(action || title || avatar) && !reverse && (
        <CardHeader
          title={
            <Typography sx={{ color: color ?? 'black', fontSize: '20px' }}>
              {titleRight ? (
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={9}>
                    {title}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    {titleRight}
                  </Grid>
                </Grid>
              ) : (
                title
              )}
            </Typography>
          }
          subheader={subheader}
          action={action}
          avatar={avatar}
          sx={{
            zIndex: 2,
            position: 'relative',
            color: '#ffffff',
            ...headerSx,
          }}
        />
      )}
      {noWrapper ? children : <CardContent sx={{ ...wrapperSx, zIndex: 2, position: 'relative' }}>{children}</CardContent>}
      {(action || title || avatar) && reverse && (
        <CardHeader
          title={title}
          subheader={subheader}
          action={action}
          avatar={avatar}
          sx={{
            zIndex: 2,
            position: 'relative',
            borderBottomColor: (theme) => theme.palette.divider,
            ...headerSx,
          }}
        />
      )}
    </Card>
  );
};

JumboCardQuick.defaultProps = {
  backdropColor: '#000000',
  backdropOpacity: '0.7',
  backdrop: false,
  reverse: false,
  divider: false,
  noWrapper: false,
  headerSx: {},
};

JumboCardQuick.propTypes = {
  avatar: PropTypes.node,
  title: PropTypes.node,
  subheader: PropTypes.node,
  action: PropTypes.node,
  reverse: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  bgImage: PropTypes.string,
  divider: PropTypes.bool,
  backdropColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  backdropOpacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backdrop: PropTypes.bool,
  noWrapper: PropTypes.bool,
};

export default JumboCardQuick;
