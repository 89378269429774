import React from 'react';
import Avatar from '@mui/material/Avatar';
import { ListItemIcon, ListItemText, ThemeProvider, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { AuhMethods } from 'app/services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import { useDispatch } from 'react-redux';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('User'));

  const onLogout = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout()).then((response) => {
      if (response.status === 200) {
        navigate('/login');
      }
    });
  };
  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={<Avatar src={''} sizes={'small'} sx={{ boxShadow: 25, cursor: 'pointer', background: '#0068b3' }} />}>
        <Div
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2.5),
          }}>
          <Avatar src={''} alt={user?.user?.name} sx={{ width: 60, height: 60, mb: 2, background: '#0068b3' }} />
          <Typography variant={'h5'}>{user?.user?.name}</Typography>
          <Typography variant={'body1'} color="text.secondary">
            {user?.user?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton onClick={() => navigate('/edit-profile')}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Update Profile" sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/change-password')}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <VpnKeyOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Change Password" sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
