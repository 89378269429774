import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { Typography } from '@mui/material';

const AreaChartCom = ({ data }) => {
  return (
    <>
      <Typography variant={'h5'} mb={3} sx={{ color: '#ffffff' }}>
        {`Portal Use (${data?.[0]?.month} - ${data?.[data.length - 1]?.month})`}
      </Typography>
      <ResponsiveContainer width="100%" height={120} className="c-bg-primary">
        <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <Tooltip
            labelStyle={{ color: 'black' }}
            labelFormatter={function (value) {
              return `Month: ${value}`;
            }}
            cursor={false}
          />
          <XAxis dataKey="month" hide />
          <Area type="monotone" dataKey="ase" stackId="1" stroke="#bdd52f" fillOpacity={1} fill="#bdd52f" />
          <Area type="monotone" dataKey="pa" stackId="1" stroke="#0068b3" fillOpacity={1} fill="#0068b3" />
          <Area type="monotone" dataKey="customer" stackId="1" stroke="#ffc305" fillOpacity={1} fill="#ffc305" />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default AreaChartCom;
