import axios from 'axios';
import { GET_PHASE } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';

export const getPhases = ({ isExport, projectId, page, perPage }) => {
  return (dispatch) => {
    return axios
      .get('/phase/list', { params: { isExport, projectId, page, perPage } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PHASE,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addPhase = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('phase', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editPhase = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`phase/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updatePhase = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`phase/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deletePhase = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`phase/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
