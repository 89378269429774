import axios from 'axios';

import { GET_PA, GET_PA_REGION, GET_EXPORT_PA } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';

export const getPa = ({ page, perPage, id, regionId }) => {
  return (dispatch) => {
    return axios
      .get('/company/list', { params: { page, perPage, id, regionId } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PA,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getPaRegion = () => {
  return (dispatch) => {
    return axios
      .get('/region/list')
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PA_REGION,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addPa = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('company', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editPa = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`company/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updatePa = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`company/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deleteCompany = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`company/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getExportPa = () => {
  return (dispatch) => {
    return axios
      .get('/company/list', { params: { isExport: true } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_EXPORT_PA,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
