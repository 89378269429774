/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Avatar, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Div from '@jumbo/shared/Div';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '15px',
    margin: '10px 0 0 0',
    borderRadius: '11Px',
    position: 'relative',
  },
  icon: {
    verticalAlign: 'middle',
    fontSize: '1rem',
    marginTop: -0.25,
    marginRight: 1,
  },
  attachIcon: {
    position: 'absolute',
    top: '10px',
    right: '5px',
    cursor: 'pointer',
  },
  avatar: {
    width: '40px',
    height: '40px',
    background: '#0068b3',
  },
  marginLeft: {
    marginLeft: 10,
  },
}));
const Massage = (props) => {
  const { data, color } = props;
  const classes = useStyles();
  return (
    <Div
      className={classes.root}
      sx={{
        background: color % 2 === 0 ? '#fef7e1' : '#f4f4f4',
      }}>
      <Avatar className={classes.avatar} alt="user" src="" />
      <Div className={classes.marginLeft}>
        <Typography variant={'h5'} mb={0.25}>
          {data?.userId?.name ?? '-'}
        </Typography>
        <Typography variant={'body1'} color={'text.secondary'} fontSize={'12px'} mb={2}>
          <AccessTimeFilledOutlinedIcon className={classes.icon} />
          {moment(data?.createdAt).format('hh:mm a')} &nbsp;
          <CalendarTodayOutlinedIcon className={classes.icon} />
          {moment(data?.createdAt).format('DD/MM/YYYY')}
        </Typography>
        {data?.attachment && (
          <>
            <Link to={data?.attachment} target="_blank" id={`downloadAttachment${color}`} download hidden></Link>
            <Tooltip title="View Attachment" placement="top-start" arrow>
              <Div
                className={classes.attachIcon}
                onClick={() => {
                  var i = document.getElementById(`downloadAttachment${color}`).click();
                }}>
                <AttachFileIcon />
              </Div>
            </Tooltip>
          </>
        )}
        <Typography className="discussion-title" variant={'body1'} color={'text.secondary'} fontSize={'14px'} mb={2}>
          {data?.comment}
        </Typography>
      </Div>
    </Div>
  );
};

export default Massage;
