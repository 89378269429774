import {
  GET_USER,
  GET_USER_COMPANY,
  GET_USER_REGION,
  GET_USER_ROLE,
  GET_COMPANY_PA,
  GET_USER_CLIENT,
  GET_USER_ASE,
} from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  regionList: [],
  companyList: [],
  roleList: [],
  paList: [],
  clientList: [],
  aseList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER: {
      return {
        ...state,
        list: action?.payload?.userList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_USER_REGION: {
      return {
        ...state,
        regionList: action?.payload?.regionList,
      };
    }
    case GET_USER_COMPANY: {
      return {
        ...state,
        companyList: action?.payload?.companyList,
      };
    }
    case GET_USER_ROLE: {
      return {
        ...state,
        roleList: action?.payload?.roleList,
      };
    }
    case GET_COMPANY_PA: {
      return {
        ...state,
        paList: action?.payload?.userList,
      };
    }
    case GET_USER_CLIENT: {
      return {
        ...state,
        clientList: action?.payload?.userList,
      };
    }
    case GET_USER_ASE: {
      return {
        ...state,
        aseList: action?.payload?.userList,
      };
    }
    default: {
      return state;
    }
  }
};
