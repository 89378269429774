import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getUser, getUserASE } from 'app/redux/actions/User';
import Loader from '@jumbo/components/Loader';
import AseListMACard from '@jumbo/components/List/AseListMACard';
import RoleConstants from '@jumbo/constants/RoleConstants';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import { userType } from 'app/helpers/userType';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';
import { ASECSvExport, ASEsCsvHeaders } from 'app/helpers/csvExport';
import Div from '@jumbo/shared/Div/Div';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const ASE = () => {
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const { enqueueSnackbar } = useSnackbar();
  const users = useSelector((state) => state?.user);
  const mobileView = useMediaQuery('(max-width:575px)');
  const { isMA } = userType();
  const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [CsvData, setCsvData] = useState([]);
  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
  });
  const count = Math.ceil(users?.totalItems / perPage);
  const _DATA = usePagination(users?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const getPermission = () => {
    if (isMA) {
      setPermission({ createPermission: true, updatePermission: true, deletePermission: true });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
      };
      const UserPermission = permissionData?.filter((item) => {
        return item?.moduleName?.includes('User') && item;
      });
      UserPermission?.length &&
        UserPermission.forEach(function (num) {
          if (num?.name === 'create-user') {
            permissionValue.createPermission = true;
          }
          if (num?.name === 'delete-user') {
            permissionValue.deletePermission = true;
          }
          if (num?.name === 'update-user') {
            permissionValue.updatePermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  let csvReport = {
    headers: ASEsCsvHeaders,
    data: CsvData,
    filename: 'ase-list.csv',
  };
  const ExportComponent = () => (
    <CSVLink {...csvReport}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}
        >
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  useEffect(() => {
    getPermission();
  }, []);
  useEffect(() => {
    setCsvData(ASECSvExport(users.aseList));
  }, [users.aseList]);
  useEffect(async () => {
    let value = { role: RoleConstants.ASE, page, perPage };
    const response = await dispatch(getUser(value));
    await dispatch(getUserASE({ role: RoleConstants.ASE }));
    if (response.status !== 200) {
      enqueueSnackbar(response?.data?.responseMessage, {
        variant: 'error',
      });
    }
    setLoader(false);
  }, [deleted, dispatch, enqueueSnackbar, page]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              MANAGE PIDILITE TEAMS
            </Typography>
          )}
          <CardComponent title={`List of Pidilite Teams`} addUrl={'/add-ase'} Component={ExportComponent}>
            {users?.list?.map((data) => (
              <AseListMACard key={data._id} item={data} setDeleted={setDeleted} deleted={deleted} permission={permission} />
            ))}
            {users?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default ASE;
