import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import JumboCardQuick from '../JumboCardQuick/JumboCardQuick';

export const DoughnutChart = ({ chartData, title }) => {
  return (
    <JumboCardQuick
      title={title}
      sx={{ textAlign: 'center' }}
      wrapperSx={{
        pt: 2,
        '&:last-child': {
          pb: 6.5,
        },
      }}
    >
      <center>
        <div className="chart-container" style={{ width: '300px' }}>
          <Doughnut data={chartData} />
        </div>
      </center>
    </JumboCardQuick>
  );
};
export default DoughnutChart;
