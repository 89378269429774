import { GET_FEEDBACK } from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  list: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEEDBACK: {
      return {
        ...state,
        list: action?.payload?.feedbackList,
      };
    }
    default: {
      return state;
    }
  }
};
