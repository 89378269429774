import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

const JumboPasswordField = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <FormControl sx={{ width: '100%' }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{props?.label}</InputLabel>
      <OutlinedInput
        fullWidth
        {...props}
        {...field}
        helperText={errorText}
        error={!!errorText}
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {errorText && <p style={{ color: '#E73145', margin: '3px 14px 0px', fontSize: '12px' }}>{errorText}</p>}
    </FormControl>
  );
};

JumboPasswordField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default JumboPasswordField;
