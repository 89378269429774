import axios from 'axios';

import { GET_FEEDBACK } from '@jumbo/utils/constants/ActionTypes';
import { fetchError } from './Common';

export const getFeedback = ({ projectId, stage }) => {
  return (dispatch) => {
    return axios
      .get('/feedback/list', { params: { projectId, stage } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_FEEDBACK,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
