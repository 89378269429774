import React, { useEffect, useState } from 'react';
import JumboThemeSidebarContext from './JumboThemeSidebarContext';
import { createTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { getAllowedPermissions, getPermissionsList } from 'app/redux/actions/Role';
import { userType } from 'app/helpers/userType';

const JumboThemeSidebar = ({ children, init }) => {
  const dispatch = useDispatch();
  const [sidebarTheme, setSidebarTheme] = React.useState(init);
  const [permissionData] = useState([]);
  const { isMA } = userType();
  const result = JSON.parse(localStorage.getItem('User'));

  useEffect(() => {
    if (result) {
      if (isMA) {
        dispatch(getPermissionsList()).then((res) => {
          res &&
            res.permissionList.map((item) => {
              return (
                item?.permissionData &&
                item?.permissionData.map((permission) => {
                  return permissionData.push(permission);
                })
              );
            });
          localStorage.setItem('permission', JSON.stringify(permissionData));
          return null;
        });
      } else {
        dispatch(getAllowedPermissions(result?.user?.roleId?._id)).then((response) => {
          if (response?.status === 200) {
            localStorage.setItem('permission', JSON.stringify(response?.data?.data?.rolePermissions?.permissionId));
          }
        });
      }
    }
  }, [result, dispatch, permissionData]);

  const themeSidebarContextValue = React.useMemo(
    () => ({
      sidebarTheme: createTheme(sidebarTheme),
      setSidebarTheme: setSidebarTheme,
    }),
    [sidebarTheme, setSidebarTheme],
  );
  return <JumboThemeSidebarContext.Provider value={themeSidebarContextValue}>{children}</JumboThemeSidebarContext.Provider>;
};

export default JumboThemeSidebar;
