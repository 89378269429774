import React, { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Typography, Grid, CardContent, Card, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import Div from '@jumbo/shared/Div';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { addRegion, editRegion, updateRegion } from 'app/redux/actions/Region';
import Loader from '@jumbo/components/Loader';
import { getZoneList } from 'app/redux/actions/Region';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 255 characters!')
    .max(255, 'Name can set of between 2 and 255 characters!')
    .required('The Name field is required'),
  code: yup.string('Enter your code').required('The Code field is required'),
  zone: yup.string('Enter your zone').required('The Zone field is required'),
});

const AddEditRegion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [region, setRegion] = useState();
  const [zone, setZone] = useState('');
  const [loader, setLoader] = useState(true);
  const regions = useSelector((state) => state?.region);

  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      name: region?.name ? region?.name : '',
      code: region?.code ? region?.code : '',
      zone: region?.zoneId ? region?.zoneId : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newRegionData = { name: values.name.trim(), code: values.code.trim().toUpperCase(), zoneId: zone };
      const response = await dispatch(id ? updateRegion(id, newRegionData) : addRegion(newRegionData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/region');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    dispatch(getZoneList());
    if (id) {
      dispatch(editRegion(id)).then((response) => {
        if (response.status === 200) {
          setRegion(response?.data?.data?.regionDetail);
          setFieldValue('name', response?.data?.data?.regionDetail?.name);
          setFieldValue('code', response?.data?.data?.regionDetail?.code);
          setFieldValue('zone', response?.data?.data?.regionDetail?.zoneId);
          setZone(response?.data?.data?.regionDetail?.zoneId);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);
  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {id ? 'Update Region' : 'Add Region'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <JumboSelectField
                        fullWidth
                        label={'Zone*'}
                        valueKey="_id"
                        name={'zone'}
                        variant="outlined"
                        labelKey={'name'}
                        data={regions?.zoneList}
                        value={zone}
                        onChange={(e) => {
                          setZone(e.target.value);
                          setFieldValue('zone', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <JumboTextField fullWidth name="name" label="Name*" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <JumboTextField fullWidth name="code" label="Code*" />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting || mutation.isLoading}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => navigate('/region')}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default AddEditRegion;
