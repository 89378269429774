import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  routesForASEOrMA,
  routesForPA,
  routesForASE,
  routesForMA,
  routesForAll,
  routesForNotAuthenticatedOnly,
} from './routes';
import useJumboRoutes from '@jumbo/hooks/useJumboRoutes';
import { AuhMethods } from './services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import { userType } from './helpers/userType';

const AppRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let routes = [];
  const { isMA, isASE, isPA } = userType();
  const common = useSelector((state) => state?.common);

  if (isMA) {
    routes = [...routes, ...routesForMA, ...routesForASEOrMA, ...routesForAll, ...routesForNotAuthenticatedOnly];
  } else if (isASE) {
    routes = [...routes, ...routesForASE, ...routesForASEOrMA, ...routesForAll, ...routesForNotAuthenticatedOnly];
  } else if (isPA) {
    routes = [...routes, ...routesForPA, ...routesForAll, ...routesForNotAuthenticatedOnly];
  } else {
    routes = [...routes, ...routesForNotAuthenticatedOnly];
  }
  useEffect(() => {
    if (common.error === 'Token Expired.' || common.error === 'Token not passed.') {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout()).then((response) => {
        if (response.status === 200) {
          navigate('/login');
        }
      });
    }
  }, [common, dispatch, navigate]);
  const appRoutes = useJumboRoutes(routes);

  return <React.Fragment>{appRoutes}</React.Fragment>;
};
export default AppRoutes;
