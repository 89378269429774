import axios from 'axios';

import { GET_REGION, GET_ZONE_LIST } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';

export const getRegions = ({ page, perPage }) => {
  return (dispatch) => {
    return axios
      .get('/region/list', { params: { page, perPage } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_REGION,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addRegion = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('region', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editRegion = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`region/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateRegion = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`region/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deleteRegion = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`region/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getZoneList = () => {
  return (dispatch) => {
    return axios
      .get('/zone/list')
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_ZONE_LIST,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
