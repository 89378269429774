import { GET_ZONE } from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  zoneList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ZONE: {
      return {
        ...state,
        list: action?.payload?.zoneList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    default: {
      return state;
    }
  }
};
