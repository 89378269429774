import React from 'react';
import { Card, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import ControlledAccordions from '../common/Accordion';
import moment from 'moment';

const NotificationListCard = ({ item }) => {
  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions>
        <div className="wrap">
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Title
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.title}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '25%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Date
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {moment(item.createdAt).format('DD/MM/YYYY')}{' '}
            </Typography>
          </Div>
          <Div
            className="plus-icon-15"
            sx={{
              width: { xs: 'auto', lg: '55%' },
              flexShrink: 0,
              px: 1,
              flex: { xs: '1', lg: '0 1 auto' },
            }}
          >
            <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
              Description
            </Typography>
            <Typography variant={'h5'} fontSize={14}>
              {item?.body}
            </Typography>
          </Div>
        </div>
      </ControlledAccordions>
    </Card>
  );
};
export default NotificationListCard;
