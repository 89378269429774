import React, { useState } from 'react';
import { Card, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import JumboDdMenu from '../JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import JumboConfirmDialog from '../JumboConfirmDialog';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { deleteZone } from 'app/redux/actions/Zone';
import ControlledAccordions from '../common/Accordion';

const ZoneListCard = ({ item, setDeleted, deleted }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePopup, setDeletePopup] = useState(false);

  const handleConfirmDelete = () => {
    dispatch(deleteZone([item?._id])).then((res) => {
      if (res?.status === 200) {
        enqueueSnackbar(res.data?.responseMessage, {
          variant: 'success',
        });
        setDeleted(!deleted);
      } else {
        enqueueSnackbar(res.data?.responseMessage, {
          variant: 'error',
        });
      }
    });
  };
  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'edit':
        navigate(`/edit-zone/${item?._id}`);
        break;
      case 'delete':
        setDeletePopup(true);
        break;
    }
  };
  return (
    <Card sx={{ mb: 1, boxShadow: 'none' }}>
      <ControlledAccordions>
        <Div
          sx={{
            width: { xs: 'auto', lg: '90%' },
            flexShrink: 0,
            px: 1,
            flex: { xs: '1', lg: '0 1 auto' },
          }}
        >
          <Typography fontSize={'12px'} mb={0.5} variant={'body1'}>
            Name
          </Typography>
          <Typography variant={'h5'} fontSize={14}>
            {item.name}
          </Typography>
        </Div>
        <Div sx={{ width: '5%', flexShrink: 0, px: 1 }}>
          <JumboDdMenu
            icon={<MoreHorizIcon />}
            menuItems={[
              { icon: <EditIcon />, title: 'Edit', action: 'edit' },
              { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
            ]}
            onClickCallback={handleItemAction}
          />
        </Div>
      </ControlledAccordions>
      <JumboConfirmDialog
        open={deletePopup}
        title={`Confirm delete `}
        message={`Are you sure, you want to  delete this zone?`}
        onClose={() => setDeletePopup(false)}
        onConfirm={handleConfirmDelete}
      />
    </Card>
  );
};
/* Todo item prop define */
export default ZoneListCard;
