import React from 'react';
import { Card, Rating, Stack, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import ControlledAccordions from '../common/Accordion';
import { userType } from 'app/helpers/userType';
import moment from 'moment';

const FeedbackListCard = ({ item }) => {
  const { isPA } = userType();
  return (
    <>
      <Card sx={{ mb: 1, boxShadow: 'none' }}>
        <ControlledAccordions
          expand={item?.answer?.remark ? true : false}
          descriptionKey={'Remark'}
          descriptionValue={item?.answer?.remark}>
          <div className="wrap">
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '14%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Category
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {item?.question?.title}
              </Typography>
            </Div>
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: isPA ? '65%' : '40%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Services
              </Typography>
              <Typography variant={'h5'} fontSize={14}>
                {item?.question?.question}
              </Typography>
            </Div>
            {!isPA && (
              <>
                <Div
                  className="plus-icon-15"
                  sx={{
                    width: { xs: 'auto', lg: '14%' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}>
                  <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                    Name
                  </Typography>
                  <Typography variant={'h5'} fontSize={14}>
                    {item?.answer?.clientId?.name ?? '-'}
                  </Typography>
                </Div>
                <Div
                  className="plus-icon-15"
                  sx={{
                    width: { xs: 'auto', lg: '20%' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}>
                  <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                    Email
                  </Typography>
                  <Typography variant={'h5'} fontSize={14}>
                    {item?.answer?.clientId?.email ?? '-'}
                  </Typography>
                </Div>
                <Div
                  className="plus-icon-15"
                  sx={{
                    width: { xs: 'auto', lg: '15%' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}>
                  <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                    Uploaded On
                  </Typography>
                  <Typography variant={'h5'} fontSize={14}>
                    {moment(item.createdAt).format('DD/MM/YYYY')}
                  </Typography>
                </Div>
              </>
            )}
            <Div
              className="plus-icon-15"
              sx={{
                width: { xs: 'auto', lg: '15%' },
                flexShrink: 0,
                px: 1,
                flex: { xs: '1', lg: '0 1 auto' },
              }}>
              <Typography fontSize={'12px'} className="min-width-95" mb={0.5} variant={'body1'}>
                Ratings
              </Typography>
              <Typography fontSize={'12px'} variant={'body1'} color="#ffbb12">
                <Stack spacing={1}>
                  <Rating value={item?.answer?.rating} precision={0.5} readOnly />
                </Stack>
              </Typography>
            </Div>
          </div>
        </ControlledAccordions>
      </Card>
    </>
  );
};
export default FeedbackListCard;
