import {
  GET_ASE_REGION_WISE,
  GET_PA_REGION_WISE,
  GET_PROJECT_FOR_ASE,
  GET_PROJECT_REGION_WISE_DATE,
  GET_PROJECT_REGION_WISE_STATUS,
} from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  ASERegionWiseList: [],
  PARegionWiseList: [],
  projectRegionWiseDateList: [],
  projectRegionWiseStatusList: [],
  projectListForASE: [],
  startDate:null,
  endDate:null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASE_REGION_WISE: {
      return {
        ...state,
        ASERegionWiseList: action?.payload?.graphData,
      };
    }
    case GET_PA_REGION_WISE: {
      return {
        ...state,
        PARegionWiseList: action?.payload?.graphData,
      };
    }
    case GET_PROJECT_REGION_WISE_DATE: {
      return {
        ...state,
        projectRegionWiseDateList: action?.payload?.graphData,
        startDate:action?.payload?.startDate,
        endDate:action?.payload?.endDate,
      };
    }
    case GET_PROJECT_REGION_WISE_STATUS: {
      return {
        ...state,
        projectRegionWiseStatusList: action?.payload?.graphData,
      };
    }
    case GET_PROJECT_FOR_ASE: {
      return {
        ...state,
        projectListForASE: action?.payload?.projectList,
      };
    }
    default: {
      return state;
    }
  }
};
