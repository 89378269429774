import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const JumboTextField = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return <TextField {...props} {...field} helperText={errorText} error={!!errorText} />;
};

JumboTextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default JumboTextField;
