import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Card, CardContent, Grid, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { CSVLink } from 'react-csv';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';
import LogListCard from '@jumbo/components/List/LogListCard';
import Loader from '@jumbo/components/Loader';
import Div from '@jumbo/shared/Div/Div';
import { getUserRegion } from 'app/redux/actions/User';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import RoleConstants from '@jumbo/constants/RoleConstants';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import moment from 'moment';
import { getLogs, getLogsCsv } from 'app/redux/actions/Log';
import { logCSvExport, logCsvHeaders } from 'app/helpers/csvExport';

const Log = () => {
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const mobileView = useMediaQuery('(max-width:575px)');
  const users = useSelector((state) => state?.user);
  const logData = useSelector((state) => state?.log);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [region, setRegion] = useState('');
  const [date, setDate] = useState(null);
  const [type, setType] = useState('');
  const [CsvData, setCsvData] = useState([]);
  const count = Math.ceil(logData?.totalItems / perPage);
  const _DATA = usePagination(logData?.list, perPage);
  const mutation = { isError: false };
  const optionData = [
    { id: RoleConstants.ASE, name: 'Pidilite Teams' },
    { id: RoleConstants.PA, name: 'Application Team' },
    { id: RoleConstants.CLIENT, name: 'Customer Team' },
  ];
  const formik = useFormik({
    initialValues: {
      regionId: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let data = {
        regionId: region === '' || !region ? null : region,
        userType: type === '' ? null : type,
        loginDate: date ? moment(new Date(date)).format('YYYY-MM-DD') : null,
        page: page,
        perPage: perPage,
      };
      await dispatch(getLogs(data));
      await dispatch(getLogsCsv(data));
      setSubmitting(false);
    },
  });
  let csvReport = {
    headers: logCsvHeaders,
    data: CsvData,
    filename: 'user-log-list.csv',
  };
  const ExportComponent = () => (
    <CSVLink {...csvReport}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}
        >
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  useEffect(() => {
    setCsvData(logCSvExport(logData.csvList));
  }, [logData.csvList]);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    dispatch(getLogs({ page, perPage }));
    dispatch(getUserRegion());
    dispatch(getLogsCsv({}));
    setLoader(false);
  }, [dispatch, page]);
  const { handleSubmit, isSubmitting } = formik;

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              LOGS
            </Typography>
          )}
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography component={'h2'} variant="h1" mb={3}>
                  Search
                </Typography>
                <FormikProvider value={formik}>
                  <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <Autocomplete
                          id="region-select-demo"
                          size="small"
                          name="region"
                          options={users?.regionList}
                          autoHighlight
                          onChange={(e, values) => setRegion(values?._id)}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option) => option?._id === region}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              label="Region"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <JumboSelectField
                          fullWidth
                          label={'User Type'}
                          valueKey="id"
                          name={'status'}
                          variant="outlined"
                          labelKey={'name'}
                          size="small"
                          data={optionData}
                          value={type}
                          onChange={(e) => {
                            setType(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            fullWidth
                            label="Date"
                            value={date}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setDate(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx={{ mb: 3 }}
                          loading={isSubmitting || mutation.isLoading}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Div>
          </Card>
          <br></br>
          <CardComponent title={'Logs List'} Component={ExportComponent}>
            {logData?.list?.map((data) => (
              <LogListCard key={data._id} item={data} />
            ))}
            {logData?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default Log;
