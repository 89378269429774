import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar';
import AuthUserDropdown from '../../../../shared/widgets/AuthUserDropdown';
import NotificationsDropdown from '../../../../shared/NotificationsDropdown';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Logo from '../../../../shared/Logo';
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from '@jumbo/utils/constants';
import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme';
import { useLocation } from 'react-router-dom';
import { userType } from 'app/helpers/userType';
import { useSelector } from 'react-redux';

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const location = useLocation();
  const { headerTheme } = useJumboHeaderTheme();
  const projects = useSelector((state) => state?.project);
  const mobileView = useMediaQuery('(max-width:575px)');
  const { isMA, isPA } = userType();
  const [title, SetTitle] = useState('');
  useEffect(() => {
    if (mobileView) {
      setSidebarOptions({ open: false });
    }
    if (location.pathname === '/') {
      isMA ? SetTitle('DASHBOARD - (MASTER ADMIN)') : isPA ? SetTitle('DASHBOARD') : SetTitle('DASHBOARD - (Pidilite Team)');
    } else if (location.pathname === '/project') {
      isMA ? SetTitle('ALL PROJECTS') : isPA ? SetTitle('PROJECT DETAILS') : SetTitle('MY PROJECTS');
    } else if (location.pathname === '/approve-project') {
      SetTitle('APPROVE PROJECTS');
    } else if (location.pathname === '/archive-project') {
      SetTitle('ARCHIVE PROJECTS');
    } else if (location.pathname === '/project-delete') {
      SetTitle('DELETED PROJECTS');
    } else if (location.pathname === '/project-reject') {
      SetTitle('REJECTED PROJECTS');
    } else if (location.pathname === '/category') {
      SetTitle('MANAGE REPORT CATEGORIES');
    } else if (location.pathname === '/reports') {
      SetTitle('ALL REPORTS');
    } else if (location.pathname === '/discussion-topic') {
      SetTitle('MANAGE CATEGORIES FOR DISCUSSIONS');
    } else if (location.pathname === '/view-thread') {
      SetTitle('DISCUSSION FORUM');
    } else if (location.pathname.includes('/view-thread/')) {
      isPA ? SetTitle('DISCUSSION FORUM') : SetTitle(`DISCUSSION FORUM - ${projects?.projectName}`);
    } else if (location.pathname === '/ase') {
      SetTitle('MANAGE PIDILITE TEAMS');
    } else if (location.pathname === '/supervisor-admin') {
      SetTitle('MANAGE APPLICATION TEAMS');
    } else if (location.pathname === '/client') {
      SetTitle('CUSTOMERS TEAMS');
    } else if (location.pathname === '/feedback') {
      SetTitle('VIEW FEEDBACK');
    } else if (location.pathname === '/pa') {
      SetTitle('PA COMPANY');
    } else if (location.pathname === '/zone') {
      SetTitle('MANAGE ZONES');
    } else if (location.pathname === '/region') {
      SetTitle('MANAGE REGIONS');
    } else if (location.pathname === '/role') {
      SetTitle('ROLE & PERMISSION');
    } else if (location.pathname === '/phases') {
      SetTitle('PHASES');
    } else if (location.pathname.includes('/work-schedule')) {
      isPA ? SetTitle('WORK SCHEDULE') : SetTitle(`WORK SCHEDULE - ${projects?.projectName}`);
    } else if (location.pathname.includes('/view-report')) {
      SetTitle(`REPORTS - ${projects?.projectName}`);
    } else if (location.pathname.includes('/old-report')) {
      SetTitle('REPORTS');
    } else if (location.pathname === '/discussion') {
      SetTitle('DISCUSSION FORUM');
    } else if (location.pathname === '/logs') {
      SetTitle('LOGS');
    } else if (location.pathname.includes('/notification')) {
      SetTitle('NOTIFICATIONS');
    } else if (location.pathname.includes('/discussion-view')) {
      SetTitle('VIEW DISCUSSION THREAD');
    } else if (location.pathname.includes('/view-project')) {
      SetTitle('VIEW PROJECT');
    } else {
      SetTitle('');
    }
  }, [location.pathname, projects?.projectName]);

  return (
    <React.Fragment>
      {!mobileView &&
        (sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
          sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
          (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)) && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
              mr: 3,
            }}
            onClick={() => setSidebarOptions({ open: !sidebarOptions.open, hide: sidebarOptions.open })}>
            {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        )}
      {mobileView && <Logo mode={headerTheme.type ?? 'light'} />}
      {!mobileView && (
        <Typography variant={'h1'} sx={{ fontWeight: '500' }}>
          {isPA ? (title === '' ? title : `${title} - ${localStorage.getItem('projectName')}`) : title}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" spacing={1.25} sx={{ ml: 'auto' }}>
        <NotificationsDropdown />
        <AuthUserDropdown />
        {mobileView && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
              mr: 3,
            }}
            onClick={() => setSidebarOptions({ open: !sidebarOptions.open, hide: sidebarOptions.open })}>
            {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default Header;
