import React, { useEffect, useState } from 'react';
import { Typography, Tooltip, useMediaQuery } from '@mui/material';
import RejectProjectListCard from '@jumbo/components/List/RejectProjectListCard';
import Div from '@jumbo/shared/Div';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, getProjectsCsv } from 'app/redux/actions/Project';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import Loader from '@jumbo/components/Loader';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import { projectCSvExport, projectCsvHeaders } from 'app/helpers/csvExport';
import { CSVLink } from 'react-csv';
import CardComponent from '@jumbo/components/common/Card';

const RejectProject = ({ history }) => {
  const mobileView = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const projects = useSelector((state) => state?.project);
  const perPage = envData.PER_PAGE;
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [CsvData, setCsvData] = useState([]);
  const count = Math.ceil(projects?.totalItems / perPage);
  const _DATA = usePagination(projects?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  let csvReport = {
    headers: projectCsvHeaders,
    data: CsvData,
    filename: 'rejected-project-list.csv',
  };
  const ExportComponent = () => (
    <CSVLink {...csvReport}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}
        >
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  useEffect(() => {
    setCsvData(projectCSvExport(projects.csvList));
  }, [projects.csvList]);
  useEffect(async () => {
    await dispatch(getProjects({ status: 3, page, perPage }));
    await dispatch(getProjectsCsv({ status: 3 }));
    setLoader(false);
  }, [dispatch, page]);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              REJECTED PROJECTS
            </Typography>
          )}
          <CardComponent title={'Rejected List'} Component={ExportComponent}>
            {projects.list.map((project) => (
              <RejectProjectListCard key={project._id} item={project} />
            ))}
            {projects?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default RejectProject;
