/* eslint-disable array-callback-return */
import moment from 'moment';

export const projectCsvHeaders = [
  { label: 'Project Id', key: 'id' },
  { label: 'Project Name', key: 'name' },
  { label: 'Project Created At', key: 'createdAt' },
  { label: 'Start Date', key: 'startDate' },
  { label: 'End Date', key: 'endDate' },
  { label: 'Area (Sqmt)', key: 'area' },
  { label: 'Progress (%)', key: 'progress' },
  { label: 'Status', key: 'status' },
  { label: 'Company', key: 'companyId' },
  { label: 'Region', key: 'regionId' },
  { label: 'Location', key: 'city' },
  { label: 'Customer Teams', key: 'clientId' },
  { label: 'Application Teams', key: 'supervisorId' },
  { label: 'Pidilite Teams', key: 'aseId' },
  { label: 'Description', key: 'description' },
];
export const projectCSvExport = (projectData) => {
  var arr = [];
  projectData.map((item) => {
    const clients = item?.clientId.reduce((val, curr) => `${val}${curr.name}, `, '');
    const supervisors = item?.supervisorId.reduce((val, curr) => `${val}${curr.name}, `, '');
    const ases = item?.aseId.reduce((val, curr) => `${val}${curr.name}, `, '');
    var obj = {
      id: item.uniqId ?? '-',
      name: item.name,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      startDate: moment(item.startDate).format('DD/MM/YYYY'),
      endDate: moment(item.endDate).format('DD/MM/YYYY'),
      area: item.totalArea,
      progress: item.progress,
      status:
        item.status === 0
          ? 'Created'
          : item.status === 1
          ? 'Approved'
          : item.status === 2
          ? 'On Going'
          : item.status === 3
          ? 'Rejected'
          : item.status === 4
          ? 'On Hold'
          : item.status === 5
          ? 'Near Completion'
          : item.status === 6
          ? 'Archived'
          : 'Deleted',
      companyId: item?.companyId?.name ?? '-',
      regionId: item?.regionId?.name,
      city: item.city,
      clientId: clients,
      supervisorId: supervisors,
      aseId: ases,
      description: item.description,
    };
    arr.push(obj);
  });
  return arr;
};

export const phasesCsvHeaders = [
  { label: 'Project ID', key: 'projectID' },
  { label: 'Project Name', key: 'projectName' },
  { label: 'Name', key: 'name' },
  { label: 'Total Area (Sqmt)', key: 'areaToBeCovered' },
  { label: 'Area Completed (Sqmt)', key: 'areaToBeCompleted' },
  { label: 'Progress (%)', key: 'progress' },
  { label: 'Created At', key: 'createdAt' },
  { label: 'Description', key: 'description' },
];
export const phaseCSvExport = (projectData) => {
  var arr = [];
  projectData.map((item) => {
    var obj = {
      projectID: item?.projectInfo?.uniqId ?? '-',
      projectName: item?.projectInfo?.name,
      name: item.name,
      areaToBeCovered: item.areaToBeCovered,
      areaToBeCompleted: item.areaToBeCompleted,
      progress: item.progress,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      description: item.description,
    };
    arr.push(obj);
  });
  return arr;
};

export const reportsCsvHeaders = [
  { label: 'Project ID', key: 'projectID' },
  { label: 'Project Name', key: 'projectName' },
  { label: 'Phase', key: 'phase' },
  { label: 'Report Name', key: 'name' },
  { label: 'Category', key: 'category' },
  { label: 'Uploaded At', key: 'createdAt' },
  { label: 'Remark', key: 'remark' },
  { label: 'Remark By', key: 'remarkBy' },
  { label: 'Remark Date', key: 'remarkedAt' },
  { label: 'Description', key: 'description' },
];
export const reportCSvExport = (projectData) => {
  var arr = [];
  projectData.map((item) => {
    var obj = {
      projectID: item?.projectInfo?.uniqId ?? '-',
      projectName: item?.projectInfo?.name,
      phase: item?.phase?.name,
      name: item?.name,
      category: item?.category?.name,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      remark: item.remark ?? '-',
      remarkBy: item?.remarkByUser ? item?.remarkByUser?.name : '-',
      remarkedAt: item.remarkedAt ? moment(item.remarkedAt).format('DD/MM/YYYY') : '-',
      description: item.description,
    };
    arr.push(obj);
  });
  return arr;
};

export const clientsCsvHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Mobile Number', key: 'mobileNumber' },
  { label: 'Designation', key: 'designation' },
  { label: 'Created At', key: 'createdAt' },
];
export const clientCSvExport = (projectData) => {
  var arr = [];
  projectData.map((item) => {
    var obj = {
      name: item?.name,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
      designation: item?.designation,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
    };
    arr.push(obj);
  });
  return arr;
};

export const PAsCsvHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Mobile Number', key: 'mobileNumber' },
  { label: 'Designation', key: 'designation' },
  { label: 'Region', key: 'region' },
  { label: 'Company', key: 'company' },
  { label: 'Created At', key: 'createdAt' },
];
export const PACSvExport = (projectData) => {
  var arr = [];
  projectData.map((item) => {
    var obj = {
      name: item?.name,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
      designation: item?.designation,
      region: item?.region?.name,
      company: item?.company?.name,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
    };
    arr.push(obj);
  });
  return arr;
};

export const ASEsCsvHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Mobile Number', key: 'mobileNumber' },
  { label: 'Region', key: 'region' },
  { label: 'Created At', key: 'createdAt' },
];
export const ASECSvExport = (projectData) => {
  var arr = [];
  projectData.map((item) => {
    var obj = {
      name: item?.name,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
      region: item?.region?.name,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
    };
    arr.push(obj);
  });
  return arr;
};

export const companysCsvHeaders = [
  { label: 'Company Name', key: 'name' },
  { label: 'Region', key: 'region' },
  { label: 'Contact Name', key: 'contactName' },
  { label: 'Contact Email', key: 'contactEmail' },
  { label: 'Contact Mobile Number', key: 'contactMobile' },
  { label: 'Address', key: 'address' },
  { label: 'Created At', key: 'createdAt' },
  { label: 'Note', key: 'note' },
];
export const companyCSvExport = (projectData) => {
  var arr = [];
  projectData.map((item) => {
    var obj = {
      name: item?.name,
      region: item?.regionId?.name,
      contactName: item?.contactName,
      contactEmail: item?.contactEmail,
      contactMobile: item?.contactMobile,
      address: item?.address,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      note: item?.note,
    };
    arr.push(obj);
  });
  return arr;
};

export const logCsvHeaders = [
  { label: 'User Name', key: 'name' },
  { label: 'User Type', key: 'role' },
  { label: 'Region', key: 'region' },
  { label: 'Login Date', key: 'date' },
  { label: 'Login Time', key: 'time' },
];

export const logCSvExport = (logData) => {
  var arr = [];
  logData.map((item) => {
    var obj = {
      name: item?.user?.name ?? '-',
      role: item?.user?.role?.roleName ?? '-',
      region: item?.user?.region?.name ?? '-',
      date: moment(item.createdAt).format('DD/MM/YYYY'),
      time: moment(item.createdAt).format('hh:mm A'),
    };
    arr.push(obj);
  });
  return arr;
};
