import axios from 'axios';
import { fetchSuccess, fetchError } from './Common';
import {
  GET_ASE_REGION_WISE,
  GET_PA_REGION_WISE,
  GET_PROJECT_REGION_WISE_DATE,
  GET_PROJECT_REGION_WISE_STATUS,
  GET_PROJECT_FOR_ASE,
} from '@jumbo/utils/constants/ActionTypes';

export const getProjectDetails = () => {
  return (dispatch) => {
    return axios
      .get('/dashboard/projects-details')
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getProjectRegionWiseDate = ({ startDate, endDate }) => {
  return (dispatch) => {
    return axios
      .get('/dashboard/project-count-region-wise', { params: { startDate, endDate } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PROJECT_REGION_WISE_DATE,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getProjectRegionWiseStatus = ({ status, recentlyAdded }) => {
  return (dispatch) => {
    return axios
      .get('/dashboard/project-count-region-wise', { params: { status, recentlyAdded } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PROJECT_REGION_WISE_STATUS,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getPARegionWise = ({ roleType }) => {
  return (dispatch) => {
    return axios
      .get('/dashboard/ase-pa-count-region-wise', { params: { roleType } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PA_REGION_WISE,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getASERegionWise = ({ roleType }) => {
  return (dispatch) => {
    return axios
      .get('/dashboard/ase-pa-count-region-wise', { params: { roleType } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_ASE_REGION_WISE,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getUsersGraph = () => {
  return (dispatch) => {
    return axios
      .get('/dashboard/user-graph')
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getProjectDetailsForASE = () => {
  return (dispatch) => {
    return axios
      .get('/dashboard/ase-projects-details')
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getProjectListForASE = ({ status, recentlyAdded }) => {
  return (dispatch) => {
    return axios
      .get('/dashboard/project-list-for-ase', { params: { status, recentlyAdded } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PROJECT_FOR_ASE,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getPAProjectDetails = (id) => {
  return (dispatch) => {
    return axios
      .get(`/dashboard/pa/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
