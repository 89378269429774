import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  IconButton,
  Divider,
} from '@mui/material';
import * as yup from 'yup';
import moment from 'moment';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import { Cancel } from '@mui/icons-material';
import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { getPa } from 'app/redux/actions/Pa';
import { addProject, editProject, updateProject } from 'app/redux/actions/Project';
import { getCompanyPA, getUser, getUserClient, getUserRegion, getUserRole } from 'app/redux/actions/User';
import RoleConstants from '@jumbo/constants/RoleConstants';
import { userType } from 'app/helpers/userType';
import Loader from '@jumbo/components/Loader';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 100 characters!')
    .max(100, 'Name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  city: yup.string('Enter your city / location').required('The City / Location field is required'),
  totalArea: yup.number('Enter valid number').required('The Total Area field is required'),
  description: yup
    .string('Enter your description')
    .min(4, 'Description can set of between 4 and 255 characters!')
    .max(255, 'Description can set of between 4 and 255 characters!')
    .required('The Description field is required'),
  clientTeam: yup.array().of(
    yup.object().shape({
      name: yup
        .string('Enter your name')
        .min(2, 'name can set of between 2 and 100 characters!')
        .max(100, 'name can set of between 2 and 100 characters!')
        .required('The Name field is required'),
      email: yup
        .string()
        .email('Please enter a valid email address in the format example@domain.com')
        .required('The Email field is required'),
      mobileNumber: yup
        .string('Enter your mobile')
        .required('The Mobile Number field is required')
        .phone('IN', true, 'Mobile Number is invalid'),
      designation: yup
        .string('Enter your designation')
        .min(2, 'Designation can set of between 2 and 100 characters!')
        .max(100, 'Designation can set of between 2 and 100 characters!')
        .required('The Designation field is required'),
    }),
  ),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name, assignASE, theme) {
  return {
    fontWeight: assignASE.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const AddEditProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMA, isASE } = userType();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { id } = useParams();
  const users = useSelector((state) => state?.user);
  const result = JSON.parse(localStorage.getItem('User'));
  const [loader, setLoader] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [company, setCompany] = useState('');
  const [region, setRegion] = useState(isMA ? "" : result?.user?.regionId);
  const [assignASE, setAssignASE] = useState([]);
  const [assignPA, setAssignPA] = useState([]);
  const [PAUser, setPAUser] = useState([]);
  const [ASEUser, setASEUser] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [clientData, setClientData] = useState([]);

  const [assignClient, setAssignClient] = useState([]);
  const [projectDetails, setProjectDetails] = useState();
  const [endDateEdit, setEndDateEdit] = useState(false);
  const [status, setStatus] = useState('');
  const [projectStatus, setProjectStatus] = useState();

  const statusArr = isMA
    ? [
      { id: 4, name: 'On Hold' },
      { id: 6, name: 'Archived' },
    ]
    : [{ id: 4, name: 'On Hold' }];
  const mutation = { isError: false };

  const errorMsg = (error) => {
    enqueueSnackbar(error, { variant: 'error' });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      clientTeam: [],
      totalArea: '',
      description: '',
      city: '',
      startDate: '',
      comment: '',
      note: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      if (!startDate) {
        errorMsg('The Start Date field is required');
        return;
      } else if (isMA && region === '') {
        errorMsg('The Region field is required');
        return;
      } else if (!endDate) {
        errorMsg('The End Date field is required');
        return;
      }
      if (id && projectDetails?.status !== 0) {
        if (company === '') {
          errorMsg('The CompanyId field is required');
          return;
        }
        if (!isMA) {
          if (assignASE.length === 0) {
            errorMsg('The assign Pidilite Teams field is required');
            return;
          } else if (assignPA.length === 0) {
            errorMsg('The assign Application Teams field is required');
            return;
          } else if (assignClient.length === 0 && values.clientTeam.length === 0) {
            errorMsg('The assign Customer Teams field is required');
            return;
          }
        }
      }
      if (endDateEdit && values.comment === '') {
        errorMsg('The Comment field is required');
        return;
      }
      if (status !== '' && values.note === '') {
        errorMsg('The Note field is required');
        return;
      }

      setSubmitting(true);
      let newProjectData = {
        name: values.name.trim(),
        companyId: isMA || (id && projectDetails?.status !== 0) ? company : null,
        city: values.city.trim(),
        description: values.description.trim(),
        startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
        endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
        comment: values.comment === '' ? null : values.comment,
        totalArea: +values.totalArea,
        aseId: assignASE,
        supervisorId: assignPA,
        clientId: assignClient,
        clients: values.clientTeam,
        regionId: isMA ? region : result?.user?.regionId,
        // status: status === '' ? null : status,
        notes: values.note === '' ? null : values.note,
      };
      if (status && status !== '') {
        newProjectData.status = status;
      }
      if (id) {
        let newAseIds = assignASE.filter((o) => projectDetails.aseId.indexOf(o) === -1);
        let newSupervisorIds = assignPA.filter((o) => projectDetails.supervisorId.indexOf(o) === -1);
        let newClientIds = assignClient.filter((o) => projectDetails.clientId.indexOf(o) === -1);
        newProjectData = { ...newProjectData, newAseIds, newSupervisorIds, newClientIds };
      }
      const response = await dispatch(id ? updateProject(id, newProjectData) : addProject(newProjectData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/project');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });

  const handleChangeASE = (event) => {
    const {
      target: { value },
    } = event;
    setAssignASE(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangePA = (event) => {
    const {
      target: { value },
    } = event;
    setAssignPA(typeof value === 'string' ? value.split(',') : value);
  };
  const handleChangeClient = (event) => {
    const {
      target: { value },
    } = event;
    setAssignClient(typeof value === 'string' ? value.split(',') : value);
  };
  const handleChangeCompany = async (e) => {
    setAssignPA([]);
    setAssignClient([]);
    setCompany(e.target.value);

    await dispatch(getCompanyPA({ companyId: e.target.value, role: RoleConstants.PA })).then((response) => {
      if (response.status === 200) {
        setPAUser(response?.data?.data?.userList);
      }
    });
    await dispatch(getCompanyPA({ companyId: e.target.value, role: RoleConstants.CLIENT })).then((response) => {
      if (response.status === 200) {
        setClientData(response?.data?.data?.userList);
      }
    });
  };

  const handleChangeRegion = async (id) => {
    setRegion(id);
    await dispatch(getPa({ regionId: id })).then((response) => {
      if (response.status === 200) {
        setCompanyData(response?.data?.data?.companyList);
      }
    });
    await dispatch(getUser({ role: RoleConstants.ASE, regionId: id })).then((response) => {
      if (response.status === 200) {
        setASEUser(response?.data?.data?.userList);
      }
    });
    setAssignASE([]);
    setAssignPA([]);
  };
  useEffect(async () => {
    await dispatch(getUserRole({ role: RoleConstants.CLIENT }));
    await dispatch(getUserRegion());
    await dispatch(getUserClient({ role: RoleConstants.CLIENT }));
    if (id) {
      await dispatch(editProject(id)).then(async (response) => {
        if (response.status === 200) {
          const projectDetail = response?.data?.data?.projectDetail;
          setProjectStatus(projectDetail.status);
          setProjectDetails(projectDetail);
          setFieldValue('name', projectDetail?.name);
          setFieldValue('city', projectDetail?.city);
          setFieldValue('totalArea', projectDetail?.totalArea);
          setFieldValue('description', projectDetail?.description);
          setEndDate(projectDetail?.endDate);
          setStartDate(projectDetail?.startDate);
          setCompany(projectDetail?.companyId ?? '');
          setRegion(projectDetail?.regionId ?? '');
          setAssignASE(projectDetail?.aseId);
          setAssignPA(projectDetail?.supervisorId);
          setAssignClient(projectDetail?.clientId);
          if (projectDetail?.regionId) {
            await dispatch(getPa({ regionId: projectDetail?.regionId })).then((response) => {
              if (response.status === 200) {
                setCompanyData(response?.data?.data?.companyList);
              }
            });
            await dispatch(getUser({ role: RoleConstants.ASE, regionId: projectDetail?.regionId })).then((response) => {
              if (response.status === 200) {
                setASEUser(response?.data?.data?.userList);
              }
            });
          }
          if (projectDetail?.companyId) {
            await dispatch(getCompanyPA({ companyId: projectDetail?.companyId, role: RoleConstants.PA })).then(
              (response) => {
                if (response.status === 200) {
                  setPAUser(response?.data?.data?.userList);
                }
              },
            );
            await dispatch(getCompanyPA({ companyId: projectDetail?.companyId, role: RoleConstants.CLIENT })).then(
              (response) => {
                if (response.status === 200) {
                  setClientData(response?.data?.data?.userList);
                }
              },
            );
          }
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    setLoader(false);
  }, [dispatch]);

  const { setFieldValue, handleSubmit, isSubmitting, values } = formik;

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Card sx={{ display: 'flex', mb: 3.5 }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography component={'h2'} variant="h1" mb={3}>
                  {id ? 'Update Project' : 'Add Project'}
                </Typography>
                <FormikProvider value={formik}>
                  <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <JumboTextField fullWidth name="name" label="Project Name*" disabled={id && isASE && projectStatus !== 0} />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <JumboSelectField
                          fullWidth
                          label={'Region*'}
                          valueKey="_id"
                          name={'region'}
                          variant="outlined"
                          labelKey={'name'}
                          data={users?.regionList}
                          value={region}
                          onChange={(e) => handleChangeRegion(e.target.value)}
                          disabled={id ? true : isASE ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <JumboTextField
                          fullWidth
                          name="city"
                          label="City / Location*"
                          disabled={id && isASE && projectStatus !== 0}
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            fullWidth
                            label="Start Date*"
                            value={startDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setStartDate(newValue);
                            }}
                            disabled={id && isASE && projectStatus !== 0}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            fullWidth
                            label="End Date*"
                            value={endDate}
                            inputFormat="DD/MM/YYYY"
                            onChange={(newValue) => {
                              setEndDate(newValue);
                              if (id) {
                                setEndDateEdit(true);
                              }
                            }}
                            disabled={id && isASE && projectStatus !== 0}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <JumboTextField
                          fullWidth
                          name="totalArea"
                          label="Total Area (Sqmt)*"
                          disabled={id && isASE && projectStatus !== 0}
                        />
                      </Grid>
                      {(isMA || (id && projectDetails?.status !== 0)) && (
                        <Grid item xs={12} sm={3}>
                          <JumboSelectField
                            fullWidth
                            label={'Company*'}
                            valueKey="_id"
                            name={'companyId'}
                            variant="outlined"
                            labelKey={'name'}
                            data={companyData}
                            value={company}
                            onChange={handleChangeCompany}
                          />
                        </Grid>
                      )}
                      {endDateEdit && (
                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="comment"
                            label="Reason for date change*"
                            multiline
                            rows={2}
                            maxRows={4}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <JumboTextField
                          fullWidth
                          name="description"
                          label="Description*"
                          disabled={id && isASE && projectStatus !== 0}
                          multiline
                          rows={3}
                          maxRows={4}
                        />
                      </Grid>
                      {id && (
                        <>
                          <Grid item xs={12} sm={3}>
                            <JumboSelectField
                              fullWidth
                              label={'Change Project Status'}
                              valueKey="id"
                              name={'status'}
                              variant="outlined"
                              labelKey={'name'}
                              data={statusArr}
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            />
                          </Grid>
                          {status !== '' && (
                            <Grid item xs={9}>
                              <JumboTextField fullWidth name="note" label="Note*" />
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                    <br></br>
                    {(isMA || (id && projectDetails?.status !== 0)) && (
                      <>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-multiple-chip-label">Assign Pidilite Teams*</InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={assignASE}
                                onChange={handleChangeASE}
                                input={<OutlinedInput id="select-multiple-chip" label="Assign Pidilite Teams*" />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => {
                                      const name = ASEUser.filter((val) => val._id === value);
                                      return <Chip key={name[0]?._id} label={name[0]?.name} />;
                                    })}
                                  </Box>
                                )}
                                MenuProps={MenuProps}>
                                {ASEUser.map((val) => (
                                  <MenuItem key={val._id} value={val._id} style={getStyles(val.name, assignASE, theme)}>
                                    {val.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-multiple-chip-label">Assign Application Teams*</InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={assignPA}
                                onChange={handleChangePA}
                                input={<OutlinedInput id="select-multiple-chip" label="Assign Application Teams*" />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => {
                                      const name = PAUser.filter((val) => val._id === value);
                                      return <Chip key={name[0]?._id} label={name[0]?.name} />;
                                    })}
                                  </Box>
                                )}
                                MenuProps={MenuProps}>
                                {PAUser.map((val) => (
                                  <MenuItem key={val?._id} value={val?._id} style={getStyles(val?.name, assignPA, theme)}>
                                    {val.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-multiple-chip-label">Assign Customer Teams*</InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={assignClient}
                                onChange={handleChangeClient}
                                input={<OutlinedInput id="select-multiple-chip" label="Assign Customer Teams*" />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => {
                                      const name = clientData.filter((val) => val?._id === value);
                                      return <Chip key={name[0]?.id} label={name[0]?.name} />;
                                    })}
                                  </Box>
                                )}
                                MenuProps={MenuProps}>
                                {clientData.map((val) => (
                                  <MenuItem key={val._id} value={val._id} style={getStyles(val.name, assignClient, theme)}>
                                    {val.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <br></br>
                        <FieldArray name="clientTeam">
                          {({ remove, push }) => (
                            <div>
                              <Divider className="mt-5" />
                              {values?.clientTeam?.length > 0 &&
                                values?.clientTeam.map((friend, index) => (
                                  <div>
                                    <br></br>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={3}>
                                        <JumboTextField fullWidth name={`clientTeam[${index}].name`} label="Full Name*" />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <JumboTextField fullWidth name={`clientTeam[${index}].email`} label="Email*" />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <JumboTextField
                                          fullWidth
                                          name={`clientTeam[${index}].designation`}
                                          label="Designation*"
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                        <JumboTextField
                                          fullWidth
                                          name={`clientTeam[${index}].mobileNumber`}
                                          label="Mobile*"
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={1}>
                                        <IconButton size="large" onClick={() => remove(index)}>
                                          <Cancel />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))}
                              <br></br>

                              <Grid item xs={12} sm={3}>
                                <Div
                                  style={{ cursor: 'pointer', marginBottom: '30px' }}
                                  onClick={() =>
                                    push({
                                      name: '',
                                      email: '',
                                      mobileNumber: '',
                                      designation: '',
                                      roleId: users?.roleList[0]?._id,
                                    })
                                  }>
                                  <Typography
                                    component={'h2'}
                                    variant="h2"
                                    style={{ textAlign: 'center', color: 'red', textDecoration: 'underline' }}>
                                    Add New Customer <AddIcon style={{ marginBottom: '-5px' }} />
                                  </Typography>
                                </Div>
                              </Grid>
                            </div>
                          )}
                        </FieldArray>
                      </>
                    )}

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          loading={isSubmitting || mutation.isLoading}>
                          Save
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="inherit"
                          size="large"
                          sx={{ mb: 3 }}
                          onClick={() => navigate('/project')}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Div>
          </Card>
        </>
      )}
    </>
  );
};

export default AddEditProject;
