import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const menus = [
  {
    label: ' ',
    type: 'section',
    children: [
      {
        uri: '/',
        label: 'Dashboard',
        type: 'nav-item',
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Projects',
        type: 'collapsible',
        icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/project',
            label: 'All Projects',
            type: 'nav-item',
          },
          {
            uri: '/add-project',
            label: 'Add New',
            type: 'nav-item',
          },
          {
            uri: '/approve-project',
            label: 'Approve Request',
            type: 'nav-item',
          },
          {
            uri: '/archive-project',
            label: 'Archived',
            type: 'nav-item',
          },
          {
            uri: '/project-delete',
            label: 'Deleted',
            type: 'nav-item',
          },
          {
            uri: '/project-reject',
            label: 'Rejected',
            type: 'nav-item',
          },
        ],
      },
      {
        uri: '/phases',
        label: 'Phases',
        type: 'nav-item',
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Reports',
        type: 'collapsible',
        icon: <AssessmentOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/category',
            label: 'Categories',
            type: 'nav-item',
          },
          {
            uri: '/reports',
            label: 'List All Reports',
            type: 'nav-item',
          },
        ],
      },
      {
        label: 'Discussion Forum',
        type: 'collapsible',
        icon: <EmojiObjectsOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/discussion-topic',
            label: 'Category',
            type: 'nav-item',
          },
          {
            uri: '/view-thread',
            label: 'View Thread',
            type: 'nav-item',
          },
        ],
      },
      {
        label: 'Users',
        type: 'collapsible',
        icon: <PersonOutlineOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/ase',
            label: 'Pidilite Teams',
            type: 'nav-item',
          },
          {
            uri: '/supervisor-admin',
            label: 'Application Teams',
            type: 'nav-item',
          },
          {
            uri: '/client',
            label: 'Customer Teams',
            type: 'nav-item',
          },
        ],
      },
      {
        label: 'Notification',
        type: 'collapsible',
        icon: <NotificationsNoneOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/notification',
            label: 'List All',
            type: 'nav-item',
          },
        ],
      },
      {
        uri: '/feedback',
        label: 'Feedback',
        type: 'nav-item',
        icon: <ChatOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/logs',
        label: 'Logs',
        type: 'nav-item',
        icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/pa',
        label: 'PA Companies',
        type: 'nav-item',
        icon: <PersonOutlineOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Settings',
        type: 'collapsible',
        icon: <SettingsOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/zone',
            label: 'Zone',
            type: 'nav-item',
          },
          {
            uri: '/region',
            label: 'Region',
            type: 'nav-item',
          },
          {
            uri: '/role',
            label: 'Role',
            type: 'nav-item',
          },
        ],
      },
    ],
  },
];

export default menus;
