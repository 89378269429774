import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Loader from '@jumbo/components/Loader';
import { userType } from 'app/helpers/userType';
import { addPhase, editPhase, updatePhase } from 'app/redux/actions/Phase';

const validationSchema = yup.object({
  phase: yup
    .string('Enter your name')
    .min(2, 'name can set of between 2 and 100 characters!')
    .max(100, 'name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  description: yup
    .string('Enter your description')
    .min(5, 'Description can set of between 5 and 255 characters!')
    .max(255, 'Description can set of between 5 and 255 characters!')
    .required('The Description field is required'),
  area: yup.number().required('The Area field is required').min(1, 'Please enter number value greater than 0'),
});

const AddEditPhase = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isPA } = userType();
  const dispatch = useDispatch();
  const { projectId, id } = useParams();
  const [loader] = useState(false);
  const mutation = { isError: false };
  const [editPprojectId, setEditPprojectId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [areaDisable, setAreaDisable] = useState(false);
  const [endDateEdit, setEndDateEdit] = useState(false);
  const errorMsg = (error) => {
    enqueueSnackbar(error, { variant: 'error' });
  };
  const formik = useFormik({
    initialValues: {
      phase: '',
      description: '',
      area: '',
      comment: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      if (!startDate) {
        errorMsg('The Start Date field is required');
        return;
      } else if (!endDate) {
        errorMsg('The End Date field is required');
        return;
      }
      if (endDateEdit && values.comment === '') {
        errorMsg('The Comment field is required');
        return;
      }
      setSubmitting(true);
      let newPhaseData = {
        name: values.phase.trim(),
        description: values.description.trim(),
        areaToBeCovered: id ? +values.area : +values.area.trim(),
        comment: values.comment === '' ? null : values.comment,
        startDate: startDate,
        endDate: endDate,
      };
      if (isPA) {
        newPhaseData.projectId = localStorage.getItem('projectId');
      } else if (projectId) {
        newPhaseData.projectId = projectId;
      } else {
        newPhaseData.projectId = editPprojectId;
      }
      const response = await dispatch(id ? updatePhase(id, newPhaseData) : addPhase(newPhaseData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/phases');
      } else {
        errorMsg(response.data?.responseMessage);
      }
      setSubmitting(false);
    },
  });
  useEffect(async () => {
    if (id) {
      await dispatch(editPhase(id)).then(async (response) => {
        if (response.status === 200) {
          const phaseDetail = response.data.data.phaseDetail;
          setFieldValue('phase', phaseDetail?.name ?? '');
          setFieldValue('description', phaseDetail?.description ?? '');
          setFieldValue('area', phaseDetail?.areaToBeCovered ?? '');
          setStartDate(phaseDetail?.startDate ?? null);
          setEndDate(phaseDetail?.endDate ?? null);
          setEditPprojectId(phaseDetail?.projectId ?? '');
          setAreaDisable(phaseDetail?.havingTask);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
  }, [dispatch]);
  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {id ? 'Update Phase' : 'Add New Phase'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="phase" label="Phase*" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField fullWidth name="area" label="Area Sqmt.*" disabled={areaDisable} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="Start Date*"
                          value={startDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="End Date*"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setEndDate(newValue);
                            if (id) {
                              setEndDateEdit(true);
                            }
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {endDateEdit && (
                      <Grid item xs={12}>
                        <JumboTextField fullWidth name="comment" label="Reason for date change*" multiline rows={2} maxRows={4} />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <JumboTextField fullWidth name="description" label="Description*" multiline rows={2} maxRows={4} />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting || mutation.isLoading}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => navigate('/phases')}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default AddEditPhase;
