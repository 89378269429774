import React, { useEffect, useState } from 'react';
import { Typography, Grid, CardContent, Card, Button } from '@mui/material';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addTopic, editTopic, updateTopic } from 'app/redux/actions/Topic';
import Loader from '@jumbo/components/Loader';
import Div from '@jumbo/shared/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name can set of between 2 and 100 characters!')
    .max(100, 'Name can set of between 2 and 100 characters!')
    .required('The Name field is required'),
  description: yup
    .string('Enter your description')
    .min(4, 'Description can set of between 4 and 255 characters!')
    .max(255, 'Description can set of between 4 and 255 characters!')
    .required('The Description field is required'),
});

const AddEditTopic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [Topic, setTopic] = useState();
  const [loader, setLoader] = useState(true);
  const mutation = { isError: false };

  const formik = useFormik({
    initialValues: {
      name: Topic?.name ? Topic?.name : '',
      description: Topic?.description ? Topic?.description : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newCategoryData = { name: values.name.trim(), description: values.description.trim() };
      const response = await dispatch(id ? updateTopic(id, newCategoryData) : addTopic(newCategoryData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        navigate('/discussion-topic');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    if (id) {
      dispatch(editTopic(id)).then((response) => {
        if (response.status === 200) {
          setTopic(response?.data?.data?.discussionTopicDetail);
          setFieldValue('name', response?.data?.data?.discussionTopicDetail?.name);
          setFieldValue('description', response?.data?.data?.discussionTopicDetail?.description);
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      });
    }
    setLoader(false);
  }, [dispatch, id]);
  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Typography component={'h2'} variant="h1" mb={3}>
                {id ? 'Update Category' : 'Add New Category'}
              </Typography>
              <FormikProvider value={formik}>
                <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  {mutation.isError && <p>{mutation.error.message}</p>}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <JumboTextField fullWidth name="name" label="Name*" />
                    </Grid>
                    <Grid item xs={12}>
                      <JumboTextField fullWidth name="description" label="Description*" multiline rows={2} maxRows={4} />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || mutation.isLoading}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        size="large"
                        sx={{ mb: 3 }}
                        onClick={() => navigate('/discussion-topic')}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      )}
    </>
  );
};

export default AddEditTopic;
