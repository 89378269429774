import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import List from '@mui/material/List';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import JumboIconButton from '@jumbo/components/JumboIconButton';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import { CardActions, ThemeProvider } from '@mui/material';
import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { getUnReadNotifications } from 'app/redux/actions/Notification';
import Notification from './Notification';

const NotificationsDropdown = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state?.notification);
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const { headerTheme } = useJumboHeaderTheme();

  useEffect(async () => {
    await dispatch(getUnReadNotifications());
  }, [dispatch]);
  const handleClick =async () => {
    await dispatch(getUnReadNotifications());
  };
  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <ThemeProvider theme={headerTheme}>
            <JumboIconButton badge={{ variant: 'dot' }} elevation={25}>
              <NotificationImportantOutlinedIcon sx={{ fontSize: '1.25rem' }} onClick={handleClick} />
            </JumboIconButton>
          </ThemeProvider>
        }
        disableInsideClick
      >
        <Div sx={{ width: 360, maxWidth: '100%', height: '400px' }}>
          <CardHeader title={'Notifications'} />
          <List disablePadding>
            {notification?.unreadList?.map((item, index) => {
              return <Notification key={index} item={item} />;
            })}
          </List>
          <Divider />
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              sx={{ textTransform: 'none', fontWeight: 'normal', '&:hover': { bgcolor: 'transparent' } }}
              size={'small'}
              variant="text"
              endIcon={<ArrowForwardIcon />}
              disableRipple
              onClick={() => navigate('/notification')}
            >
              View All
            </Button>
          </CardActions>
        </Div>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default NotificationsDropdown;
