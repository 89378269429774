import RoleConstants from '@jumbo/constants/RoleConstants';

export const userType = () => {
  let obj = { isMA: false, isASE: false, isPA: false };
  const result = JSON.parse(localStorage.getItem('User'));
  if (result?.user?.roleId?.type === RoleConstants.MA) {
    obj.isMA = true;
  } else if (result?.user?.roleId?.type === RoleConstants.ASE) {
    obj.isASE = true;
  } else if (result?.user?.roleId?.type === RoleConstants.PA) {
    obj.isPA = true;
  }
  return obj;
};
