import axios from 'axios';

import {
  GET_USER,
  GET_USER_REGION,
  GET_USER_COMPANY,
  GET_USER_ROLE,
  GET_COMPANY_PA,
  GET_USER_CLIENT,
  GET_USER_ASE,
} from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';

export const getUser = ({
  page,
  search,
  type,
  perPage,
  role,
  name,
  email,
  mobileNumber,
  companyName,
  regionId,
  projectId,
}) => {
  return (dispatch) => {
    return axios
      .get('/user/list', {
        params: { page, search, type, perPage, role, name, email, mobileNumber, companyName, regionId, projectId },
      })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_USER,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getUserRegion = () => {
  return (dispatch) => {
    return axios
      .get('/region/list')
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_USER_REGION,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getUserRole = ({ role }) => {
  return (dispatch) => {
    return axios
      .get('/role/list', { params: { role } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_USER_ROLE,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getUserCompany = () => {
  return (dispatch) => {
    return axios
      .get('/company/list')
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_USER_COMPANY,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addUser = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('user', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editUser = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`user/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateUser = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`user/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`user/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getCompanyPA = ({ companyId, role, name, email, mobileNumber, companyName, regionId }) => {
  return (dispatch) => {
    return axios
      .get('/user/list', { params: { companyId, role, name, email, mobileNumber, companyName, regionId } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_COMPANY_PA,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
export const getUserClient = ({ companyId, role, name, email }) => {
  return (dispatch) => {
    return axios
      .get('/user/list', { params: { companyId, role, name, email } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_USER_CLIENT,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getUserASE = ({ role }) => {
  return (dispatch) => {
    return axios
      .get('/user/list', { params: { role } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_USER_ASE,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
