import axios from 'axios';

import { GET_PROJRCT, GET_SELECT_PROJRCT, GET_PROJRCT_CSV, GET_PROJRCT_NAME } from '@jumbo/utils/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import envData from 'env_config';

export const getProjects = ({
  projectId,
  projectName,
  status,
  totalArea,
  clientId,
  aseId,
  paId,
  endDate,
  startDate,
  regionId,
  page,
  perPage,
}) => {
  return (dispatch) => {
    return axios
      .get('/project/list', {
        params: {
          projectId,
          projectName,
          status,
          totalArea,
          clientId,
          aseId,
          paId,
          endDate,
          startDate,
          regionId,
          page,
          perPage,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PROJRCT,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getSelectProjects = ({ status, paId }) => {
  return (dispatch) => {
    return axios
      .get('/project/list', { params: { listType: 0, status, paId } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_SELECT_PROJRCT,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addProject = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('project', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editProject = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`project/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getProjectName = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`project/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PROJRCT_NAME,
            payload: response?.data?.data?.projectDetail,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
export const updateProject = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`project/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateProjectStatus = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`project/status/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getProjectsCsv = ({
  projectId,
  projectName,
  status,
  totalArea,
  clientId,
  aseId,
  paId,
  endDate,
  startDate,
  regionId,
}) => {
  return (dispatch) => {
    return axios
      .get('/project/list', {
        params: {
          projectId,
          projectName,
          status,
          totalArea,
          clientId,
          aseId,
          paId,
          endDate,
          startDate,
          regionId,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PROJRCT_CSV,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getProjectTeam = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`${envData.API_BASE_URL_AUTH}client/project/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};
