import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom/dist';
import { getExportPa, getPa } from 'app/redux/actions/Pa';
import Loader from '@jumbo/components/Loader';
import PartnerApplicatorListCard from '@jumbo/components/List/PartnerApplicatorListCard';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import { userType } from 'app/helpers/userType';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import CardComponent from '@jumbo/components/common/Card';
import { companyCSvExport, companysCsvHeaders } from 'app/helpers/csvExport';
import Div from '@jumbo/shared/Div/Div';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const PA = () => {
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const mobileView = useMediaQuery('(max-width:575px)');
  const [searchParams] = useSearchParams();
  const companys = useSelector((state) => state?.pa);
  const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));
  const { isMA } = userType();
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [CsvData, setCsvData] = useState([]);
  const [permission, setPermission] = useState({
    createPermission: false,
    updatePermission: false,
    deletePermission: false,
    createUserPermission: false,
  });
  const count = Math.ceil(companys?.totalItems / perPage);
  const _DATA = usePagination(companys?.list, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const getPermission = () => {
    if (isMA) {
      setPermission({ createPermission: true, updatePermission: true, deletePermission: true, createUserPermission: true });
    } else {
      let permissionValue = {
        createPermission: false,
        updatePermission: false,
        deletePermission: false,
        createUserPermission: false,
      };
      const companyPermission = permissionData?.filter((item) => {
        return item?.moduleName?.includes('Company') && item;
      });
      companyPermission?.length &&
        companyPermission.forEach(function (num) {
          if (num?.name === 'create-company') {
            permissionValue.createPermission = true;
          }
          if (num?.name === 'delete-company') {
            permissionValue.deletePermission = true;
          }
          if (num?.name === 'update-company') {
            permissionValue.updatePermission = true;
          }
        });
      const userPermission = permissionData?.filter((item) => {
        return item?.moduleName?.includes('User') && item;
      });
      userPermission?.length &&
        userPermission.forEach(function (num) {
          if (num?.name === 'create-user') {
            permissionValue.createUserPermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  let csvReport = {
    headers: companysCsvHeaders,
    data: CsvData,
    filename: 'companies-list.csv',
  };
  const ExportComponent = () => (
    <CSVLink {...csvReport}>
      <Tooltip title="Download full list" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}>
          <img src={`${ASSET_IMAGES}/download.png`} alt="Pidilite" style={{ width: '22px', margin: '12px 9px 9px 9px' }} />
        </Div>
      </Tooltip>
    </CSVLink>
  );
  useEffect(() => {
    setCsvData(companyCSvExport(companys.exportPAlist));
  }, [companys.exportPAlist]);
  useEffect(() => {
    getPermission();
  }, []);
  useEffect(async () => {
    if (searchParams.get('companyId')) {
      await dispatch(getPa({ page, perPage, id: searchParams.get('companyId') }));
    } else {
      await dispatch(getPa({ page, perPage }));
    }
    await dispatch(getExportPa());
    setLoader(false);
  }, [deleted, dispatch, page]);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {mobileView && (
            <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
              PA COMPANY
            </Typography>
          )}
          <CardComponent title={`List of PA Companies`} addUrl={'/add-pa'} Component={ExportComponent}>
            {companys?.list?.map((data) => (
              <PartnerApplicatorListCard
                key={data._id}
                item={data}
                setDeleted={setDeleted}
                deleted={deleted}
                permission={permission}
              />
            ))}
            {companys?.list?.length !== 0 ? (
              <JumboPagination count={count} page={page} handleChange={handleChangePage} />
            ) : (
              <NoDataPlaceholder />
            )}
          </CardComponent>
        </>
      )}
    </>
  );
};

export default PA;
