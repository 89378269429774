import { GET_PA, GET_PA_REGION, GET_EXPORT_PA } from '@jumbo/utils/constants/ActionTypes';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  regionList: [],
  exportPAlist: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PA: {
      return {
        ...state,
        list: action?.payload?.companyList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_PA_REGION: {
      return {
        ...state,
        regionList: action?.payload?.regionList,
      };
    }
    case GET_EXPORT_PA: {
      return {
        ...state,
        exportPAlist: action?.payload?.companyList,
      };
    }
    default: {
      return state;
    }
  }
};
