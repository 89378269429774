import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import TikitListCard from '@jumbo/components/List/TikitListCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { getProjectName, getSelectProjects } from 'app/redux/actions/Project';
import { getProjectTopic, getTopic } from 'app/redux/actions/Topic';
import { userType } from 'app/helpers/userType';
import { addTicket, getTicket } from 'app/redux/actions/Discussion';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import JumboPagination from '@jumbo/components/JumboPagination';
import usePagination from 'app/helpers/Pagination';
import envData from 'env_config';
import { useParams } from 'react-router-dom';
import { uploadFileS3, uploadFileS3signedUrl } from 'app/redux/actions/Report';
import TikitListCard2 from '@jumbo/components/List/TikitListCard2';

const validationSchema = yup.object({
  ticketTitle: yup
    .string('Enter your Ticket Title')
    .min(2, 'Discussion Title can set of between 2 and 100 characters!')
    .max(100, 'Discussion Title can set of between 2 and 100 characters!')
    .required('The Discussion Title field is required'),
  message: yup
    .string('Enter your Message')
    .min(5, 'Message can set of between 5 and 255 characters!')
    .max(255, 'Message can set of between 5 and 255 characters!')
    .required('The Message field is required'),
  topic: yup.string('Enter your Category').required('The Category field is required'),
});

const ViewThread = () => {
  const mutation = { isError: false };
  const dispatch = useDispatch();
  const perPage = envData.PER_PAGE;
  const { isPA, isMA } = userType();
  const { projectId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const mobileView = useMediaQuery('(max-width:575px)');
  const projects = useSelector((state) => state?.project);
  const topics = useSelector((state) => state?.topic);
  const discussionTopics = useSelector((state) => state?.discussion);
  const [projectValue, setProjectValue] = useState('');
  const [projectName, setProjectName] = useState('');

  const [topic, setTopic] = useState('');
  const [status, setStatus] = useState('');
  const [view, setView] = useState(false);
  const [viewTopic, setViewTopic] = useState('');
  const [ticketTopic, setTicketTopic] = useState('');
  const [file, setfile] = useState();
  const [page, setPage] = useState(1);
  const count = Math.ceil(discussionTopics?.totalItems / perPage);
  const _DATA = usePagination(discussionTopics?.ticketList, perPage);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const formik = useFormik({
    initialValues: {
      ticketTitle: '',
      message: '',
      topic: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newPaData = {
        ticketTitle: values.ticketTitle.trim(),
        comment: values.message.trim(),
        projectId: projectValue,
        topicId: ticketTopic,
        attachment: file,
      };
      const response = await dispatch(addTicket(newPaData));
      if (response.status === 200) {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'success',
        });
        changeTopic(ticketTopic);
        resetForm();
        setfile(null);
        setTicketTopic('');
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(async () => {
    if (isPA || projectId) {
      setProjectValue(projectId ? projectId : localStorage.getItem('projectId'));
      if (topics.projectTopicList.length !== 0) {
        changeTopic(topics?.projectTopicList[0]?._id);
      }
      setView(true);
    }
  }, [topics.projectTopicList]);

  useEffect(async () => {
    await dispatch(getSelectProjects({}));
    await dispatch(getTopic({}));
    if (projectId) await dispatch(getProjectName(projectId));
  }, [dispatch]);

  useEffect(async () => {
    await dispatch(
      getProjectTopic({ projectId: isPA ? localStorage.getItem('projectId') : projectId ? projectId : projectValue }),
    );
  }, [projectValue]);

  useEffect(async () => {
    const obj = { page, perPage, status: status }
    if (topic && topic !== '') obj.topicId = topic
    if (isPA ? localStorage.getItem('projectId') : projectId ? projectId : projectValue) obj.projectId = isPA ? localStorage.getItem('projectId') : projectId ? projectId : projectValue
    await dispatch(
      getTicket(obj),
    );
  }, [page]);

  const submithandler = async () => {
    // if (projectValue === '' || !projectValue) {
    //   enqueueSnackbar('The ProjectId field is required', { variant: 'error' });
    //   return;
    // } else {
    const obj = { page, perPage, projectId: projectValue, status: status }
    if (topic !== '' || topic) obj.topicId = topic
    await dispatch(getTicket(obj));
    setView((projectValue === '' || !projectValue) ? false : true);
    setViewTopic(topic);
    // }
  };
  const fileUpload = async (val) => {
    const allowFile = ['jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'csv'];
    if (allowFile.includes(val.name.split('.').pop())) {
      if (val && val.size <= 2097152) {
        const response = await dispatch(
          uploadFileS3signedUrl({
            key: `${Date.now()}-${val.name.replace(' ', '')}`,
            ContentType: val.type,
            folderName: envData.FOLDER_NAME_DISCUSSION,
          }),
        );

        if (response.status === 200) {
          setfile(response?.data?.data?.url);
          await dispatch(uploadFileS3({ url: response.data.data.signedUrl, data: val }));
        } else {
          enqueueSnackbar(response.data?.responseMessage, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('The document may not be greater than 2 MB', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Please enter only pdf, image, excel and docx file', {
        variant: 'error',
      });
    }
  };
  const changeTopic = async (topicId) => {
    setViewTopic(topicId);
    await dispatch(getTicket({ page: 1, perPage, topicId, projectId: projectValue, status: status }));
  };
  const resetData = () => {
    setfile(null);
    setTicketTopic('');
  };
  const { setFieldValue, handleSubmit, isSubmitting } = formik;
  return (
    <>
      {mobileView && (
        <Typography className="title-pl-xs-15" variant={'h1'} mb={3}>
          {projectId ? `DISCUSSION FORUM - ${projects?.projectName}` : 'DISCUSSION FORUM'}
        </Typography>
      )}
      {!isPA && !projectId && (
        <Card sx={{ display: 'flex', mb: 3.5 }}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    id="projectId"
                    size="small"
                    name="projectId"
                    options={projects?.selectList}
                    autoHighlight
                    onChange={(e, values) => {
                      setProjectValue(values?._id);
                      setProjectName(values?.name);
                      setView(false);
                    }}
                    getOptionLabel={(option) => option.uniqId}
                    getOptionSelected={(option) => option?._id === projectValue}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.uniqId}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="Project Id"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />{projectName}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="topic"
                    size="small"
                    name="topic"
                    options={topics?.list}
                    autoHighlight
                    onChange={(e, values) => {
                      setTopic(values?._id);
                      setView(false);
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option) => option?._id === topic}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="Select Category"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel size="small" id="status">
                      Select Status
                    </InputLabel>
                    <Select
                      labelId="status"
                      id="ststus-select"
                      value={status}
                      label="Select Status"
                      size="small"
                      onChange={(e) => setStatus(e.target.value)}>
                      <MenuItem value={true}>Open</MenuItem>
                      <MenuItem value={false}>Closed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button fullWidth type="submit" variant="contained" onClick={submithandler}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Div>
        </Card>
      )}

      {view && (
        <>
          {!isMA && (
            <>
              <Card sx={{ display: 'flex', mb: 3.5 }}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant={'h2'}>How It Works - Discussion Forum Section</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant={'h5'} mb={3}>
                      The discussion forum serves as a platform to facilitate effective communication with the team
                      concerning specific concerns. We have established pre-defined topics and categories to address relevant
                      matters to ensure a structured approach. The process is outlined as follows:
                    </Typography>
                    <Typography variant={'h5'} mb={2}>
                      A. Starting a New Discussion Thread:
                    </Typography>
                    <Typography variant={'h5'} mb={3}>
                      Select the appropriate Category from the provided options. Enter a clear and concise Title for your
                      discussion to effectively convey the subject matter. Optionally, you may upload any supporting
                      documents that are relevant to the discussion. Compose your query or comment in the designated area.
                      Upon completion, submit your thread. The system will automatically generate a unique ticket number, and
                      all stakeholders involved in the project will receive notifications regarding the new discussion.{' '}
                    </Typography>
                    <Typography variant={'h5'} mb={2}>
                      B. Replying or Joining an Existing Thread:
                    </Typography>
                    <Typography variant={'h5'} mb={1}>
                      In the discussion thread box, navigate to the appropriate category where ongoing discussions are
                      listed. To participate in a specific thread, click on the three dots displayed in the right column.
                      This will allow you to view the thread's content and reply accordingly. If your query has been
                      successfully addressed or your concern has been resolved, you have the option to update the status of
                      the thread initiated by you to "resolved." This will help in maintaining a clear overview of the
                      discussions.{' '}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Card>
              <Card sx={{ display: 'flex', mb: 3.5 }}>
                <Accordion sx={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant={'h2'}>Lets Discuss</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormikProvider value={formik}>
                      <Form style={{ textAlign: 'left' }} noValidate autoComplete="off" onSubmit={handleSubmit}>
                        {mutation.isError && <p>{mutation.error.message}</p>}
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4}>
                            <JumboSelectField
                              fullWidth
                              label={'Select Category*'}
                              valueKey="_id"
                              name={'topic'}
                              variant="outlined"
                              labelKey={'name'}
                              data={topics?.list}
                              value={ticketTopic}
                              onChange={(e) => {
                                setTicketTopic(e.target.value);
                                setFieldValue('topic', e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <JumboTextField fullWidth name="ticketTitle" label="Discussion Title*" />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Button fullWidth size="large" variant="outlined" component="label">
                              <input
                                type="file"
                                accept=".jpg, .jpeg, .png, .gif, .doc, .docx, .pdf, .xls, .xlsx, .csv"
                                onChange={(e) => fileUpload(e.target.files[0])}
                                className="c-width"
                              />
                            </Button>
                            <p style={{ margin: '0', fontSize: '14px' }}>Maximum file upload size is 2 MB</p>
                          </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <JumboTextField
                              fullWidth
                              name="message"
                              label="Write youe query/comment*"
                              multiline
                              rows={4}
                              maxRows={4}
                            />
                          </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={2}>
                            <LoadingButton
                              fullWidth
                              type="submit"
                              variant="contained"
                              size="large"
                              loading={isSubmitting || mutation.isLoading}>
                              Submit
                            </LoadingButton>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Button
                              fullWidth
                              type="reset"
                              variant="contained"
                              color="inherit"
                              size="large"
                              onClick={resetData}>
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    </FormikProvider>
                  </AccordionDetails>
                </Accordion>
              </Card>
            </>
          )}
          <Card sx={{ display: 'flex', mb: 3.5, overflow: 'auto' }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography variant={'h2'} mb={3}>
                  Discussion Threads
                </Typography>
                <Div className="discussion-threads-responsive">
                  <Div className="sub-discussion-threads">
                    {topics?.projectTopicList?.map((val) => (
                      <Button
                        className="discussion-threads-btn"
                        color="secondary"
                        sx={{ mr: 2, color: 'black' }}
                        size={'small'}
                        onClick={() => changeTopic(val._id)}
                        variant={viewTopic === val._id ? 'contained' : 'outlined'}>
                        {val.name}
                      </Button>
                    ))}
                  </Div>
                </Div>
                <br></br>
                <Div className="ticket-responsive">
                  {discussionTopics?.ticketList?.map((data) => (
                    <TikitListCard key={data._id} item={data} />
                  ))}
                </Div>

                {discussionTopics?.ticketList?.length !== 0 ? (
                  <JumboPagination count={count} page={page} handleChange={handleChangePage} />
                ) : (
                  <NoDataPlaceholder />
                )}
              </CardContent>
            </Div>
          </Card>
        </>
      )}

      {!view && (
        <>
          <Card sx={{ display: 'flex', mb: 3.5, overflow: 'auto' }}>
            <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <CardContent>
                <Typography variant={'h2'} mb={3}>
                  Discussion Threads
                </Typography>
                <Div className="discussion-threads-responsive">
                  <Div className="sub-discussion-threads">
                    {topics?.projectTopicList?.map((val) => (
                      <Button
                        className="discussion-threads-btn"
                        color="secondary"
                        sx={{ mr: 2,mb:2, color: 'black' }}
                        size={'small'}
                        onClick={() => changeTopic(val._id)}
                        variant={viewTopic === val._id ? 'contained' : 'outlined'}>
                        {val.name}
                      </Button>
                    ))}
                  </Div>
                </Div>
                <Div className="ticket-responsive">
                  {discussionTopics?.ticketList?.map((data) => (
                    <TikitListCard2 key={data._id} item={data} />
                  ))}
                </Div>
                {discussionTopics?.ticketList?.length !== 0 ? (
                  <JumboPagination count={count} page={page} handleChange={handleChangePage} />
                ) : (
                  <NoDataPlaceholder />
                )}
              </CardContent>
            </Div>
          </Card>
        </>
      )}
    </>
  );
};

export default ViewThread;
