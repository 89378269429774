import axios from 'axios';

import { GET_ROLE, GET_PERMISSION_LIST, GET_PERMISSION } from '@jumbo/utils/constants/ActionTypes';
import { fetchStart, fetchSuccess, fetchError } from './Common';

export const getRoles = ({ page, search, type }) => {
  return (dispatch) => {
    return axios
      .get('/role/list', { params: { page: page, search: search, type: type } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_ROLE,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const addRole = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post('role', data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const editRole = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .get(`role/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const updateRole = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .put(`role/${id}`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const deleteRole = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .delete(`role/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.responseMessage));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.responseMessage));
        return Promise.resolve(err);
      });
  };
};

export const getPermissionsList = () => {
  return (dispatch) => {
    return axios
      .get('/permission/list')
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PERMISSION_LIST,
            payload: response?.data?.data,
          });
          return Promise.resolve(response?.data?.data);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.response_msg));
        return Promise.resolve(err);
      });
  };
};

export const getAllowedPermissions = (id) => {
  return (dispatch) => {
    return axios
      .get(`/permission/allow-list/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_PERMISSION,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.response_msg));
        return Promise.resolve(err);
      });
  };
};

export const updatePermissions = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    return axios
      .post(`/permission/allow`, data)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(fetchSuccess(response?.data?.response_msg));
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.data?.response_msg));
        return Promise.resolve(err);
      });
  };
};
